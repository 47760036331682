/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
import React, { useState } from 'react'
import NotificationsNoneIcon from '@material-ui/icons/Notifications'
import Badge from '@mui/material/Badge'
import { Menu, MenuItem } from '@material-ui/core' //Divider
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  toastMessage,
  showLoading,
  selectedContractDetails,
  timezoneAtom,
} from '../../../components/recoil/atoms/atoms'
import { fetchDataSelector } from '../../../components/recoil/selectors/selectors'
import apiEndPoints from '../../../../models/api/apiEndpoints'
import utils from '../../../../utils/utils'
import ReactModal from '../../../components/base/modal/ReactModal'
import './BellNotification.css'

const BellNotification = () => {
  const initialState = {
    modalOpen: false,
    modaldata: '',
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [appNotificationData, setAppNotificationData] = useState(0)
  const [notificationData, setNotificationData] = useState(null)
  const [state, setState] = useState(initialState)
  const showToastMessage = useSetRecoilState(toastMessage)
  //const showLoader = useSetRecoilState(showLoading)
  const selectedContractData = useRecoilValue(selectedContractDetails)
  const getCoreData = useRecoilValue(fetchDataSelector)
  const timezoneData = useRecoilValue(timezoneAtom)

  React.useEffect(() => {
    getNotificationData()
  }, [selectedContractData])

  const getNotificationData = async () => {
    setAnchorEl(() => null)
    if (
      Object.keys(selectedContractData).length > 0 &&
      selectedContractData?.contractId !== undefined
    ) {
      Promise.all([
        await getCoreData(
          apiEndPoints?.Notification?.getNotification?.method,
          `${apiEndPoints?.Notification?.getNotification.url}/${selectedContractData?.contractId}`
        ),
      ])
        .then(async ([response]) => {
          if (response?.status === utils?.apiResponseCode?.successCode) {
            setAppNotificationData({
              NotificationCount: response?.data?.data?.filter(
                (user) => user?.status === 'Unread'
              ).length,
            })
            setNotificationData(response?.data?.data)
          } else {
            setNotificationData(null)
            if (response?.data?.message) {
              showToastMessage({
                message: response?.data?.message,
                status: 'error',
              })
            }
          }
        })
        .catch((error) => {
          showToastMessage({
            message: error,
            status: 'error',
          })
        })
    } else {
      setAppNotificationData(null)
      setNotificationData(null)
    }
  }

  //   const getData = async () => {
  //     showLoader(true)
  //     await getNotificationData()
  //     showLoader(false)
  //   }
  const handleClick = (event) => {
    //setNotificationData(null)
    setAnchorEl(event.currentTarget)
    //getData()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const onRead = async (parms) => {
    await updateNotification(parms)
    setState((Prev) => ({
      ...Prev,
      modalOpen: true,
      modaldata: parms,
    }))
  }
  const modalclose = () => {
    setState((Prev) => ({
      ...Prev,
      modalOpen: false,
      modaldata: '',
    }))
  }

  const updateNotification = async (data) => {
    const postData = {
      contractId: selectedContractData?.contractId,
      status: 'Read',
      messageId: data?.id,
    }

    await getCoreData(
      `${apiEndPoints?.Notification?.putNotification?.method}`,
      `${apiEndPoints?.Notification?.putNotification.url}`,
      postData
    )
      .then((response) => {
        if (response?.status === utils?.apiResponseCode?.successCode) {
          const updatedUsers = notificationData.map((notify) => {
            if (notify.id === data?.id) {
              return { ...notify, status: 'Read' }
            }
            return notify
          })

          setAppNotificationData({
            NotificationCount: updatedUsers.filter(
              (user) => user?.status === 'Unread'
            ).length,
          })
          setNotificationData(updatedUsers)
        } else {
          if (response?.data?.message) {
            showToastMessage({
              message: response?.data?.message,
              status: 'error',
            })
          }
        }
      })
      .catch((error) => {
        showToastMessage({
          message: error,
          status: 'error',
        })
      })
  }
  return (
    <>
      <div
        className={`BellNotification ${
          Object.keys(selectedContractData).length > 0 &&
          selectedContractData?.contractId !== undefined
            ? ''
            : 'disabled'
        }`}
      >
        <div
          onClick={
            Object.keys(selectedContractData).length > 0 &&
            selectedContractData?.contractId !== undefined
              ? handleClick
              : () => {}
          }
          data-testid="bellNotificationDiv"
        >
          {/* <NotificationsNoneIcon className="titleBar-iconList" /> */}

          <Badge
            badgeContent={appNotificationData?.NotificationCount}
            color="primary"
          >
            <NotificationsNoneIcon
              color={notificationData?.length >= 0 ? 'action' : 'disabled'}
            />
          </Badge>
        </div>
        {notificationData?.length >= 0 ? (
          <Menu
            className="notify_menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            data-testid="Menu"
          >
            <div>
              {/* 
              Hide the <MenuItem> due to the notification header shout not clickable.
            */}
              {/* <MenuItem
                className="notify_menuItem Header"
                divider
                data-testid="MenuItem"
              > */}
              <div className="notificationContentHeading">Notifications</div>
              {/* </MenuItem> */}
            </div>
            <div className="notificationContentData">
              {notificationData?.map((data, index) => {
                let cssClass = `notify_menuItem`
                if (data?.status === 'Read')
                  cssClass = `notify_menuItem ReadColor`
                else if (data?.status === 'Unread')
                  cssClass = `notify_menuItem UnReadColor`

                return (
                  <div
                    className="divMainNotofication"
                    onClick={() => onRead(data)}
                    data-testid={`onRead_${index}`}
                  >
                    <MenuItem
                      className={cssClass}
                      divider
                      data-testid="MenuItem"
                    >
                      <div className="notificationContent">
                        <div className={`notificationContentText`}>
                          <div
                            className={`${
                              data?.status === 'Read' ? 'read' : 'unread'
                            }`}
                          >
                            <p className="messageText">
                              {data?.message.toString()}
                            </p>
                          </div>
                        </div>
                        <div className={`readCircleDiv`}>
                          <div
                            className={`${
                              data?.status === 'Read' ? 'read' : 'unread'
                            }`}
                          ></div>
                        </div>
                      </div>
                      <div className="notificationContentDate">
                        {utils?.convertUtcDateTimeToTimezoneDateTime(
                          timezoneData?.selectedContract?.timezoneId,
                          data?.expiryStartDate
                        )}
                      </div>
                    </MenuItem>
                  </div>
                )
              })}
            </div>
          </Menu>
        ) : null}
      </div>

      {state?.modalOpen && (
        <ReactModal
          searchData={state?.modaldata}
          modalOpen={state?.modalOpen}
          setmodalOpen={modalclose}
          modaltype="bellNotification"
          showmodal={state}
          selectTab={''}
        />
      )}
    </>
  )
}

export default BellNotification
