/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This Autocomplete component used to select the option value id and value combination with lazyloading
  onChangeHandler : onChangeHandler is callback function recieved from parent component for send the user selected value to parent component
  Field           : Field props recieved from parent component with datasource
  className       : className props recieved from parent component for display and modify the style based on parent component
*/
// System Defined varaibles
import React, { useState, useEffect, useRef } from 'react' //useMemo
import Popper from '@mui/material/Popper'
import Grid from '@mui/material/Grid'
import InfiniteScroll from 'react-infinite-scroller'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import { useRecoilValue, useSetRecoilState } from 'recoil'

// Custom defined variables
import HelpText from '../helpText/HelpText'
import utils from '../../../../utils/utils'
import { fetchDataSelector } from '../../recoil/selectors/selectors'
import { toastMessage } from '../../recoil/atoms/atoms'
import './AutoComplete.css'

/**useCurrentUser = false,id,name,variant,onBlur,fieldErrorMessage,isFocused */
function AutoComplete({
  className,
  field,
  value,
  onChangeHandler,
  outlineError,
  required,
  restrictSorting = false,
  onFocus,
  onScroll = false,
  ...rest
}) {
  const getCoreData = useRecoilValue(fetchDataSelector)
  const showToastMessage = useSetRecoilState(toastMessage)
  // Element Reference
  const inputElementDiv = useRef() // The whole div ref of input - used for popperReference
  const inputElement = useRef() // The ref of input - used for focusin/out
  // lazyLoad
  const [hasMore, setHasMore] = useState(false) // Set true when option have more data to fetch, when true fetchMoreData function will be called
  const [isFetching, setIsFetching] = useState(false) // using this to restrict another fetch when already fetching against hasMoreData
  const [invalidateApiCall, setInvalidateApiCall] = useState(null) // set true when need to fetch next set of records

  const [fetchLength, setFetchLength] = useState(0) // set the fetchLength of data, used to skip on next fetch
  const [dataSourceLength, setDatasourceLength] = useState(0) // the entire count of record to be shown

  const [renderDataSource, setRenderDataSource] = useState([]) // dataSource what will be finally rendered in UI
  const [optionDatasource, setOptionDatasource] = useState([]) // the appended wholeDataSource with '-- None --'
  const [mergedDataSource, setMergedDataSource] = useState([]) // merged all the fetched records from api including all setOf lazyLoad without '-- None --'
  const [dataSourceWithoutLazyLoad, setDataSourceWithoutLazyLoad] = useState([]) // when lazyload disabled, holds datasource with -- None --, used to maintain as global data on filter
  // search
  const [searchMode, setSearchMode] = useState(false) // set true when types on TextField, set false when any option selected
  const [searchText, setSearchText] = useState('') // records what we type on TextField, when this changes searchMode will be enabled and fetches api with 1second delay
  const [selectedOption, setSelectedOption] = useState('') // when value changes setSelectedOption based on renderSource
  const [fetchSearchTerm, setFetchSearchTerm] = useState('') // when searchText changes set to fetchSearchTerm, api call will be run based on fetchSearchTerm
  // dom
  const [showOptions, setShowOptions] = useState(false) // Flag of dropdownOption
  const [anchorEl, setAnchorEl] = useState(null) // set the element, to which the popper refers

  const [onBlurFlag, setOnBlurFlag] = useState(false) // flag will be enabled when mouseDown on options (selecting any option)
  const [onMouseDownFlag, setOnMouseDownFlag] = useState(false) // flag will be enabled when focus blurred from input

  const [fieldObject, setFieldObject] = useState(null) // used to execute useEffect when any property of Field changed (On direct Field dependency useEffect called frequently, so compare and created new object)

  const defaultNoneObject = { id: '-- None --', Name: '-- None --' }
  const dataSourceURL = `${field?.dataSourceURL}?$select=$${field?.valueField},${field?.textField}&$orderby=${field?.textField} asc` // Code CleanUp
  outlineError?.splice(1)

  // reset lazyLoad count and source when got any error
  /* istanbul ignore next */
  /* will inclue in lazyload implementation */
  const resetLazyLoadWhenError = () => {
    setDatasourceLength(0)
    setMergedDataSource([])
    setOptionDatasource([defaultNoneObject])
  }

  useEffect(() => {
    if (onScroll === true) {
      setShowOptions(false)
    }
  }, [onScroll])
  // set lazyLoad count and source when return data from api call or from tabData
  /* istanbul ignore next */
  /* will inclue in lazyload implementation */
  //from
  const lazyLoadCalculation = (responseData) => {
    let filteredData = []
    let count = 0
    if (field?.enableLazyLoad) {
      if (
        responseData?.data?.data?.length > 0 &&
        responseData?.data?.data[0]?.hasOwnProperty('Value')
      ) {
        if (fetchLength === 0) {
          filteredData = responseData?.data?.data[0]?.value
        } else {
          filteredData = responseData?.data?.data[0].value?.filter(
            (item) => item[field?.valueField]?.toLowerCase() !== value
          )
        }
      }
      if (
        responseData?.data?.data?.length > 0 &&
        responseData?.data?.data[0]?.hasOwnProperty('Count')
      ) {
        count = responseData?.data?.data[0]?.Count
      }
    } else {
      filteredData = responseData?.data?.data
      count = responseData?.data?.data?.length
    }

    const mergeWholeData = mergedDataSource?.concat(filteredData)
    setFetchLength(Number(fetchLength) + filteredData?.length)

    setDatasourceLength(count)
    mergeWholeData?.unshift({
      Name: '-- None --',
      id: '-- None --',
    })
    setMergedDataSource([...mergedDataSource?.concat(filteredData)])
    if (field?.dataSourceURL && !field?.enableLazyLoad) {
      setDataSourceWithoutLazyLoad(mergeWholeData)
    }
    setOptionDatasource(mergeWholeData)
  }

  /* istanbul ignore next */
  /* will inclue in lazyload implementation */
  const resetStateValues = () => {
    setInvalidateApiCall(null)
    setFetchLength(0)
    setDatasourceLength(0)
    setHasMore(false)
    setMergedDataSource([])
    setOptionDatasource([])
    setRenderDataSource([])
  }

  // take datasource from apiCall or from Field based on Field properties
  useEffect(() => {
    async function getData() {
      //const dataSource = []
      setInvalidateApiCall(!invalidateApiCall)
    }
    // ---------------------------------------------------------------------------//
    if (JSON.stringify(fieldObject) !== JSON.stringify(field)) {
      resetStateValues()
      setFieldObject({ ...field })
      if (field?.dataSourceURL) {
        // api call will be made regardless of lazy load enablinf, at initial
        getData()
      } else {
        let fieldDataSource = field?.dataSource

        if (fieldDataSource && field?.isSelect !== false) {
          fieldDataSource.unshift({
            Name: '-- None --',
            id: '-- None --',
          })
        }
        fieldDataSource = [
          ...new Map(
            fieldDataSource?.map((item) => [JSON.stringify(item), item])
          )?.values(),
        ]
        if (!field?.isDisableOrder) {
          fieldDataSource = utils?.sortArrayOfObjects(
            fieldDataSource,
            field?.textField
          )
        }
        setOptionDatasource(fieldDataSource)
      }
    }
  }, [field])

  // coreData call with skip and top
  useEffect(() => {
    async function fetchData() {
      //const dataSource = []

      await getCoreData(
        field?.postBody ? 'post' : 'get',
        dataSourceURL?.includes('?')
          ? `${dataSourceURL}&$skip=${fetchLength}&$top=${30}&$count=true${
              fetchSearchTerm
                ? `&$filter=contains(${field?.TextField},'${fetchSearchTerm}')`
                : ``
            }`
          : `${dataSourceURL}?$skip=${fetchLength}&$top=${30}&$count=true${
              fetchSearchTerm
                ? `&$filter=contains(${field?.textField},'${fetchSearchTerm}')`
                : ``
            }`,
        field?.postBody ? field?.postBody : '',
        {
          ...field?.header,
          additionalData: value && value !== '-- None --' ? value || '' : '',
        }
      )
        .then((response) => {
          lazyLoadCalculation(response, 'core')
          setIsFetching(false)
        })
        .catch((err) => {
          showToastMessage({ message: err?.response?.data, status: 'error' })
          resetLazyLoadWhenError()
          setIsFetching(false)
        })
        .finally(() => {})
    }
    if (invalidateApiCall === true || invalidateApiCall === false) {
      if (dataSourceURL) {
        fetchData()
      } else {
        setIsFetching(false)
      }
    }
  }, [invalidateApiCall])

  // on selecting option
  function onSelectOption(event, selectedValue) {
    setOnMouseDownFlag(true)
    setSearchMode(false)

    if (onChangeHandler)
      onChangeHandler(event, {
        id: field?.fieldValue,
        name: field?.name,
        value: selectedValue[field.valueField] ?? '-- None --',
        text: selectedValue[field.textField],
        selectedDataSource: selectedValue,
        type: 'select',
      })
  }

  // on focusOut from input
  function onBlurInput() {
    setOnBlurFlag(true)
  }

  useEffect(() => {
    const dataSource = optionDatasource

    setRenderDataSource([...dataSource])
    if (mergedDataSource?.length < dataSourceLength) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }, [optionDatasource])

  /* istanbul ignore next */
  /* will inclue in lazyload implementation */
  const fetchMoreData = () => {
    if (!isFetching)
      if (mergedDataSource?.length < dataSourceLength) {
        setIsFetching(true)
        setInvalidateApiCall(!invalidateApiCall)
        setHasMore(false)
      }
  }
  // calls when search record against direct datasource from pages (Field?.Datasource)
  const filterSearchedData = (searchedContent) => {
    // for direct provided datasource we dont fetch api for search values instead handled with provided datasource

    let dataSource = field?.dataSource?.filter(
      (item) =>
        item[field?.textField]
          ?.toString()
          ?.toLowerCase()
          .includes(searchedContent?.toString()?.toLowerCase()) ||
        (searchedContent === '' &&
          item?.id?.toString()?.includes(defaultNoneObject?.Name)) // allows to include -- none -- when search empty
    )

    // Commented this due to infinite wait on unit test
    /* // added this to fix unsorted data displayed after first render (i.e whenever user sees the options that should sorted when this key is true)
    if (!field?.isDisableOrder) {
      dataSource = utils?.sortArrayOfObjects(dataSource, field?.textField)
    } */

    setOptionDatasource(dataSource || [])
  }
  // calls when search record against data without lazyload from pages
  /* istanbul ignore next */
  const filterSearchedDataWithoutLazyLoad = (searchedContent) => {
    const dataSource = dataSourceWithoutLazyLoad?.filter(
      (item) =>
        item[field?.textField]
          ?.toString()
          ?.toLowerCase()
          .includes(searchedContent?.toString()?.toLowerCase()) ||
        (searchedContent === '' &&
          item?.id?.toString()?.includes(defaultNoneObject?.Name)) // allows to include -- none -- when search empty
    )
    setOptionDatasource(dataSource || [])
  }
  // executes when input's text changed
  useEffect(() => {
    // allow search when selectOptionTextField !== serachText //restricting this when input changes on any option selection
    if (
      (selectedOption &&
        ((selectedOption?.hasOwnProperty([field?.textField]) &&
          selectedOption[field?.textField] !== searchText) ||
          (!selectedOption?.hasOwnProperty([field?.textField]) &&
            selectedOption[field?.valueField] !== searchText))) ||
      showOptions === true // added this to handle searching selected value after typing others
    ) {
      setSearchMode(true)
      const timeId = setTimeout(
        () => {
          if (field?.dataSource) {
            // for providede datasource and api dataSource we dont fetch api for seacrh values instaed handled with provided datasourec and mergedDatasource
            filterSearchedData(searchText)
          } else if (field?.dataSourceURL && !field?.enableLazyLoad) {
            // when field has datasourceURL and lazyLoad is disabled
            filterSearchedDataWithoutLazyLoad(searchText)
          } else if (searchText !== fetchSearchTerm && field?.enableLazyLoad) {
            setFetchLength(0)
            setMergedDataSource([])
            setFetchSearchTerm(searchText)
            setInvalidateApiCall(!invalidateApiCall)
          }
        },
        searchText ? 1000 : 0 // added this to solve the issue of late rendering of datasource option, when changing drop-down datasource depended on another dropdown after selecting any data
      )
      return () => {
        clearTimeout(timeId)
      }
    }
  }, [searchText])
  useEffect(() => {
    // This is used to handle simultanoues event of option click and blur handler
    // executes this block only when user focused out from input after typing something, without selecting any option
    if (onMouseDownFlag === false && onBlurFlag === true) {
      // With value -> when focus out with searchText / selectedAnyText, then -> the selected text will be replaced in input if textvalue not present then id will be replaced. the id maybe GUID or '-- None --'
      if (value) {
        setSearchText(
          selectedOption[field?.textField] || selectedOption[field?.valueField]
        )
      }
      // Without value -> when focus out replace with default none object text
      else {
        if (field?.isSelect !== false) {
          setSearchText(defaultNoneObject?.id)
        } else {
          setSearchText('')
          //Reloaded data here for - click input, search any invalid data, options will be empty, blur out -> the input will be empty in new mode(value=null). When again input click -> no data will be reload(since no change in searchText). So reloaded the datasource on blur out
          filterSearchedData('')
        }
      }
    }
    // Blur will be the final execution of option select or focus out from input -> therfore can reset the mouseDown and blur flag
    if (onBlurFlag === true) {
      setOnBlurFlag(false)
      setOnMouseDownFlag(false)
      setShowOptions(false)
    }
  }, [onMouseDownFlag, onBlurFlag])
  // executes when value changed or completed api call fetch
  useEffect(() => {
    if (searchMode === false) {
      if (optionDatasource?.length > 0) {
        const data = optionDatasource.find(
          (item) =>
            item[field.valueField]?.toString()?.toLowerCase() ===
            value?.toString()?.toLowerCase()
        )
        if (data) {
          setSearchText(
            data?.hasOwnProperty([field?.textField])
              ? data[field?.textField]
              : data[field.valueField] || ''
          )

          setSelectedOption({ ...data })
        } else {
          if (field?.isSelect !== false) {
            setSearchText('-- None --')
            setSelectedOption({ id: '-- None --', Name: '-- None --' })
          } else if (!value) {
            // done for resetting contract when organisation value changed
            setSearchText('')
            setTimeout(() => {
              setSelectedOption('')
              setSearchMode(() => false)
            }, 0)
          }
        }
      }
    }
  }, [value, optionDatasource, searchMode])

  const controlLabel = (
    <div className="controlLabelDiv">
      <span className="controlLabel">{field && field.fieldLabel}</span>
      <span>{outlineError && outlineError}</span>
      <span>
        {field && field.helpText && <HelpText longText={field.helpText} />}
      </span>
      {required || field?.validation?.isRequired?.toLowerCase() === 'true' ? (
        <span className="Required-color"> *</span>
      ) : (
        ''
      )}
    </div>
  )

  const onClickingInputScroll = () => {
    onClickingInput()
    setShowOptions(false)
  }

  const onClickingInput = () => {
    setShowOptions(true)
    // setAnchorEl(event.currentTarget)
    setAnchorEl(inputElementDiv?.current)
    if (!showOptions) {
      // checking !showOptions to restrict continous click on input itself
      if (
        field?.dataSourceURL &&
        fetchSearchTerm !== '' &&
        field?.enableLazyLoad
      ) {
        setSearchText('')
      } else if (
        field?.dataSourceURL &&
        !field?.enableLazyLoad &&
        searchText !== ''
      ) {
        filterSearchedDataWithoutLazyLoad('')
      } else if (field?.dataSource && searchText !== '') {
        // for providede datasource and api dataSource we dont fetch api for seacrh values instaed handled with provided datasourec and mergedDatasource
        filterSearchedData('')
      }
    }
  }
  // render this when option has icon (Dataype for Add field)
  const renderOption = (option) => (
    <Grid container className="iconSelect">
      <Grid item xs={1.5}>
        <div className="optionIcon">{/* //icon */}</div>
      </Grid>
      <Grid item xs={6}>
        {`${option[field?.textField] || option[field?.valueField] || ''}`}
      </Grid>
    </Grid>
  )

  const adornmentrender = (adornmentOption) => {
    return <div className="AdornmentText"> {adornmentOption} </div>
  }

  return (
    <div className={`autoCompleteLazyLoadctrl `}>
      <div className={`${field?.className}`}>
        {controlLabel}
        <div
          ref={inputElementDiv}
          className={`autoCompleteLazyLoadinputDiv ${
            field?.disabled ? 'disabled' : ''
          }`}
          data-testid="AutoComplete"
        >
          {field?.showAdornment &&
            field?.adornmentPosition === 'start' &&
            adornmentrender(field?.adornmentText)}
          <input
            autoComplete="off"
            data-testid={
              rest?.dataTestId ? rest?.dataTestId : 'autoCompleteInput'
            }
            ref={inputElement}
            type="text"
            id={field?.id || 'ac_inputAutocompleteLazyLoad'}
            name="inputAutocompleteLazyLoad"
            className="autoCompleteInput inputAutocompleteLazyLoad"
            placeholder={field?.placeholder}
            onChange={(event) => {
              setSearchText(event?.target?.value)
            }}
            onClick={onClickingInput}
            onBlur={() => {
              onBlurInput()
            }}
            onFocus={onFocus}
            value={
              field?.isSplit
                ? !showOptions
                  ? searchText?.toString()?.split('-')[0]
                  : searchText?.toString()
                : searchText?.toString()
            }
          />
          {field?.showAdornment &&
            field?.adornmentPosition === 'end' &&
            adornmentrender(field?.adornmentText)}
          {!showOptions ? (
            <ArrowDropDown
              data-testid="ArrowAutoComplete"
              onClick={() => {
                setAnchorEl(inputElementDiv?.current)

                if (
                  field?.dataSourceURL &&
                  fetchSearchTerm !== '' &&
                  field?.enableLazyLoad
                ) {
                  setSearchText('')
                } else if (
                  field?.dataSourceURL &&
                  !field?.enableLazyLoad &&
                  searchText !== ''
                ) {
                  filterSearchedDataWithoutLazyLoad('')
                } else if (field?.dataSource && searchText !== '') {
                  filterSearchedData('')
                }
                inputElement?.current?.focus()
                setShowOptions(true)
              }}
            />
          ) : (
            <ArrowDropUp
              data-testid="ArrowAutoComplete"
              onClick={() => {
                setAnchorEl(inputElementDiv?.current)
                setShowOptions(false)
              }}
            />
          )}
        </div>
      </div>
      {field?.fieldAlert ? (
        <span className="fieldAlert">{field?.fieldAlert}</span>
      ) : null}
      {showOptions && (
        <Popper
          data-testid="autoCompleteLazyLoadPopper"
          className={`autoCompleteLazyLoadPopper ${field?.popperClassName}`}
          open={showOptions}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{
            width: inputElementDiv?.current?.offsetWidth
              ? `${inputElementDiv?.current?.offsetWidth}px`
              : '100%',
          }} // This is to set the width of anchor element to the popper
        >
          <div
            className={`autoCompleteLazyLoad ${field?.className} ${className}`}
          >
            <div className="infiniteScrollDiv">
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
                onClick={onClickingInputScroll}
                useWindow={false}
                threshold={100} // The reaching frequency of scroll to fetch more data
              >
                {renderDataSource?.map((optionData, index) => (
                  <div
                    className={`optionLabel ${
                      optionData.hasOwnProperty(field?.valueField) &&
                      optionData[field?.valueField]
                        ?.toString()
                        ?.toLowerCase() === value?.toString()?.toLowerCase()
                        ? 'selected'
                        : ''
                    }`}
                    data-testId={`${
                      rest?.dataTestId || 'ac'
                    }_optionLabel_${index}`}
                    key={index}
                    onMouseDown={(event) => {
                      onSelectOption(event, optionData)
                    }}
                  >
                    {optionData?.Icon
                      ? renderOption(optionData, index)
                      : `${
                          optionData?.hasOwnProperty([field?.textField])
                            ? optionData[field?.textField]
                            : optionData[field?.valueField] || ''
                        }`}
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </Popper>
      )}
    </div>
  )
}
export default AutoComplete
