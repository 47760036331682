/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* the component used to data showing grid based component. */

// System defined variables
import React from 'react' //useRef
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

// Custom defined variables
import MoreVertIcon from '../../base/moreVertIcon/MorevertIcon'

import './Grid.css'

//import _ from 'lodash'

// Register the required feature modules with the Grid
//ModuleRegistry.registerModules([InfiniteRowModelModule])
/** onCopy,className,entityName,onGridRowEditHandler,useExternalFilters,infiniteInitialRowCount,serverSideStoreType,onGridRowDelete */
const Grid = ({
  dataSource,
  data,
  pagination,
  paginationPageSize,
  floatingFilter,
  onRowClicked,
  onCellClicked,
  onCellDoubleClicked,
  onEdit,
  onDelete,
  onPreview,
  onDownload,
  onSendEmail,
  onGridReady,
  rowModelType,
  cacheOverflowSize,
  maxBlocksInCache,
  cacheBlockSize,
  listviewergridref,
  columnDefs,
  rowHeight,
  className,
  scrollbarWidth,
  overlayNoRowsTemplateText,
  onSelectionChanged,
  rowSelection,
  getRowStyle,
  onApprove,
  onReRun,
}) => {
  //const gridRef = useRef()

  const { rowData } = dataSource || data
  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
    // Using Flex Grid Columns width will be auto adjust. To specify minWidth or actual width min and maxWidth can be passed from coulmndef
    flex: 1,
    minWidth: 200,
    lockPinned: true,
  }
  const gridproptarray = []

  if (onPreview)
    gridproptarray.push({
      ActionName: 'Preview',
      ActionHandler: onPreview,
    })
  if (onEdit)
    gridproptarray.push({
      ActionName: 'Edit',
      ActionHandler: onEdit,
    })
  if (onDelete)
    gridproptarray.push({
      ActionName: 'Delete',
      ActionHandler: onDelete,
    })
  if (onApprove) {
    gridproptarray.push({
      ActionName: 'Approve',
      ActionHandler: onApprove,
    })
  }
  if (onReRun) {
    gridproptarray.push({
      ActionName: 'Rerun',
      ActionHandler: onReRun,
    })
  }
  if (onDownload)
    gridproptarray.push({
      ActionName: 'Download',
      ActionHandler: onDownload,
    })
  if (onSendEmail)
    gridproptarray.push({
      ActionName: 'Send Email',
      ActionHandler: onSendEmail,
    })

  const gridProperties = {
    RowActions: gridproptarray,
  }
  const gridCellComponents = {
    MoreVertIcon: (params) => {
      if (params?.data && gridProperties) {
        return (
          <MoreVertIcon
            className={className}
            RowActions={params?.data?.rowActions ?? gridProperties?.RowActions}
            {...params}
          />
        )
      }
    },
  }

  return (
    <div className="aggridbasecomponent">
      <div className="ag-theme-alpine">
        <AgGridReact
          ref={listviewergridref}
          defaultColDef={defaultColDef}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          rowData={data && data?.rowData && [...data?.rowData]}
          // rowSelection={'multiple'}
          //rowSelection={'single'}
          rowSelection={rowSelection !== '' ? rowSelection : 'single'}
          rowModelType={rowModelType || 'clientSide'}
          components={gridCellComponents}
          //how many record show in grid below the option
          paginationPageSize={paginationPageSize}
          floatingFilter={floatingFilter}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked && onRowClicked}
          onCellClicked={onCellClicked && onCellClicked}
          onCellDoubleClicked={onCellDoubleClicked && onCellDoubleClicked}
          overlayNoRowsTemplate={
            rowData?.length > 0
              ? `<span className="custom-loading-overlay">${
                  overlayNoRowsTemplateText
                    ? overlayNoRowsTemplateText
                    : 'No records to show'
                }</span>`
              : `<span className="custom-loading-overlay">${
                  overlayNoRowsTemplateText
                    ? overlayNoRowsTemplateText
                    : 'No records to show'
                }</span>`
          }
          overlayLoadingTemplate={
            rowData?.length > 0
              ? '<span className="custom-loading-overlay">Please wait while your records are loading</span>'
              : `${'<span className="custom-loading-overlay">Please wait while your records are loading</span>'}`
          }
          //getRowNodeId={(gridData) => gridData.athlete ?? gridData.athlete}
          maintainColumnOrder
          cacheOverflowSize={cacheOverflowSize}
          //infiniteInitialRowCount={0}
          maxBlocksInCache={maxBlocksInCache}
          maxConcurrentDatasourceRequests={1}
          //how many record get the API
          cacheBlockSize={cacheBlockSize}
          //enableRangeSelection={true}
          pageable={false}
          pagination={pagination}
          rowBuffer={0}
          {...dataSource}
          rowHeight={rowHeight ? rowHeight : 50}
          scrollbarWidth={scrollbarWidth || null}
          suppressRowClickSelection={true}
          onSelectionChanged={onSelectionChanged && onSelectionChanged}
          getRowStyle={getRowStyle}
        />
      </div>
    </div>
  )
}
Grid.defaultProps = {
  dataSource: null,
  data: null,
  pagination: null,
  paginationPageSize: null,
  floatingFilter: null,
  onRowClicked: null,
  onGridReady: null,
  cacheOverflowSize: null,
  rowModelType: null,
  maxBlocksInCache: null,
  infiniteInitialRowCount: null,
  cacheBlockSize: null,
  serverSideStoreType: null,
  rowHeight: 50,
  onSelectionChanged: null,
}

Grid.propTypes = {
  dataSource: PropTypes.shape({
    columnDefs: PropTypes.arrayOf(PropTypes.any),
    rowData: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.bool,
    paginationPageSize: PropTypes.number,
  }),
  data: PropTypes.objectOf(PropTypes.any),
  pagination: PropTypes.bool,
  paginationPageSize: PropTypes.number,
  floatingFilter: PropTypes.bool,
  onRowClicked: PropTypes.func,
  onGridReady: PropTypes.func,
  onSelectionChanged: PropTypes.func,
}
export default Grid
