/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This file used to store the API urls thourgh the application in JSON object format
  EX: https://jsonplaceholderqa.typicode.com/api/GetHomeData
*/
/* istanbul ignore file */

const apiUrl = { general: '/api/', case: '/api/Case' }
const apiMethod = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
  patch: 'PATCH',
}
const APIEndPoints = {
  getCaseData: {
    url: apiUrl?.case,
    method: apiMethod?.get,
    methodname: 'getData',
  },
  postCaseData: {
    url: apiUrl?.case,
    method: apiMethod?.post,
    methodname: 'postData',
  },
  deleteCaseData: {
    url: apiUrl?.case,
    method: apiMethod?.delete,
    methodname: 'deleteData',
  },
  getUserRoleCount: {
    url: `${apiUrl?.general}User/Role/Count`,
    method: apiMethod?.get,
    methodname: 'getUserRoleCount',
  },
  getSearchBarData: {
    url: `${apiUrl?.case}/Search`,
    method: apiMethod?.post,
    methodname: 'getData Search Data',
  },
  getAttachments: {
    url: apiUrl?.case,
    method: apiMethod?.post,
    methodname: 'getAttachments',
  },
  postAttachmentData: {
    url: apiUrl?.case,
    method: apiMethod?.post,
    methodname: 'postAttachmentData',
  },
  getCasePrefixes: {
    url: `${apiUrl?.case}/GetCasePrefixes`,
    method: apiMethod?.post,
    methodname: 'getCasePrefixes',
  },
  getContractsForPrefix: {
    url: `${apiUrl?.general}Contracts`,
    method: apiMethod?.get,
    methodname: 'getContractsForPrefix',
  },
  casePrefix: {
    url: `${apiUrl?.case}/CasePrefix`,
    method: apiMethod?.get,
    methodname: 'casePrefix',
  },
  getCountryLookUpData: {
    url: `${apiUrl?.general}Country`,
    method: apiMethod?.get,
    methodname: 'getCountryLookUpData',
  },
  getTitleLookUpData: {
    url: `${apiUrl?.general}title`,
    method: apiMethod?.get,
    methodname: 'getTitleLookUpData',
  },
  getOrganisationData: {
    url: `${apiUrl?.general}GetOrganisations`,
    method: apiMethod?.post,
    methodname: 'get Organisation ',
  },
  editOrganisationData: {
    url: `${apiUrl?.general}Organisation`,
    method: apiMethod?.get,
    methodname: 'get Organisation by ID',
  },
  postOrganisationData: {
    url: `${apiUrl?.general}Organisation`,
    method: apiMethod?.post,
    methodname: 'Add Organisation',
  },
  putOrganisationData: {
    url: `${apiUrl?.general}Organisation`,
    method: apiMethod?.put,
    methodname: 'Update Organisation',
  },
  getContactAddress: {
    url: `${apiUrl?.general}ContactAddress/__contactId__/Contracts/__contractId__`,
    method: apiMethod?.get,
    methodname: 'Get ContactAddress List',
  },
  putContactAddressKeeper: {
    url: `${apiUrl?.general}ContactAddress/Keeper/Contract/__contractId__`,
    method: 'put',
    methodname: 'ContactAddressKeeper',
  },
  putContactAddress: {
    url: `${apiUrl?.general}ContactAddress`,
    method: apiMethod?.put,
    methodname: 'Update ContactAddress',
  },
  deleteContactAddress: {
    url: `${apiUrl?.general}ContactAddress/__contactAddressId__/Contracts/__contractId__`,
    method: apiMethod?.delete,
    methodname: 'Delete ContactAddress',
  },
  Contract: {
    postGet: {
      url: `${apiUrl?.general}Contracts`,
      method: apiMethod?.post,
      methodname: 'GetContracts List',
    },
    post: {
      url: `${apiUrl?.general}Contract`,
      method: apiMethod?.post,
      methodname: 'PostContracts',
    },
    Get: {
      url: `${apiUrl?.general}Contract`,
      method: apiMethod?.get,
      methodname: 'GetContracts',
    },
    postFaultName: {
      url: `${apiUrl?.general}FaultType`,
      method: apiMethod?.post,
      methodname: 'PostFaultType',
    },
    ContractsByOrganisation: {
      url: `${apiUrl?.general}ContractsByOrganisation`,
      method: apiMethod?.post,
      methodname: 'ContractsByOrganisation',
    },
    caseOverView: {
      url: `${apiUrl?.general}Contract/__contractId__/CaseOverview`,
      method: apiMethod?.get,
      methodname: 'caseOverView',
    },
    paymentSummary: {
      url: `${apiUrl?.general}Contract/__contractId__/CasePaymentSummary`,
      method: apiMethod?.get,
      methodname: 'paymentSummary',
    },
    ActiveDaysRulesDetails: {
      url: `${apiUrl?.general}Contract/__contractId__/ActiveDaysRulesDetails`,
      method: apiMethod?.get,
      methodname: 'ActiveDaysRulesDetails',
    },
    ActiveDaysRulesPut: {
      url: `${apiUrl?.general}Contract/__contractId__/rule`,
      method: apiMethod?.put,
      methodname: 'ActiveDaysRulesDetails',
    },
    getContractType: {
      url: `${apiUrl?.general}contract/type`,
      method: apiMethod?.get,
      methodname: 'Contract type data',
    },
  },
  DVLARequest: {
    getDataPostMethodVQ4: {
      url: `${apiUrl?.general}DVLA/Contract/__contractId__/GetVQ4Request`,
      method: apiMethod?.post,
      methodname: 'get V4 request method',
    },
    getDataPostMethodVQ5: {
      url: `${apiUrl?.general}DVLA/Contract/__contractId__/GetVQ5Response`,
      method: apiMethod?.post,
      methodname: 'get V5 request method',
    },
    getDataPostMethodProcessData: {
      url: `${apiUrl?.general}DVLA/Contract/__contractId__/GetProcess`,
      method: apiMethod?.post,
      methodname: 'Get Mismatched Data with PCN number',
    },
    putUpdateMethodProcessData: {
      url: `${apiUrl?.general}DVLA/Contract/__contractId__/DvlaProcess`,
      method: apiMethod?.put,
      methodname: 'Update Process Batch List Data',
    },
    dvlaDownloadEnquiryBatch: {
      url: `${apiUrl?.general}DVLA/GetVQ4RequestFile`,
      method: apiMethod?.post,
      methodname: 'dvlaDownloadEnquiryBatch',
    },
    dvlaDownloadResponseBatch: {
      url: `${apiUrl?.general}DVLA/GetVQ5ResponseFile`,
      method: apiMethod?.post,
      methodname: 'dvlaDownloadResponseBatch',
    },
    getDvlaAttachments: {
      url: `${apiUrl?.general}DVLA/Case/__caseId__/GetDVLAAttachments`,
      method: apiMethod?.get,
      methodname: 'getDvlaAttachments',
    },
    putVQ616Progress: {
      url: `${apiUrl?.general}DVLA/Contract/__contractId__/VQ616Progress`,
      method: apiMethod?.put,
      methodname: 'putVQ616Progress',
    },
    putVQ616CancelCase: {
      url: `${apiUrl?.general}DVLA/Contract/__contractId__/VQ616CancelCase`,
      method: apiMethod?.put,
      methodname: 'putVQ616CancelCase',
    },
  },
  payment: {
    refund: {
      url: `${apiUrl?.general}Payment/Refund`,
      method: apiMethod?.post,
      methodname: 'refund',
    },
    paymentReason: {
      url: `${apiUrl?.general}PaymentReason`,
      method: apiMethod?.get,
      methodname: 'paymentReason',
    },
    paymentMethod: {
      url: `${apiUrl?.general}PaymentMethod`,
      method: apiMethod?.post,
      methodname: 'paymentMethod',
    },
    chargeBack: {
      url: `${apiUrl?.general}Payment/ChargeBack`,
      method: apiMethod?.post,
      methodname: 'chargeBack',
    },
    searchCase: {
      url: `${apiUrl?.general}Payment/Search`,
      method: apiMethod?.post,
      methodname: 'searchCase',
    },
    transfer: {
      url: `${apiUrl?.general}Payment/Transfer`,
      method: apiMethod?.post,
      methodname: 'transfer',
    },
    record: {
      url: `${apiUrl?.general}Payment/Record`,
      method: apiMethod?.post,
      methodname: 'record',
    },
    closeWithPartPayment: {
      url: `${apiUrl?.general}Payment/CloseWithPartPayment`,
      method: apiMethod?.put,
      methodname: 'closeWithPartPayment',
    },
  },
  getRegionLookUpData: {
    url: `${apiUrl?.general}Region`,
    method: apiMethod?.get,
    methodname: 'getRegionLookUpData',
  },
  getTribunalLookUpData: {
    url: `${apiUrl?.general}Tribunal`,
    method: apiMethod?.get,
    methodname: 'getTribunalLookUpData',
  },
  getChargeRateLookUpData: {
    url: `${apiUrl?.general}ChargeRate/contract/__contractType__`,
    method: apiMethod?.get,
    methodname: 'getChargeRateLookUpData',
  },
  getReportingTypeLookUpData: {
    url: `${apiUrl?.general}ReportType`,
    method: apiMethod?.get,
    methodname: 'getReportingTypeLookUpData',
  },
  getFaultTypeLookUpData: {
    url: `${apiUrl?.general}FaultType`,
    method: apiMethod?.get,
    methodname: 'getFaultTypeLookUpData',
  },
  getCCTVDoubleJeopardyRuleLookUpData: {
    url: `${apiUrl?.general}CCTVDoubleJeopardyRule`,
    method: 'get',
    methodname: 'getCCTVDoubleJeopardyRule',
  },
  getCountDataForHomeWidget: {
    url: `${apiUrl?.general}Contract/WidgetsCount`,
    method: apiMethod?.get,
    methodname: 'getCountDataForHomeWidget',
  },
  getContractForHomeWidget: {
    url: `${apiUrl?.general}Contract/Widgets`,
    method: apiMethod?.get,
    methodname: 'getContractForHomeWidget',
  },
  addFavouriteContract: {
    url: `${apiUrl?.general}Contract/__contractId__/Favourite`,
    method: apiMethod?.post,
    methodname: 'addFavouriteContract',
  },
  removeFavouriteContract: {
    url: `${apiUrl?.general}Contract/__contractId__/Favourite`,
    method: apiMethod?.delete,
    methodname: 'removeFavouriteContract',
  },
  representationReview: {
    representationReviewGetList: {
      url: `${apiUrl?.general}Workflow/__contractId__/ClientWorkflowTasks`,
      method: apiMethod?.post,
      methodname: 'get Formal stage case numbers',
    },
    representationReviewUpdate: {
      url: `${apiUrl?.general}Workflow/__contractId__/ClientWorkflowAcceptOrReject`,
      method: apiMethod?.put,
      methodname: 'update status of the case number',
    },
  },
  getApprovalQueueListData: {
    url: `${apiUrl?.general}Correspondences`,
    method: apiMethod?.post,
    methodname: 'getData',
  },
  getCorrespondenceFileData: {
    url: `${apiUrl?.general}CorrespondencePreview`,
    method: apiMethod?.post,
    methodname: 'getData',
  },

  contraventionCodes: {
    postcontravention: {
      url: `${apiUrl?.general}Contract`,
      method: apiMethod?.post,
      methodname: 'Add Contravention',
    },
    getcontravention: {
      url: `${apiUrl?.general}ContraventionCode`,
      method: apiMethod?.get,
      methodname: 'Get Contravention',
    },
    putcontravention: {
      url: `${apiUrl?.general}Contract`,
      method: apiMethod?.put,
      methodname: 'Update Contravention',
    },
    deletecontravention: {
      url: `${apiUrl?.general}ContraventionCode`,
      method: apiMethod?.delete,
      methodname: 'Delete Contravention',
    },
    getcontraventionPath: {
      url: `${apiUrl?.general}ContraventionPath`,
      method: apiMethod?.get,
      methodname: 'ContraventionPath lookup data',
    },
    getdifferentialLevel: {
      url: `${apiUrl?.general}DifferentialLevel`,
      method: apiMethod?.get,
      methodname: 'DifferentialLevel lookup data',
    },
    getdvlaEnquirycode: {
      url: `${apiUrl?.general}DVLAEnquiryCode`,
      method: apiMethod?.get,
      methodname: 'DVLAEnquiryCode lookup data',
    },
    getlocationGroup: {
      url: `${apiUrl?.general}LocationGroup`,
      method: apiMethod?.get,
      methodname: 'LocationGroup lookup data',
    },
    getContraventionCodes: {
      url: `${apiUrl?.general}Contract/__contractId__/contraventionCodes`,
      method: apiMethod?.get,
      methodname: 'LocationGroup lookup data',
    },
    contraventionUpload: {
      url: `${apiUrl?.general}ContraventionCode/Upload`,
      method: apiMethod?.post,
      methodname: 'PostContravention',
    },
    contraventionSampleDataDownload: {
      url: `${apiUrl?.general}template/Download`,
      method: apiMethod?.post,
      methodname: 'contraventionSampleDataDownload',
    },
    getContraventionErrorsExport: {
      url: `${apiUrl?.general}ContraventionCode/GetContraventionCodeErrorsExport`,
      method: apiMethod?.post,
      methodname: 'getContraventionErrorsExport',
    },
    getContraventionErrors: {
      url: `${apiUrl?.general}ContraventionCode/GetContraventionCodeErrors`,
      method: apiMethod?.post,
      methodname: 'GetContraventionErrors',
    },
    validationStatus: {
      url: `${apiUrl?.general}ContraventionCode/ValidationStatus`,
      method: apiMethod?.post,
      methodname: 'validationStatus',
    },
  },

  templates: {
    templateType: {
      url: `${apiUrl?.general}Template/Contract/_contractType_/TemplateType`,
      method: apiMethod?.get,
      methodname: 'Template Type Data',
    },
    notificationType: {
      url: `${apiUrl?.general}Template/NotificationType`,
      method: apiMethod?.get,
      methodname: 'Notification Type Data',
    },
    templateLayoutTypes: {
      url: `${apiUrl?.general}LayoutType`,
      method: apiMethod?.get,
      methodname: 'Layout Type Data',
    },
    templateData: {
      url: `${apiUrl?.general}Templates`,
      method: apiMethod?.post,
      methodname: 'Template Data',
    },
    headerFooterList: {
      url: `${apiUrl?.general}Template/HeaderOrFooterList`,
      method: apiMethod?.post,
      methodname: 'Header Footer List Data',
    },
    hhdNoticeTemplateList: {
      url: `${apiUrl?.general}HHDTemplates`,
      method: apiMethod?.post,
      methodname: 'HHD notice template list data',
    },
    statDocType: {
      url: `${apiUrl?.general}Template/Contract/_contractType_/PrintBatchType`,
      method: apiMethod?.get,
      methodname: 'StatDocType',
    },
    post: {
      HeaderFooterTemplate: {
        url: `${apiUrl?.general}Template/HeaderOrFooter`,
        method: apiMethod?.post,
        methodname: 'HeaderFooterTemplate Data',
      },
      Template: {
        url: `${apiUrl?.general}Template`,
        method: apiMethod?.post,
        methodname: 'Template Data',
      },
      hhdNoticeTemplate: {
        url: `${apiUrl?.general}HHDTemplate`,
        method: apiMethod?.post,
        methodname: 'HHD notice template Data Insert',
      },
    },
    delete: {
      HeaderFooterTemplate: {
        url: `${apiUrl?.general}Template/HeaderOrFooter`,
        method: apiMethod?.delete,
        methodname: 'HeaderFooterTemplate Data',
      },
      Template: {
        url: `${apiUrl?.general}Template`,
        method: apiMethod?.delete,
        methodname: 'Template Data',
      },
    },
    preview: {
      PreviewHeaderOrFooter: {
        url: `${apiUrl?.general}Template/PreviewHeaderOrFooter`,
        method: apiMethod?.get,
        methodname: 'PreviewHeaderOrFooter Data',
      },
      PreviewContent: {
        url: `${apiUrl?.general}Preview`,
        method: apiMethod?.get,
        methodname: 'PreviewContent',
      },
      TemplatePreviewContent: {
        url: `${apiUrl?.general}Template/PreviewContent`,
        method: apiMethod?.get,
        methodname: 'TemplatePreviewContent',
      },
      HhdNoticeTemplate: {
        url: `${apiUrl?.general}Template/PreviewContent/__templateId__`,
        method: apiMethod?.get,
        methodname: 'Preview Hhd Notice Template',
      },
      PreviewEditContent: {
        url: `${apiUrl?.general}Template/PreviewContent`,
        method: apiMethod?.get,
        methodname: 'PreviewContent',
      },
    },
    GetById: {
      HeaderOrFooter: {
        url: `${apiUrl?.general}Template/HeaderOrFooter`,
        method: apiMethod?.get,
        methodname: 'HeaderOrFooter Data',
      },
      Template: {
        url: `${apiUrl?.general}Template`,
        method: apiMethod?.get,
        methodname: 'Template',
      },
    },
    put: {
      HeaderFooter: {
        url: `${apiUrl?.general}Template/HeaderOrFooter`,
        method: apiMethod?.put,
        methodname: 'HeaderFooter Template Data',
      },
      Template: {
        url: `${apiUrl?.general}Template`,
        method: apiMethod?.put,
        methodname: 'Template Data',
      },
      hhdNoticeTemplate: {
        url: `${apiUrl?.general}HHDTemplate`,
        method: apiMethod?.post,
        methodname: 'HHD notice template Data Insert',
      },
    },
  },
  userFlow: {
    url: `${apiUrl?.general}Login/UserFlow`,
    method: apiMethod?.post,
    methodname: 'userFlow',
  },

  userPermissionCheck: {
    url: `${apiUrl?.general}login`,
    method: apiMethod?.get,
    methodname: 'userPermissionCheck',
  },
  Currency: {
    getAll: {
      url: `${apiUrl?.general}Currency`,
      method: apiMethod?.get,
      methodname: 'GetCurrency',
    },
  },
  FAQ: {
    getAll: {
      url: `${apiUrl?.general}ChallengeFAQs`,
      method: apiMethod?.post,
      methodname: 'GetFaq',
    },
    post: {
      url: `${apiUrl?.general}ChallengeFAQ`,
      method: apiMethod?.post,
      methodname: 'PostFaq',
    },
    put: {
      url: `${apiUrl?.general}ChallengeFAQ`,
      method: apiMethod?.put,
      methodname: 'PutFaq',
    },
    GetById: {
      url: `${apiUrl?.general}ChallengeFAQ`,
      method: apiMethod?.get,
      methodname: 'GetFaq',
    },
    delete: {
      url: `${apiUrl?.general}ChallengeFAQ`,
      method: apiMethod?.delete,
      methodname: 'deleteFaq',
    },
    upload: {
      url: `${apiUrl?.general}ChallengeFAQ/Upload`,
      method: apiMethod?.post,
      methodname: 'PostFaq',
    },
    faqSampleDataDownload: {
      url: `${apiUrl?.general}template/Download`,
      method: apiMethod?.post,
      methodname: 'faqSampleDataDownload',
    },
    getFAQErrorsExport: {
      url: `${apiUrl?.general}ChallengeFAQ/GetFAQErrorsExport`,
      method: apiMethod?.post,
      methodname: 'getFAQErrorsExport',
    },
    getFAQErrors: {
      url: `${apiUrl?.general}ChallengeFAQ/GetFAQErrors`,
      method: apiMethod?.post,
      methodname: 'GetFAQErrors',
    },
    validationStatus: {
      url: `${apiUrl?.general}ChallengeFAQ/validationStatus`,
      method: apiMethod?.post,
      methodname: 'validationStatus',
    },
  },
  Users: {
    gtUser: {
      url: `${apiUrl?.general}Users `,
      method: apiMethod?.post,
      methodname: 'Get User data',
    },
    createUser: {
      url: `${apiUrl?.general}BackOffice/CreateUser`,
      method: apiMethod?.post,
      methodname: 'createUser',
    },
    updateUser: {
      url: `${apiUrl?.general}BackOffice/UpdateUser`,
      method: apiMethod?.put,
      methodname: 'updateUser',
    },
    getUserById: {
      url: `${apiUrl?.general}BackOffice/User`,
      method: apiMethod?.get,
      methodname: 'gettingUserById',
    },

    getRoles: {
      url: `${apiUrl?.general}UserRoles`,
      method: apiMethod?.get,
      methodname: 'Get all available roles',
    },
    poundLocation: {
      url: `${apiUrl?.general}ClampAndRemoval/PoundLocation/Contract/_contractId_`,
      method: apiMethod?.get,
      methodname: 'Getting pound location data',
    },
  },
  Correspondence: {
    getTemplate: {
      url: `${apiUrl?.general}Template/TemplateList`,
      method: apiMethod?.post,
      methodname: 'Get Templates data',
    },
    getContactAddress: {
      url: `${apiUrl?.case}/__caseId__/GetContactAddress`,
      method: apiMethod?.post,
      methodname: 'ContactAddress',
    },
    getTemplatePreview: {
      url: `${apiUrl?.general}Preview`,
      method: apiMethod?.get,
      methodname: 'Template Preview',
    },
    postSendCorrespondence: {
      url: `${apiUrl?.general}Correspondence`,
      method: apiMethod?.post,
      methodname: 'Correspondence',
    },
    getcorrespondencePreview: {
      url: `${apiUrl?.general}CorrespondencePreview`,
      method: apiMethod?.get,
      methodname: 'CorrespondencePreview',
    },
    postCorrespondenceApprove: {
      url: `${apiUrl?.general}CorrespondenceApprove`,
      method: apiMethod?.post,
      methodname: 'CorrespondenceApprove',
    },
    convertDocxtopdf: {
      url: `${apiUrl?.general}template/convertdocxtopdf`,
      method: apiMethod?.post,
      methodname: 'convertDocxtopdf',
    },
  },
  casePayment: {
    getCasePaymentData: {
      url: `${apiUrl?.case}/__caseId__/Payments `,
      method: apiMethod?.get,
      methodname: 'GetCasePaymentData',
    },
    getPaymentInvoiceDetail: {
      url: `${apiUrl?.case}/__caseId__/GetPaymentInvoiceDetails `,
      method: apiMethod?.post,
      methodname: 'getPaymentInvoiceDetail',
    },
    getManualDiscounts: {
      url: `${apiUrl?.general}Payment/Case/__caseId__/GetManualDiscounts `,
      method: apiMethod?.post,
      methodname: 'getManualDiscounts',
    },
    manualDiscount: {
      url: `${apiUrl?.general}Payment/Case/__caseId__/ManualDiscount `,
      method: apiMethod?.post,
      methodname: 'manualDiscount',
    },
    deleteManualDiscount: {
      url: `${apiUrl?.general}Payment/ManualDiscount/__manualDiscountId__`,
      method: apiMethod?.delete,
      methodname: 'deleteManualDiscount',
    },
  },
  stage: {
    get: {
      url: `${apiUrl?.general}Stage`,
      method: apiMethod?.get,
      methodname: 'GetStage',
    },
  },
  status: {
    get: {
      url: `${apiUrl?.general}Status`,
      method: apiMethod?.get,
      methodname: 'GetStatus',
    },
  },
  updateStageStatus: {
    url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/CancelReason/__cancelReasonId__/UpdateStageAndStatus`,
    method: apiMethod?.put,
    methodname: 'updateStageStatus',
  },
  stageAndStatus: {
    listOfStages: {
      url: `${apiUrl?.case}/Contract/__contractId__/ManualCaseStage`,
      method: apiMethod?.post,
      methodname: 'listOfStages',
    },
    listOfStatusForStage: {
      url: `${apiUrl?.case}/Contract/__contractId__/Stage/__stageName__/ManualCaseStatus`,
      method: apiMethod?.get,
      methodname: 'listOfStatusForStage',
    },
  },
  UpdateTPTVerificationCode: {
    url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/UpdateTPTVerificationCode`,
    method: apiMethod?.put,
    methodname: 'UpdateTPTVerificationCode',
  },

  Workflow: {
    UnassignedTasks: {
      url: `${apiUrl?.general}Workflow/__contractId__/UnassignedTasks`,
      method: apiMethod?.post,
      methodname: 'UnassignedTasks',
    },
    AssignedTasks: {
      url: `${apiUrl?.general}Workflow/__contractId__/AssignedTasks`,
      method: apiMethod?.post,
      methodname: 'AssignedTasks',
    },
    UnassignedHierarchyView: {
      url: `${apiUrl?.general}Workflow/__contractId__/UnassignedHierarchyView`,
      method: apiMethod?.get,
      methodname: 'UnassignedHierarchyView',
    },
    AssignedHierarchyView: {
      url: `${apiUrl?.general}Workflow/__contractId__/AssignedHierarchyView`,
      method: apiMethod?.get,
      methodname: 'AssignedHierarchyView',
    },
    WorkflowManagerBOUsers: {
      url: `${apiUrl?.general}Workflow/__contractId__/WorkflowManagerBOUsers`,
      method: apiMethod?.get,
      methodname: 'WorkflowManagerBOUsers',
    },
    UnassignOrAssign: {
      url: `${apiUrl?.general}Workflow/__contractId__/UnassignOrAssign`,
      method: apiMethod?.put,
      methodname: 'UnassignOrAssign',
    },
    Reassign: {
      url: `${apiUrl?.general}Workflow/__contractId__/Reassign`,
      method: apiMethod?.put,
      methodname: 'Reassign',
    },
    Complete: {
      url: `${apiUrl?.general}Workflow/__contractId__/Complete`,
      method: apiMethod?.put,
      methodname: 'Complete',
    },
    MyWorkflowBOUsers: {
      url: `${apiUrl?.general}Workflow/__contractId__/MyWorkflowBOUsers`,
      method: apiMethod?.get,
      methodname: 'MyWorkflowBOUsers',
    },
    MyWorkflows: {
      url: `${apiUrl?.general}Workflow/__contractId__/MyWorkflows`,
      method: apiMethod?.post,
      methodname: 'MyWorkflows',
    },
    associatedCases: {
      url: `${apiUrl?.general}Workflow/Contract/__contractId__/WorkflowAssociatedCases`,
      method: apiMethod?.post,
      methodname: 'Case associated Validation',
    },
    delete: {
      url: `${apiUrl?.general}Workflow/Contract/__contractId__/DeleteUnassignedTask`,
      method: apiMethod?.delete,
      methodname: 'DeleteWorkflow',
    },
    caseNumberAssignToUnassignedTask: {
      url: `${apiUrl?.general}Workflow/Contract/__contractId__/CaseNumberAssignToUnassignedTask`,
      method: apiMethod?.put,
      methodname: 'caseNumberAssignToUnassignedTask',
    },
    ocrErrorAttachments: {
      url: `${apiUrl?.general}Workflow/__workflowId__/Contract/__contractId__/OCRErrorAttachments`,
      method: apiMethod?.get,
      methodname: 'ocrErrorAttachments',
    },
  },
  TribunalAppeals: {
    TribunalAppealsPost: {
      url: apiUrl?.case,
      method: apiMethod?.post,
      methodname: 'PostTribunalAppeal',
    },
    TribunalAppealsPut: {
      url: apiUrl?.case,
      method: apiMethod?.put,
      methodname: 'PutTribunalAppeals',
    },
    TribunalAppealGet: {
      url: `${apiUrl?.case}/TribunalAppeal`,
      method: apiMethod?.get,
      methodname: 'getTribunalAppeals',
    },
    TribunalAppealsDelete: {
      url: `${apiUrl?.case}/TribunalAppeal`,
      method: apiMethod?.delete,
      methodname: 'deleteTribunalAppeals',
    },
    GetHearingType: {
      url: `${apiUrl?.general}HearingType`,
      method: apiMethod?.get,
      methodname: 'getHearingType',
    },
  },
  Notification: {
    getNotification: {
      url: `${apiUrl?.general}Notification`,
      method: apiMethod?.get,
      methodname: 'Notification',
    },
    putNotification: {
      url: `${apiUrl?.general}Notification`,
      method: apiMethod?.put,
      methodname: 'Notification',
    },
    getMessages: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__/Messages`,
      method: apiMethod?.post,
      methodname: 'Messages',
    },
    getUsers: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__/CEOUsers`,
      method: apiMethod?.get,
      methodname: 'CEOUsers',
    },
    getLocations: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__/Locations`,
      method: apiMethod?.get,
      methodname: 'Locations',
    },
    postNotification: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'Notification',
    },
    getBOUsers: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__/BOUsers`,
      method: apiMethod?.get,
      methodname: 'BOUsers',
    },
    getVRMNumbers: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__/VRMNumbers`,
      method: apiMethod?.get,
      methodname: 'getVRMNumbers',
    },
    getContraventionCodes: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__/ContraventionCodes`,
      method: apiMethod?.get,
      methodname: 'getContraventionCodes',
    },
    getMessage: {
      url: `${apiUrl?.general}Notification/Contract/__contractId__/Message/__ReferenceId__`,
      method: apiMethod?.get,
      methodname: 'getMessage',
    },
  },
  Zone: {
    getZones: {
      url: `${apiUrl?.general}Zones`,
      method: apiMethod?.post,
      methodname: 'Zones',
    },
    getZone: {
      url: `${apiUrl?.general}Zone`,
      method: apiMethod?.get,
      methodname: 'Zone',
    },
    postZone: {
      url: `${apiUrl?.general}Zone`,
      method: apiMethod?.post,
      methodname: 'Zone',
    },
    putZone: {
      url: `${apiUrl?.general}Zone`,
      method: apiMethod?.put,
      methodname: 'Zone',
    },
    deleteZone: {
      url: `${apiUrl?.general}Zone`,
      method: apiMethod?.delete,
      methodname: 'Zone',
    },
    zoneSampleDataDownload: {
      url: `${apiUrl?.general}Template/Download`,
      method: apiMethod?.post,
      methodname: 'sampleDownload',
    },
    zoneUpload: {
      url: `${apiUrl?.general}Zone/Upload`,
      method: apiMethod?.post,
      methodname: 'Zone',
    },
    uploadedFileErrorsExport: {
      url: `${apiUrl?.general}Zone/UploadedFileErrorsExport`,
      method: apiMethod?.post,
      methodname: 'uploadedFileErrorsExport',
    },
    getZoneErrors: {
      url: `${apiUrl?.general}Zone/UploadedFileErrors`,
      method: apiMethod?.post,
      methodname: 'UploadedFileErrors',
    },
    validationStatus: {
      url: `${apiUrl?.general}Zone/BulkUploadStatus`,
      method: apiMethod?.post,
      methodname: 'UploadedFileErrors',
    },
  },
  cancelReason: {
    url: `${apiUrl?.general}CancelReason/Contract/__contractId__`,
    method: apiMethod?.get,
    methodname: 'cancelReason',
  },
  cancellationReason: {
    getCancelReason: {
      url: `${apiUrl?.general}GetCancelReasons`,
      method: apiMethod?.post,
      methodname: 'cancelReason',
    },
    postCancelReason: {
      url: `${apiUrl?.general}CancelReason`,
      method: apiMethod?.post,
      methodname: 'cancelReason',
    },
    deleteCancelReason: {
      url: `${apiUrl?.general}CancelReason`,
      method: apiMethod?.delete,
      methodname: 'cancelReason',
    },
    exportCancelReason: {
      url: `${apiUrl?.general}CancelReason/Export`,
      method: apiMethod?.get,
      methodname: 'Export cancelReason',
    },
    cancelReasonValidationAssociated: {
      url: `${apiUrl?.general}CancelReason/__caseId__/Contract/__contractId__/Reference`,
      method: apiMethod?.get,
      methodname: 'Associated validation',
    },
    upload: {
      url: `${apiUrl?.general}CancelReason/Upload`,
      method: apiMethod?.post,
      methodname: 'Postupload',
    },
    cancellationReasonSampleDataDownload: {
      url: `${apiUrl?.general}template/Download`,
      method: apiMethod?.post,
      methodname: 'cancellationReasonSampleDataDownload',
    },
    getCancellationReasonErrorsExport: {
      url: `${apiUrl?.general}CancelReason/GetCancelReasonErrorsExport`,
      method: apiMethod?.post,
      methodname: 'getCancellationReasonErrorsExport',
    },
    getCancellationReasonErrors: {
      url: `${apiUrl?.general}CancelReason/GetCancelReasonErrors`,
      method: apiMethod?.post,
      methodname: 'getCancellationReasonErrors',
    },
    validationStatus: {
      url: `${apiUrl?.general}CancelReason/validationStatus`,
      method: apiMethod?.post,
      methodname: 'validationStatus',
    },
  },
  holdReason: {
    getHoldReasons: {
      url: `${apiUrl?.general}HoldReasons`,
      method: apiMethod?.post,
      methodname: 'holdReason',
    },
    postHoldReason: {
      url: `${apiUrl?.general}HoldReason`,
      method: apiMethod?.post,
      methodname: 'holdReason',
    },
    putHoldReason: {
      url: `${apiUrl?.general}HoldReason/__id__`,
      method: apiMethod?.post,
      methodname: 'holdReason',
    },
    deleteHoldReason: {
      url: `${apiUrl?.general}HoldReason`,
      method: apiMethod?.delete,
      methodname: 'holdReason',
    },
    exportHoldReasons: {
      url: `${apiUrl?.general}HoldReason/Export`,
      method: apiMethod?.get,
      methodname: 'Export holdReason',
    },
  },
  case: {
    cancel: {
      url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/Cancel`,
      method: 'Patch',
      methodname: 'cancelCase',
    },
  },

  caseProgression: {
    reversal: {
      url: `${apiUrl?.general}CaseProgression/Contract/__contractId__/revert`,
      method: 'Patch',
      methodname: 'reversal',
    },
    revertIssued: {
      url: `${apiUrl?.general}CaseProgression/Contract/__contractId__/revert`,
      method: 'Patch',
      methodname: 'revert Issued',
    },
    stageStatusSettings: {
      url: `${apiUrl?.general}CaseProgression/StageAndStatus/Contract/__contractId__`,
      method: 'Patch',
      methodname: 'stageStatusSettings',
    },
    tribunalAppealAllowed: {
      url: `${apiUrl?.general}Case/__caseId__/CancelReason/__CancelReasonId__/Contract/__contractId__/TribunalAppealAllowed`,
      method: apiMethod?.put,
      methodname: 'TribunalAppealAllowed',
    },
    tribunalAppealReject: {
      url: `${apiUrl?.general}Case/__caseId__/Contract/__contractId__/TribunalAppealReject`,
      method: apiMethod?.put,
      methodname: 'TribunalAppealReject',
    },
    tribunalAppealWitness: {
      url: `${apiUrl?.general}Case/__caseId__/Contract/__contractId__/TribunalAppealWitness`,
      method: apiMethod?.put,
      methodname: 'TribunalAppealWitness',
    },
    noaWarningNotice: {
      url: `${apiUrl?.general}CaseProgression/Contract/__contractId__/__contractType__/WarningNoticeRevert`,
      method: apiMethod?.patch,
      methodname: 'NOAWarningNotice',
    },
    discountCCRepReject: {
      url: `${apiUrl?.general}CaseProgression/Case/__caseId__/Contract/__contractId__/DiscountCCRepReject`,
      method: apiMethod?.patch,
      methodname: 'DiscountCCRepReject',
    },
    discountOFRRepReject: {
      url: `${apiUrl?.general}CaseProgression/Case/__caseId__/Contract/__contractId__/DiscountOFRRepReject`,
      method: apiMethod?.patch,
      methodname: 'discountOFRRepReject',
    },
    discountPreNTORepReject: {
      url: `${apiUrl?.general}CaseProgression/Case/__caseId__/Contract/__contractId__/DiscountPreNtOChallengeReject`,
      method: apiMethod?.patch,
      methodname: 'discountPreNTORepReject',
    },
    discountRepReject: {
      url: `${apiUrl?.general}CaseProgression/Case/__caseId__/Contract/__contractId__/DiscountRepReject`,
      method: apiMethod?.patch,
      methodname: 'discountRepReject',
    },
    actioned: {
      url: `${apiUrl?.general}CaseProgression/Contract/__contractId__/Action`,
      method: apiMethod?.patch,
      methodname: 'actioned',
    },
    dayLetterReverted: {
      url: `${apiUrl?.general}CaseProgression/Contract/__contractId__/DayLetterRevert`,
      method: apiMethod?.patch,
      methodname: 'dayLetterReverted',
    },
    reClosefollowingPaidCorrespondence: {
      url: `${apiUrl?.general}CaseProgression/Case/__caseId__/Contract/__contractId__/ReClosefollowingPaidCorrespondence`,
      method: apiMethod?.patch,
      methodname: 'ReClosefollowingPaidCorrespondence',
    },
    challengeAction: {
      url: `${apiUrl?.general}CaseProgression/Case/__caseId__/Contract/__contractId__/ChallengeAction`,
      method: apiMethod?.patch,
      methodname: 'ChallengeAction',
    },
    revertToIssuedDiscount: {
      url: `${apiUrl?.general}CaseProgression/Case/__caseId__/Contract/__contractId__/DiscountRevertToIssued`,
      method: apiMethod?.patch,
      methodname: 'RevertToIssuedDiscount',
    },
    courtPackDownload: {
      url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/DownloadCourtPack`,
      method: apiMethod?.get,
      methodname: 'downloadCourtPack',
    },
  },
  Debt: {
    getDebts: {
      url: `${apiUrl?.general}Debt/Contract/__contractId__/GetDebt`,
      method: apiMethod?.post,
      methodname: 'Debts',
    },
    generateNewRequest: {
      url: `${apiUrl?.general}Debt/Contract/__contractId__/GenerateNewRequest`,
      method: apiMethod?.post,
      methodname: 'Debts',
    },
    getDebtCaseSearch: {
      url: `${apiUrl?.general}Debt/Contract/__contractId__/Debt/GetDebtCaseSearch`,
      method: apiMethod?.post,
      methodname: 'Debts',
    },
    getDebtProcessed: {
      url: `${apiUrl?.general}Debt/Contract/__contractId__/Debt`,
      method: apiMethod?.post,
      methodname: 'Debts',
    },
    updateDebtProcess: {
      url: `${apiUrl?.general}Debt/Contract/__contractId__/Debt/UpdateDebtProcess`,
      method: apiMethod?.put,
      methodname: 'Debts',
    },
  },
  Warrant: {
    getWarrants: {
      url: `${apiUrl?.general}Warrant/Contract/__contractId__/GetWarrants`,
      method: apiMethod?.post,
      methodname: 'Warrants',
    },
    getWarrant: {
      url: `${apiUrl?.general}Warrant/__id__/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'Warrants',
    },
    generateNewRequest: {
      url: `${apiUrl?.general}Warrant/Contract/__contractId__/GenerateNewRequest`,
      method: apiMethod?.post,
      methodname: 'Warrant',
    },
    getWarrantCaseSearch: {
      url: `${apiUrl?.general}Warrant/Contract/__contractId__/GetWarrantCaseSearch`,
      method: apiMethod?.post,
      methodname: 'Warrant',
    },
    updateWarrantProcess: {
      url: `${apiUrl?.general}Warrant/Contract/__contractId__/WarrantProcess`,
      method: apiMethod?.put,
      methodname: 'Warrant',
    },
  },
  PrintBatch: {
    PrintBatchList: {
      url: `${apiUrl?.general}PrintBatches/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'Debts',
    },
    PrintBatchDownLoad: {
      url: `${apiUrl?.general}PrintBatch/__printBatchId__/Contract/__contractId__/__contractType__/Download`,
      method: apiMethod?.get,
      methodname: 'PrintBatch',
    },
    getPDFBlobFileLocation: {
      url: `${apiUrl?.general}PrintBatch/__batchId__/LocalPrintLocations`,
      method: apiMethod?.get,
      methodname: 'localPrinting File Location',
    },
    updatePrintBathConfirm: {
      url: `${apiUrl?.general}PrintBatch/Status`,
      method: apiMethod?.put,
      methodname: 'update print batch status',
    },
  },
  foreignDebtRecovery: {
    foreignDebtRecoveryList: {
      url: `${apiUrl?.general}ForeignDebtRecovery/Contract/__contractId__/GetForeignDebts`,
      method: apiMethod?.post,
      methodname: 'foreignDebtRecoveryList',
    },
    foreignDebtRecovery: {
      url: `${apiUrl?.general}ForeignDebtRecovery/Contract/__contractId__/ForeignDebt`,
      method: apiMethod?.post,
      methodname: 'foreignDebtRecovery',
    },
  },
  BailiffBatch: {
    BailiffBatchList: {
      url: `${apiUrl?.general}Bailiff/Contract/__contractId__/Batches`,
      method: apiMethod?.post,
      methodname: 'BailiffBatchList',
    },
    BailiffBatchExportXMLGeneration: {
      url: `${apiUrl?.general}Bailiff/Contract/__contractId__/ExportXMLGeneration`,
      method: apiMethod?.get,
      methodname: 'ExportXMLGeneration',
    },
    BailiffDetails: {
      url: `${apiUrl?.general}Bailiff/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'get Bailiff Details',
    },
  },
  BailiffMessage: {
    BailiffMessageList: {
      url: `${apiUrl?.general}Bailiff/Contract/__contractId__/Messages`,
      method: apiMethod?.post,
      methodname: 'BailiffMessage',
    },
    BailiffMessageAction: {
      url: `${apiUrl?.general}Bailiff/Contract/__contractId__/Batch/__batchId__/MessageAction`,
      method: apiMethod?.get,
      methodname: 'BailiffMessage',
    },
  },
  timezone: {
    url: `${apiUrl?.general}TimeZone/GetTimeZone`,
    method: apiMethod?.post,
    methodname: 'timezone',
  },
  advanceSearch: {
    searchColumnData: {
      url: `${apiUrl?.case}/GetAdvancedSearchColumnData/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'to get the Search field Data',
    },
    searchColumnValueData: {
      url: `${apiUrl?.case}/GetAdvancedSearch/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'To get the column value',
    },
    searchColumnValueDataExport: {
      url: `${apiUrl?.case}/GetAdvancedSearchExport/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'To get the column value',
    },
    storePreference: {
      url: `${apiUrl?.case}/AdvancedSearchColumnPicker`,
      method: apiMethod?.put,
      methodname:
        'To store the column filter preference against the user account',
    },
    getPreference: {
      url: `${apiUrl?.case}/AdvancedSearchColumnPicker`,
      method: apiMethod?.get,
      methodname:
        'To get the stored column filter preference against the user account',
    },
  },
  Location: {
    getLocation: {
      url: `${apiUrl?.general}Locations`,
      method: apiMethod?.post,
      methodname: 'Locations',
    },
    getLocationZone: {
      url: `${apiUrl?.general}Zone/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'Locations',
    },
    deleteLocation: {
      url: `${apiUrl?.general}Location`,
      method: apiMethod?.delete,
      methodname: 'Locations',
    },
    postLocation: {
      url: `${apiUrl?.general}Location`,
      method: apiMethod?.post,
      methodname: 'Location',
    },
    GetDetailLocation: {
      url: `${apiUrl?.general}Location/__locationId__/Contract/__contractId__/GetLocation`,
      method: apiMethod?.get,
      methodname: 'Location',
    },
    upload: {
      url: `${apiUrl?.general}Location/Upload`,
      method: apiMethod?.post,
      methodname: 'PostLocation',
    },
    locationSampleDataDownload: {
      url: `${apiUrl?.general}template/Download`,
      method: apiMethod?.post,
      methodname: 'locationSampleDataDownload',
    },
    getLocationErrorsExport: {
      url: `${apiUrl?.general}Location/GetLocationErrorsExport`,
      method: apiMethod?.post,
      methodname: 'getLocationErrorsExport',
    },
    getLocationErrors: {
      url: `${apiUrl?.general}Location/GetLocationErrors`,
      method: apiMethod?.post,
      methodname: 'getLocationErrors',
    },
    validationStatus: {
      url: `${apiUrl?.general}Location/ValidationStatus`,
      method: apiMethod?.post,
      methodname: 'validationStatus',
    },
  },
  GetReportToken: {
    getReportToken: {
      url: `${apiUrl?.general}PowerBI`,
      method: apiMethod?.get,
      methodname: 'PowerBI',
    },
  },
  preDebtList: {
    enquiryBatchList: {
      url: `${apiUrl?.general}PreDebt/Contract/__contractId__/GetPreDebtRequest`,
      method: apiMethod?.post,
      methodname: 'preDebtEnquiryBatchList',
    },
    responseBatchList: {
      url: `${apiUrl?.general}PreDebt/Contract/__contractId__/GetPreDebtResponse`,
      method: apiMethod?.post,
      methodname: 'preDebtResponseBatchList',
    },
    generateNewRequest: {
      url: `${apiUrl?.general}PreDebt/Contract/__contractId__/GenerateNewRequest`,
      method: apiMethod?.post,
      methodname: 'preDebtResponseBatchList',
    },
  },
  Bailiff: {
    getBailiffs: {
      url: `${apiUrl?.general}GetBailiffs`,
      method: apiMethod?.post,
      methodname: 'GetBailiffs',
    },
    postBailiff: {
      url: `${apiUrl?.general}Bailiff`,
      method: apiMethod?.post,
      methodname: 'Bailiff',
    },
    deleteBailiff: {
      url: `${apiUrl?.general}Bailiff/Contract/__contractId__`,
      method: apiMethod?.delete,
      methodname: 'Bailiff',
    },
    GetById: {
      url: `${apiUrl?.general}Bailiff/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'GetBailiff',
    },
  },
  integration: {
    getContracts: {
      url: `${apiUrl?.general}Integrations`,
      method: apiMethod?.post,
      methodname: 'getContracts',
    },
    updateIntegration: {
      url: `${apiUrl?.general}Integration`,
      method: apiMethod?.put,
      methodname: 'updateIntegration',
    },
    updateIntegration: {
      url: `${apiUrl?.general}Integration`,
      method: apiMethod?.put,
      methodname: 'updateIntegration',
    },
  },

  GetLinkedCase: {
    url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/GetLinkedCasesByVRM`,
    method: apiMethod?.post,
    methodname: 'GetLinkedCase',
  },
  Branding: {
    getBranding: {
      url: `${apiUrl?.general}WhiteLabel/Brandingconfigurations`,
      method: apiMethod?.post,
      methodname: 'BrandingList',
    },
    deleteBranding: {
      url: `${apiUrl?.general}WhiteLabel`,
      method: apiMethod?.delete,
      methodname: 'Delete Branding',
    },
    getByIdBranding: {
      url: `${apiUrl?.general}WhiteLabel`,
      method: apiMethod?.get,
      methodname: 'Get By ID Branding',
    },
    getByIdBrandingConfiguration: {
      url: `${apiUrl?.general}WhiteLabel/ConfigurationSetting`,
      method: apiMethod?.post,
      methodname: 'Branding configuration post method',
    },
    getByIdBrandingAttachment: {
      url: `${apiUrl?.general}WhiteLabel/Attachments`,
      method: apiMethod?.post,
      methodname: 'Branding Attachments post method',
    },
    getByIdBrandingHeaderFooter: {
      url: `${apiUrl?.general}WhiteLabel/HeaderFooter`,
      method: apiMethod?.post,
      methodname: 'Branding HeaderFooter post method',
    },
    getByIdBrandingPublish: {
      url: `${apiUrl?.general}WhiteLabel/Publish`,
      method: apiMethod?.post,
      methodname: 'Branding Publish post method',
    },
  },
  BulkUploadCase: {
    getBulkUpdateCaseSearch: {
      url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/BulkUpdateCaseSearch`,
      method: apiMethod?.get,
      methodname: 'BulkUpdateCaseSearch',
    },
    caseSampleDataDownload: {
      url: `${apiUrl?.general}template/Download`,
      method: apiMethod?.post,
      methodname: 'caseSampleDataDownload',
    },
    caseCSVFileUpload: {
      url: `${apiUrl?.case}/Contract/__contractId__/Upload`,
      method: apiMethod?.post,
      methodname: 'CSVFileUpload',
    },
    caseCSVFileUploadFileError: {
      url: `${apiUrl?.case}/CaseErrors`,
      method: apiMethod?.post,
      methodname: 'ErrorFileData',
    },
    caseCSVFileUploadSkipErrorFileExport: {
      url: `${apiUrl?.case}/CaseErrorsExport`,
      method: apiMethod?.post,
      methodname: 'skip Error File Data',
    },
    caseCSVFileUploadFinalData: {
      url: `${apiUrl?.case}/ValidationStatus`,
      method: apiMethod?.post,
      methodname: 'File Data upload',
    },
    getStage: {
      url: `${apiUrl?.case}/Contract/__contractId__/CaseStage`,
      method: apiMethod?.get,
      methodname: 'CaseStage',
    },
    getStatus: {
      url: `${apiUrl?.case}/Contract/__contractId__/CaseStatus/Stage/__stageId__`,
      method: apiMethod?.get,
      methodname: 'CaseStatus',
    },
    getCancelReason: {
      url: `${apiUrl?.general}CancelReason/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'getCancelReason',
    },
    BulkBatchCaseValidate: {
      url: `${apiUrl?.case}/Contract/__contractId__/BulkBatchCaseValidate`,
      method: apiMethod?.post,
      methodname: 'BulkBatchCaseValidate',
    },
    BulkBatchCaseUpdate: {
      url: `${apiUrl?.case}/Contract/__contractId__/BulkBatchCaseUpdate`,
      method: apiMethod?.put,
      methodname: 'BulkBatchCaseUpdate',
    },
    bulkCaseNotesAndAttachments: {
      url: `${apiUrl?.case}/Contract/__contractId__/BulkCaseNotesAndAttachments`,
      method: apiMethod?.post,
      methodname: 'BulkCaseNotesAndAttachments',
    },
    cancelBatch: {
      url: `${apiUrl?.case}/Contract/__contractId__/Batch/__batchId__`,
      method: apiMethod?.delete,
      methodname: 'cancelBatch',
    },
    batchCaseNumbers: {
      url: `${apiUrl?.case}/Contract/__contractId__/BatchCaseNumbers/__batchId__`,
      method: apiMethod?.get,
      methodname: 'batchCaseNumbers',
    },
    getCaseReportList: {
      url: `${apiUrl?.general}CaseReport/Contract/__contractId__/batch/__batchId__`,
      method: apiMethod?.post,
      methodname: 'getCaseReportList',
    },
    caseReportCommit: {
      url: `${apiUrl?.general}CaseReport/Contract/__contractId__/batch/__batchId__`,
      method: apiMethod?.put,
      methodname: 'caseReportCommit',
    },
    caseReportDownload: {
      url: `${apiUrl?.general}CaseReport/Contract/__contractId__/batch/__batchId__/Download`,
      method: apiMethod?.post,
      methodname: 'caseReportDownload',
    },
    getCaseSettingData: {
      url: `${apiUrl?.general}CaseReport/Contract/__contractId__/batch/__batchId__/Page`,
      method: apiMethod?.get,
      methodname: 'getCaseSettingData',
    },
    getBatches: {
      url: `${apiUrl?.case}/Contract/__contractId__/Batches`,
      method: apiMethod?.post,
      methodname: 'getBatches',
    },
    listApproval: {
      url: `${apiUrl?.case}/Contract/__contractId__/Batch/__batchId__/BulkApproval`,
      method: apiMethod?.put,
      methodname: 'listApproval',
    },
  },
  Purge: {
    getPurgeList: {
      url: `${apiUrl?.general}Purge/PurgeList`,
      method: apiMethod?.post,
      methodname: 'GetPurgeList',
    },
    postPurge: {
      url: `${apiUrl?.general}Purge`,
      method: apiMethod?.post,
      methodname: 'PostPurge',
    },
    deletePurge: {
      url: `${apiUrl?.general}Purge`,
      method: apiMethod?.delete,
      methodname: 'DeletePurge',
    },
    getPurgeById: {
      url: `${apiUrl?.general}Purge`,
      method: apiMethod?.get,
      methodname: 'GetPurgeById',
    },
    putPurge: {
      url: `${apiUrl?.general}Purge`,
      method: apiMethod?.put,
      methodname: 'PutPurge',
    },
  },
  OCR: {
    postScan: {
      url: `${apiUrl?.general}OCR/Scan`,
      method: apiMethod?.post,
      methodname: 'postScan',
    },
  },

  officerTracking: {
    getCEOLocationNameAPI: {
      url: 'https://atlas.microsoft.com/search/address/reverse/crossstreet/json?&api-version=1.0&subscription-key=__AzureMapSubscriptionKey__&language=en-US&query=__LatandLongCoordinate__',
      method: apiMethod?.get,
      methodname: 'get the GEO location based on coordinate',
    },
    getListOfCeo: {
      url: `${apiUrl?.general}CEO/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'getListOfCeo',
    },
    getLocationHistory: {
      url: `${apiUrl?.general}CEO/location-history?contractId=__contractId__&startDate=__startDate__&endDate=__endDate__&userIds=__userIds__`,
      method: apiMethod?.get,
      methodname: 'getLocationHistory',
    },
    geoFenceLogs: {
      url: `${apiUrl?.general}GeoFenceLogs`,
      method: apiMethod?.post,
      methodname: 'GeoFenceLogs',
    },
  },

  debtReview: {
    getDebtReviewList: {
      url: `${apiUrl?.general}Debt/Contract/__contractId__/GetDebtReview`,
      method: apiMethod?.post,
      methodname: 'getDebtReviewList',
    },
    cancelCases: {
      url: `${apiUrl?.general}Debt/Contract/__contractId__/DebtReviewCasesCancel`,
      method: apiMethod?.put,
      methodname: 'cancelCases',
    },
  },
  pcnOnHoldAndOffHold: {
    pcnremoveOffHold: {
      url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/__contractType__/OffHold`,
      method: apiMethod?.patch,
      methodname: 'remove off hold',
    },
    onHold: {
      url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/OnHold`,
      method: apiMethod?.patch,
      methodname: 'remove off hold',
    },
    onHoldReasons: {
      url: `${apiUrl?.general}HoldReason/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'onHoldReasons',
    },
  },
  attachmentReallocation: {
    getAttachmentsList: {
      url: `${apiUrl?.case}/__caseId__/Contract/__contractId__/reallocationAttachments`,
      method: apiMethod?.post,
      methodname: 'get attachments list',
    },
    getCaseData: {
      url: `${apiUrl?.case}/Contract/__contractId__/searchForReallocation`,
      method: apiMethod?.post,
      methodname: 'CaseSearch',
    },
    attachmentReallocation: {
      url: `${apiUrl?.case}/Contract/__contractId__/attachmentReallocate`,
      method: apiMethod?.put,
      methodname: 'attachement reallocation',
    },
  },
  PCNNumberReservation: {
    getCEOIds: {
      url: `${apiUrl?.general}CEO/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'getCEOIds',
    },
    getPCNNumberReservationList: {
      url: `${apiUrl?.general}ManualCase/Contract/__contractId__/GetReservedPCNNumbers`,
      method: apiMethod?.post,
      methodname: 'GetPCNNumberReservationList',
    },
    getCreatePCNReservationValidation: {
      url: `${apiUrl?.general}ManualCase/Contract/__contractId__/CEO/__cEOId__/CreatePCNReservationValidation`,
      method: apiMethod?.get,
      methodname: 'getCreatePCNReservationValidation',
    },
    postPCNReservation: {
      url: `${apiUrl?.general}ManualCase/Contract/__contractId__/CreatePCNReservation`,
      method: apiMethod?.post,
      methodname: 'postPCNReservation',
    },
    getDownloadPCNsReservation: {
      url: `${apiUrl?.general}ManualCase/Contract/__contractId__/PCN/__pcnId__/DownloadPCNsReservation`,
      method: apiMethod?.get,
      methodname: 'getDownloadPCNsReservation',
    },
    postSendMailPCNsReservation: {
      url: `${apiUrl?.general}ManualCase/Contract/__contractId__/PCN/__pcnId__/SendMailPCNsReservation`,
      method: apiMethod?.post,
      methodname: 'postSendMailPCNsReservation',
    },
  },
  manualCase: {
    vehicleMake: {
      url: `${apiUrl?.general}Vehicle/Make`,
      method: apiMethod?.get,
      methodname: 'vehicleMake',
    },
    vehicleColor: {
      url: `${apiUrl?.general}Vehicle/Color`,
      method: apiMethod?.get,
      methodname: 'vehicleColor',
    },
    getReason: {
      url: `${apiUrl?.general}ManualCase/Reason`,
      method: apiMethod?.get,
      methodname: 'caseReason',
    },
    getCeoId: {
      url: `${apiUrl?.general}CEO/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'ceoID',
    },
    caseNumber: {
      url: `${apiUrl?.general}Case/ReservedCaseNumbers/Contract/__contractId__/User/__userId__`,
      method: apiMethod?.get,
      methodname: 'caseNumber',
    },
    validateSession: {
      url: `${apiUrl?.general}DVLA/ParkingSession`,
      method: apiMethod?.post,
      methodname: 'validateSession',
    },
    contravention: {
      url: `${apiUrl?.general}ContraventionCode/Contract/__contractId__/Location/__locationId__`,
      method: apiMethod?.get,
      methodname: 'contravention',
    },
    wheelPosition: {
      url: `${apiUrl?.general}WheelPosition`,
      method: apiMethod?.get,
      methodname: 'wheelPosition',
    },
    serviceMethod: {
      url: `${apiUrl?.general}ServiceMethod/Contract/__contractType__`,
      method: apiMethod?.get,
      methodname: 'serviceMethod',
    },
    createPcn: {
      url: `${apiUrl?.general}ManualCase/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'createPcn',
    },
    reportFault: {
      url: `${apiUrl?.general}ManualReportFault/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'reportFault',
    },
    faultType: {
      url: `${apiUrl?.general}FaultType/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'faultType',
    },
  },
  contractConfigData: {
    getContractConfigData: {
      url: `${apiUrl?.general}Contract/__contractId__/Settings`,
      method: apiMethod?.get,
      methodname: 'getContractData',
    },
  },
  haloIntegration: {
    getAuthToken: {
      url: `/auth/token`,
      method: apiMethod?.post,
      methodname: 'getAuthToken from HALO server',
    },
    getCaseCategory: {
      url: `${apiUrl?.general}TicketType?client_id=__haloClientID__&showcounts=true`,
      method: apiMethod?.get,
      methodname: 'get Ticket category from HALO server',
    },
    ticketCreate: {
      url: `${apiUrl?.general}tickets`,
      method: apiMethod?.post,
      methodname: 'Create ticket in HALO app',
    },
    addAttachmentForTicket: {
      url: `${apiUrl?.general}attachment`,
      method: apiMethod?.post,
      methodname: 'Add Attachment for the Ticket',
    },
    eventLogged: {
      url: `${apiUrl?.general}halo/event`,
      method: apiMethod?.post,
      methodname: 'Add the usd event in HALO app',
    },
    validateUserDetails: {
      url: `${apiUrl?.general}Users?search=__search__`,
      method: apiMethod?.get,
      methodname: 'Validate the user exist or not',
    },
  },
  tasks: {
    getTaskList: {
      url: `${apiUrl?.general}Task/CEOTasks/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'getTaskList',
    },
    postTask: {
      url: `${apiUrl?.general}Task/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'postTask',
    },
    getTaskStatus: {
      url: `${apiUrl?.general}Task/TaskStatus/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'getTaskStatus',
    },
    getCategory: {
      url: `${apiUrl?.general}Task/Category/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'getCategory',
    },
    getCEOs: {
      url: `${apiUrl?.general}CEO/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'getCEOs',
    },
    reAssign: {
      url: `${apiUrl?.general}Task/ReAssign/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'reAssign',
    },
    cancelTask: {
      url: `${apiUrl?.general}Task/CancelTask/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'cancelTask',
    },
    getTask: {
      url: `${apiUrl?.general}Task/Contract/__contractId__/CEOTask/__taskId__`,
      method: apiMethod?.get,
      methodname: 'getTask',
    },
    postNotesAndAttachments: {
      url: `${apiUrl?.general}Task/NotesAndAttachments/Contract/__contractId__`,
      method: apiMethod?.post,
      methodname: 'postNotesAndAttachments',
    },
    getHistory: {
      url: `${apiUrl?.general}Task/Contract/__contractId__/TaskHistory/__taskId__`,
      method: apiMethod?.get,
      methodname: 'getHistory',
    },
  },
  sourceType: {
    getSourceType: {
      url: `${apiUrl?.case}/SourceType`,
      method: apiMethod?.get,
      methodname: 'sourceType',
    },
  },
  ceoOfficerTrack: {
    getActiveCEOOfficer: {
      url: `${apiUrl?.general}CEOOfficerTrack/ActiveOfficers/Contract/__contractId__`,
      method: apiMethod?.get,
      methodname: 'getActiveCEOOfficer',
    },
  },
  groundReason: {
    getGroundReasons: {
      url: `${apiUrl?.general}GroundReasons`,
      method: apiMethod?.post,
      methodname: 'getGroundReasons',
    },
    postGroundReason: {
      url: `${apiUrl?.general}GroundReason`,
      method: apiMethod?.post,
      methodname: 'postGroundReason',
    },
    deleteGroundReason: {
      url: `${apiUrl?.general}GroundReason`,
      method: apiMethod?.delete,
      methodname: 'deleteGroundReason',
    },
  },
  cAndRConfiguration: {
    cancellationList: {
      url: `${apiUrl?.general}ClampAndRemoval/CancelReason/Pagination`,
      method: apiMethod?.post,
      methodname: 'cancellationReasonList',
    },
    csvExport: {
      url: `${apiUrl?.general}ClampAndRemoval/CancelReason/Export`,
      method: apiMethod?.get,
      methodname: 'cancellationReasonCSVExport',
    },
    postCRCancellationReason: {
      url: `${apiUrl?.general}ClampAndRemoval/cancelReason`,
      method: apiMethod?.post,
      methodname: 'postCRCancellationReason',
    },
    cancellationReasonDelete: {
      url: `${apiUrl?.general}ClampAndRemoval/cancelReason/__caseId__`,
      method: apiMethod?.delete,
      methodname: 'postCRCancellationDelete',
    },
    cancellationReasonEditGet: {
      url: `${apiUrl?.general}ClampAndRemoval/cancelReason/__caseId__`,
      method: apiMethod?.get,
      methodname: 'postCRCancellationGet',
    },
    cancellationReasonEdit: {
      url: `${apiUrl?.general}ClampAndRemoval/cancelReason/`,
      method: apiMethod?.put,
      methodname: 'postCRCancellationEdit',
    },
    cancellationReasonSampleDownload: {
      url: `${apiUrl?.general}ClampAndRemoval/CancelReason/Download`,
      method: apiMethod?.post,
      methodname: 'cancellationReasonSampleDownload',
    },
    cancellationReasonUpload: {
      url: `${apiUrl?.general}ClampAndRemoval/CancelReason/Upload`,
      method: apiMethod?.post,
      methodname: 'cancellationReasonSampleDownload',
    },
    getCancellationReasonErrors: {
      url: `${apiUrl?.general}ClampAndRemoval/CancelReason/GetCancelReasonErrors`,
      method: apiMethod?.post,
      methodname: 'getCancellationReasonErrors',
    },
    cancellationReasonValidationStatus: {
      url: `${apiUrl?.general}ClampAndRemoval/CancelReason/ValidationStatus`,
      method: apiMethod?.post,
      methodname: 'cancellationReasonValidationStatus',
    },
    cancellationReasonErrorExport: {
      url: `${apiUrl?.general}ClampAndRemoval/CancelReason/GetCancelReasonErrorsExport`,
      method: apiMethod?.post,
      methodname: 'cancellationReasonErrorExport',
    },
  },

  geoFenceSetUp: {
    getGeoFenceSetUpList: {
      url: `${apiUrl?.general}GeoFences`,
      method: apiMethod?.get,
      methodname: 'getGeoFenceSetUpList',
    },
    saveGeoFence: {
      url: `${apiUrl?.general}/GeoFence`,
      method: apiMethod?.post,
      methodname: 'saveGeoFence',
    },
    updateGeoFence: {
      url: `${apiUrl?.general}/GeoFence`,
      method: apiMethod?.patch,
      methodname: 'updateGeoFence',
    },
    deleteGeoFence: {
      url: `${apiUrl?.general}/GeoFence`,
      method: apiMethod?.delete,
      methodname: 'deleteGeoFence',
    },
    getGeoFenceSetUp: {
      url: `${apiUrl?.general}GeoFence`,
      method: apiMethod?.get,
      methodname: 'getGeoFenceSetUp',
    },
  },

  poundLocation: {
    postCRpoundLocation: {
      url: `${apiUrl?.general}ClampAndRemoval/poundLocation`,
      method: apiMethod?.post,
      methodname: 'postCRpoundLocation',
    },
    poundLocationList: {
      url: `${apiUrl?.general}ClampAndRemoval/PoundLocation/Pagination`,
      method: apiMethod?.post,
      methodname: 'poundLocationList',
    },
    poundLocationDelete: {
      url: `${apiUrl?.general}ClampAndRemoval/PoundLocation/__poundLocationID__`,
      method: apiMethod?.delete,
      methodname: 'postCRCancellationDelete',
    },
    poundLocationEditGet: {
      url: `${apiUrl?.general}ClampAndRemoval/PoundLocation/__poundLocationID__`,
      method: apiMethod?.get,
      methodname: 'postCRCancellationGet',
    },
  },
  prioritySettings: {
    prioritySettingsList: {
      url: `${apiUrl?.general}ClampAndRemoval/PrioritySetting/Rules`,
      method: apiMethod?.post,
      methodname: 'postCRCancellationGet',
    },
  },
  prioritySettingData: {
    editOrganisationData: {
      url: `${apiUrl?.general}organisation`,
      method: apiMethod?.get,
      methodname: 'get Organisation by ID',
    },
    contractsByOrganisation: {
      url: `${apiUrl?.general}BackOffice/ContractsByOrganisation/__OrganisationId__`,
      method: apiMethod?.get,
      methodname: 'Get Contracts By Organisation',
    },
    getContraventionCodes: {
      url: `${apiUrl?.general}BackOffice/Contract/__ContractId__/ContraventionCodes`,
      method: apiMethod?.get,
      methodname: 'Get Contravention Codes by Contracts',
    },
    getContraventionLocation: {
      url: `${apiUrl?.general}BackOffice/Contract/__ContractId__/Locations`,
      method: apiMethod?.get,
      methodname: 'Get Contravention Location by Contracts',
    },
    addPrioritySettingRule: {
      url: `${apiUrl?.general}ClampAndRemoval/PrioritySetting/Contract/__ContractId__/Rule`,
      method: apiMethod?.post,
      methodname: 'Add Priority Setting rules',
    },
  },
}

export default APIEndPoints
