/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this file, we configured URL and componenet for that URL
  #lazy           : Lazy loading is a technique that enables us to load a specific component when a particular route is accessed. 
                    It exponentially enhances the load time and the loading speed. At the same time, it increases the react application performance.
  #React.Suspense : React Suspense here to offer a fallback React element when the component is lazy loaded from the server.
*/
// System defined variables
import React, { lazy, useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import _ from 'lodash'

// Custom defined variables.
import { fetchDataSelector } from '../components/recoil/selectors/selectors'
import { selectedContractDetails } from '../components/recoil/atoms/atoms'
import APIEndPoints from '../../models/api/apiEndpoints'
import utils from '../../utils/utils'

const RouteMiddleware = lazy(() => import('./RouteMiddleware'))
const Home = lazy(() => import('../pages/home/Home'))
const PncPage = lazy(() => import('../pages/pcnPage/PcnPage'))
const ManageUser = lazy(() => import('../pages/manageUsers/ManageUser'))
const AddUser = lazy(() => import('../pages/manageUsers/addUser/AddUserPage'))
const BailiffList = lazy(() => import('../pages/bailiff/BailiffList'))
//const PurgeList = lazy(() => import('../pages/purge/PurgeList'))
const PrefixListPage = lazy(() =>
  import('../pages/prefixListPage/PrefixListPage')
)
const PrefixSetupPage = lazy(() =>
  import('../pages/prefixSetupPage/PrefixSetupPage')
)
const ContractListPage = lazy(() =>
  import('../pages/contract/ContractListPage')
)
const ContactAddress = lazy(() => import('../pages/contactTab/ContactAddress'))
const OrganisationList = lazy(() =>
  import('../pages/organisation/OrganisationList')
)
const OrganisationSetUp = lazy(() =>
  import('../pages/organisation/OrganisationSetUp')
)
const ContraventionList = lazy(() =>
  import('../pages/contravention/ContraventionList')
)
const ContraventionSetupPage = lazy(() =>
  import('../pages/contravention/addpage/ContraventionSetupPage')
)
const CaseOverview = lazy(() => import('../pages/caseOverview/CaseOverview'))
const PageNotFound = lazy(() => import('../pages/pageNotFound/PageNotFound'))
const ContractSetUp = lazy(() => import('../pages/contract/ContractSetUp'))
const FaqList = lazy(() => import('../pages/faq/FaqList'))
const FaqSetup = lazy(() => import('../pages/faq/FaqSetup'))
const TemplatePage = lazy(() => import('../pages/templates/TemplatePage'))
const TemplateSetup = lazy(() =>
  import('../pages/templates/templateTab/TemplateSetup')
)
const SendCorrespondence = lazy(() =>
  import('../pages/pcnPage/SendCorrespondence')
)
const ApprovalQueueList = lazy(() =>
  import('../pages/approvalQueue/ApprovalQueueList')
)
const WorkflowManager = lazy(() =>
  import('../pages/workflowManager/WorkflowManager')
)
const ZoneList = lazy(() => import('../pages/zone/ZoneList'))
const MyWorkFlow = lazy(() => import('../pages/myWorkFlow/MyWorkFlow'))
const OnStreet = lazy(() => import('../pages/messaging/onStreet/OnStreet'))
const BackOffice = lazy(() =>
  import('../pages/messaging/backOffice/BackOffice')
)
const ActivityDays = lazy(() => import('../pages/contract/ActivityDays'))
const DVLAListPage = lazy(() => import('../pages/dvlaList/DVLAList'))
const WarrantList = lazy(() => import('../pages/warrant/WarrantList'))
const PrintBatchList = lazy(() => import('../pages/printBatch/PrintBatchList'))
const DebtList = lazy(() => import('../pages/debt/DebtList'))
const ForeignDebt = lazy(() => import('../pages/foreignDebt/ForeignDebt'))
const ScanImport = lazy(() => import('../pages/scanImport/ScanImport'))
const RepresentationReview = lazy(() =>
  import('../pages/representationReview/RepresentationReview')
)
const SearchPage = lazy(() => import('../pages/searchPage/SearchPage'))
const BailiffBatchList = lazy(() =>
  import('../pages/bailiffBatch/BailiffBatchList')
)
const BailiffMessageList = lazy(() =>
  import('../pages/bailiffMessage/BailiffMessageList')
)
const OperationalInternal = lazy(() =>
  import('../pages/reports/operationalInternal/OperationalInternal')
)
const FinancialReports = lazy(() =>
  import('../pages/reports/financialReports/FinancialReports')
)
const OperationalExternal = lazy(() =>
  import('../pages/reports/operationalExternal/OperationalExternal')
)
const ObligatoryReport = lazy(() =>
  import('../pages/reports/obligatoryReport/ObligatoryReport')
)
const CancellationReason = lazy(() =>
  import('../pages/cancellationReason/CancellationReason')
)
const CaseStatus = lazy(() => import('../pages/reports/caseStatus/CaseStatus'))
const OutboundCorrespondence = lazy(() =>
  import('../pages/reports/outboundCorrespondence/OutboundCorrespondence')
)
const InboundCorrespondence = lazy(() =>
  import('../pages/reports/inboundCorrespondence/InboundCorrespondence')
)
const EnforcementTeam = lazy(() =>
  import('../pages/reports/enforcementTeam/EnforcementTeam')
)
const Financial = lazy(() => import('../pages/reports/financial/Financial'))
const AuditandTracking = lazy(() =>
  import('../pages/reports/auditAndTracking/AuditAndTracking')
)
const Monitoring = lazy(() => import('../pages/reports/monitoring/Monitoring'))
const PerformanceMonitoring = lazy(() =>
  import('../pages/reports/performanceMonitoring/PerformanceMonitoring')
)

const GroundsList = lazy(() => import('../pages/groundReasons/GroundsList'))
const PreDebt = lazy(() => import('../pages/preDebt/PreDebtList'))
const LocationList = lazy(() => import('../pages/location/LocationList'))
const BulkUpdate = lazy(() => import('../pages/bulkUpdate/BulkUpdate'))
const BulkUpdateList = lazy(() => import('../pages/bulkUpdate/BulkUpdateList'))

const Integration = lazy(() => import('../pages/integration/Integration'))
const IntegrationSetup = lazy(() =>
  import('../pages/integration/IntegrationSetup')
)
const BrandingListPage = lazy(() =>
  import('../pages/branding/BrandingListPage')
)
const BrandingSetup = lazy(() => import('../pages/branding/BrandingSetup'))

const OfficerTracking = lazy(() =>
  import('../pages/officerTracking/OfficerTracking')
)
const HoldReasonsList = lazy(() =>
  import('../pages/holdReasons/HoldReasonsList')
)
const DebtReview = lazy(() => import('../pages/debtReview/DebtReview'))
const ManualCaseCreation = lazy(() =>
  import('../pages/manualCaseCreation/ManualCaseCreation')
)
const PCNNumberReservation = lazy(() =>
  import('../pages/pcnNumberReservation/PCNNumberReservationList')
)

const Tasks = lazy(() => import('../pages/tasks/TasksList'))
const PreViewTasks = lazy(() =>
  import('../pages/tasks/PreviewTask/PreviewTask')
)

const GeoFencingSetUpList = lazy(() =>
  import('../pages/geoFencingSetUp/GeoFencingSetUpList')
)
const GeoFencingSetUp = lazy(() =>
  import('../pages/geoFencingSetUp/GeoFencingSetUp')
)
// Function to check permission (Role/ContractDependent) and render pageNotFound/children
const MonitoringReports = lazy(() =>
  import('../pages/reports/monitoringReports/MonitoringReports')
)
const CancellationReasonList = lazy(() =>
  import('../pages/crConfiguration/cancellationReason/CancellationReasonList')
)
const PoundLocationList = lazy(() =>
  import('../pages/crConfiguration/poundLocation/PoundLocationList')
)
const GeoFenceLog = lazy(() => import('../pages/geoFenceLog/GeoFenceLog'))
const PrioritySettingList = lazy(() =>
  import('../pages/crConfiguration/prioritySetting/PrioritySettingsList')
)
const PrioritySetting = lazy(() =>
  import('../pages/crPrioritySettings/PrioritySetting')
)

// Function to check permission (Role/ContractDependent) and render pageNotFound/children

// when the page comes under contract based screens, then parameter contractDependent should be true
const renderProtectedComponent = (
  component,
  contractDependent,
  stateDataDependent
) => {
  return (
    <RouteMiddleware
      contractRequired={contractDependent}
      stateDataRequired={stateDataDependent}
    >
      {component}
    </RouteMiddleware>
  )
}

function RoutesFile() {
  const getCoreData = useRecoilValue(fetchDataSelector)
  const selectedContractData = useRecoilValue(selectedContractDetails)
  const [menuEnableCheck, setMenuEnableCheck] = useState(false)

  /* istanbul ignore next */
  const checkMenuEnableOrDisable = async () => {
    if (
      selectedContractData?.contractId !== null &&
      selectedContractData?.contractId !== '' &&
      !_.isUndefined(selectedContractData?.contractId)
    ) {
      const caseOverView = await getCoreData(
        APIEndPoints?.Contract?.caseOverView?.method,
        APIEndPoints?.Contract?.caseOverView?.url?.replace(
          '__contractId__',
          selectedContractData?.contractId
        )
      )

      if (
        caseOverView &&
        caseOverView?.status === utils?.apiResponseCode?.successCode
      ) {
        setMenuEnableCheck(caseOverView?.data?.isPreDebtCleanse)
      }
    }
  }

  useEffect(() => {
    checkMenuEnableOrDisable()
  }, [selectedContractData])

  return (
    <React.Suspense fallback={<p className="loadingblock"> </p>}>
      {/* Due to GuardProvider is not supported for current React-Router-DOM creating custom routeMiddleware */}
      <Routes>
        <Route path="/" element={renderProtectedComponent(<Home />)} />
        <Route
          path="/pcnpage"
          element={renderProtectedComponent(<PncPage />)}
        />
        <Route
          path="/search"
          element={renderProtectedComponent(<SearchPage />, true)}
        />
        <Route
          path="/manageuser"
          element={renderProtectedComponent(<ManageUser />)}
        />
        <Route
          path="/adduser"
          element={renderProtectedComponent(<AddUser />)}
        />
        <Route
          path="/caseoverview"
          element={renderProtectedComponent(<CaseOverview />, true)}
        />
        <Route
          path="/prefix"
          element={renderProtectedComponent(<PrefixListPage />)}
        />
        <Route
          path="/prefixsetup"
          element={renderProtectedComponent(<PrefixSetupPage />)}
        />
        <Route
          path="/bailiff"
          element={renderProtectedComponent(<BailiffList />)}
        />
        {/* <Route
          path="/purge"
          element={renderProtectedComponent(<PurgeList />)}
        /> */}
        <Route
          path="/contractListPage"
          element={renderProtectedComponent(<ContractListPage />)}
        />
        <Route
          path="/brandinglistpage"
          element={renderProtectedComponent(<BrandingListPage />)}
        />
        <Route
          path="/contractSetUp"
          element={renderProtectedComponent(<ContractSetUp />)}
        />
        <Route
          path="/contactaddress"
          element={renderProtectedComponent(<ContactAddress />)}
        />
        <Route
          path="/organisationlist"
          element={renderProtectedComponent(<OrganisationList />)}
        />

        <Route
          path="/organisationsetup"
          element={renderProtectedComponent(<OrganisationSetUp />)}
        />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path="/contraventions"
          element={renderProtectedComponent(<ContraventionList />)}
        />
        <Route
          path="/addcontravention"
          element={renderProtectedComponent(<ContraventionSetupPage />)}
        />
        <Route
          path="/templates"
          element={renderProtectedComponent(<TemplatePage />)}
        />
        <Route
          path="/templateSetup"
          element={renderProtectedComponent(<TemplateSetup />)}
        />
        <Route
          path="/faqList"
          element={renderProtectedComponent(<FaqList />)}
        />
        <Route
          path="/faqSetup"
          element={renderProtectedComponent(<FaqSetup />)}
        />
        {/* The pcn values for sendCorrespondence passed through redirect state data. Hence required stateData protection */}
        <Route
          path="/sendCorrespondence"
          element={renderProtectedComponent(
            <SendCorrespondence />,
            false,
            true
          )}
        />
        {/* <Route
          path="/approvalQueueList"
          element={renderProtectedComponent(<ApprovalQueueList />, true)}
        /> */}
        <Route
          path="/approvalQueueList"
          element={renderProtectedComponent(<ApprovalQueueList />, true)}
        />
        <Route
          path="/workflowmanager"
          element={renderProtectedComponent(<WorkflowManager />, true)}
        />
        <Route
          path="/myworkflow"
          element={renderProtectedComponent(<MyWorkFlow />, true)}
        />
        <Route
          path="/onstreet"
          element={renderProtectedComponent(<OnStreet />, true)}
        />
        <Route
          path="/backoffice"
          element={renderProtectedComponent(<BackOffice />, true)}
        />
        <Route
          path="/activedays"
          element={renderProtectedComponent(<ActivityDays />)}
        />
        <Route path="/zone" element={renderProtectedComponent(<ZoneList />)} />
        <Route
          path="/dvla"
          element={renderProtectedComponent(<DVLAListPage />, true)}
        />
        <Route
          path="/warrant"
          element={renderProtectedComponent(<WarrantList />, true)}
        />
        <Route
          path="/printbatchlist"
          element={renderProtectedComponent(<PrintBatchList />, true)}
        />
        <Route
          path="/debt"
          element={renderProtectedComponent(<DebtList />, true)}
        />
        <Route
          path="/foreigndebt"
          element={renderProtectedComponent(<ForeignDebt />, true)}
        />
        <Route
          path="/scanimport"
          element={renderProtectedComponent(<ScanImport />, true)}
        />
        <Route
          path="/repReview"
          element={renderProtectedComponent(<RepresentationReview />, true)}
        />
        <Route
          path="/bailiffbatch"
          element={renderProtectedComponent(<BailiffBatchList />, true)}
        />
        <Route
          path="/bailiffmessage"
          element={renderProtectedComponent(<BailiffMessageList />, true)}
        />
        <Route
          path="/location"
          element={renderProtectedComponent(<LocationList />)}
        />
        <Route
          path="/operationalInternal"
          element={renderProtectedComponent(<OperationalInternal />, true)}
        />
        <Route
          path="/financialReports"
          element={renderProtectedComponent(<FinancialReports />, true)}
        />
        <Route
          path="/operationalexternal"
          element={renderProtectedComponent(<OperationalExternal />, true)}
        />
        <Route
          path="/obligatoryreport"
          element={renderProtectedComponent(<ObligatoryReport />, true)}
        />
        <Route
          path="/casestatus"
          element={renderProtectedComponent(<CaseStatus />, true)}
        />
        <Route
          path="/outboundcorrespondence"
          element={renderProtectedComponent(<OutboundCorrespondence />, true)}
        />
        <Route
          path="/inboundcorrespondence"
          element={renderProtectedComponent(<InboundCorrespondence />, true)}
        />
        <Route
          path="/enforcementteam"
          element={renderProtectedComponent(<EnforcementTeam />, true)}
        />
        <Route
          path="/financial"
          element={renderProtectedComponent(<Financial />, true)}
        />
        <Route
          path="/auditandtracking"
          element={renderProtectedComponent(<AuditandTracking />, true)}
        />
        <Route
          path="/monitoring"
          element={renderProtectedComponent(<Monitoring />, true)}
        />
        <Route
          path="/performancemonitoring"
          element={renderProtectedComponent(<PerformanceMonitoring />, true)}
        />

        <Route
          path="/cancellationreason"
          element={renderProtectedComponent(<CancellationReason />, false)}
        />
        <Route
          path="/groundslist"
          element={renderProtectedComponent(<GroundsList />, false)}
        />
        {menuEnableCheck && (
          <Route
            path="/predebt"
            element={renderProtectedComponent(<PreDebt />, false)}
          />
        )}
        <Route
          path="/bulkupdate"
          element={renderProtectedComponent(<BulkUpdate />, true)}
        />
        <Route
          path="/integration"
          element={renderProtectedComponent(<Integration />, false)}
        />
        <Route
          path="/integrationsetup"
          element={renderProtectedComponent(<IntegrationSetup />, false)}
        />
        <Route
          path="/brandingsetup"
          element={renderProtectedComponent(<BrandingSetup />)}
        />
        <Route
          path="/bulkupdatelist"
          element={renderProtectedComponent(<BulkUpdateList />, true)}
        />
        {selectedContractData?.isOfficerTracking && (
          <Route
            path="/officertracking"
            element={renderProtectedComponent(<OfficerTracking />, true)}
          />
        )}
        <Route
          path="/holdreasonslist"
          element={renderProtectedComponent(<HoldReasonsList />, false)}
        />
        <Route
          path="/debtreview"
          element={renderProtectedComponent(<DebtReview />, true)}
        />
        <Route
          path="/manualcasecreation"
          element={renderProtectedComponent(<ManualCaseCreation />, true)}
        />
        <Route
          path="/pcnnumberreservation"
          element={renderProtectedComponent(<PCNNumberReservation />, false)}
        />
        <Route
          path="/monitoringreports"
          element={renderProtectedComponent(<MonitoringReports />, true)}
        />
        {/* <Route
          path="/tasks"
          element={renderProtectedComponent(<Tasks />, true)}
        /> */}
        {/* <Route
          path="/previewtasks"
          element={renderProtectedComponent(<PreViewTasks />, true)}
        /> */}
        <Route
          path="/cancellationreasonlist"
          element={renderProtectedComponent(<CancellationReasonList />, false)}
        />
        <Route
          path="/geofencingsetuplist"
          element={renderProtectedComponent(<GeoFencingSetUpList />)}
        />
        <Route
          path="/geofencingsetup"
          element={renderProtectedComponent(<GeoFencingSetUp />)}
        />
        <Route
          path="/poundlocation"
          element={renderProtectedComponent(<PoundLocationList />, false)}
        />

        <Route
          path="/geofencelog"
          element={renderProtectedComponent(<GeoFenceLog />, true)}
        />
        {/* <Route
          path="/prioritysettingslist"
          element={renderProtectedComponent(<PrioritySettingList />, false)}
        /> */}
        {/* <Route
          path="/prioritysettings"
          element={renderProtectedComponent(<PrioritySetting />, false)}
        /> */}
      </Routes>
    </React.Suspense>
  )
}

export default RoutesFile
