/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  It contains recoil's atoms files to manage the state value(s)
  atom  : This is an recoil's default library to manage the global state values, This atom is an function and it recieve parameter as object with containing key default values.
  key   : This is an unique key to access this atom function from component.
*/

//import { SignalCellularNull } from '@mui/icons-material'

// System defined variables
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // this key is using to store data in storage
  storage: sessionStorage, // configurate which stroage will be used to store the data
})

const toastMessage = atom({
  key: 'toastMessage',
  default: { message: '', status: '' },
})

const showLoading = atom({
  key: 'showLoading',
  default: false,
})

const axiosConfiguration = atom({
  key: 'axiosConfiguration',
  default: null,
})
const msalConfigAtom = atom({
  key: 'msalConfigAtom',
  default: null,
})

// this atom is used to render inactive user screen when domain is invalid
const invalidDomainAtom = atom({
  key: 'invalidDomainAtom',
  default: false,
})

// this will store the currently typed username in sign-page. This will reset when user click on return to login button on any Inactive page.
const currentUsernameAtom = atom({
  key: 'currentUsernameAtom',
  default: '',
})

const gotInitialSessionResponseOfAuthenticationAtom = atom({
  key: 'gotInitialSessionResponseOfAuthenticationAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

const penalityChargeNoticeDetail = atom({
  key: 'penalityChargeNoticeDetail',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const captchaInputClear = atom({
  key: 'captchaInputClear',
  default: false,
})

const PaymentResponse = atom({
  key: 'PaymentResponse',
  default: '',
})
const NotificationDetails = atom({
  key: 'NotificationDetails',
  default: [],
})
const UserDetails = atom({
  key: 'UserDetails',
  default: '',
})
// persisting this atom to restrict splash screen on page refresh and show the splash screen on initial session
const showSplashScreen = atom({
  key: 'showSplashScreen',
  default: true,
  effects_UNSTABLE: [persistAtom],
})
const prefixSetupData = atom({
  key: 'prefixSetupData',
  default: { mode: 'new', data: null },
  effects_UNSTABLE: [persistAtom],
})
const contractSetupData = atom({
  key: 'contractSetupData',
  default: { mode: 'new', data: null },
  effects_UNSTABLE: [persistAtom],
})

const contraventionSetupData = atom({
  key: 'contraventionSetupData',
  default: { mode: 'new', data: {} },
  effects_UNSTABLE: [persistAtom],
})

//This need to be set dynamic once userRole is implemented
const userId = atom({
  key: 'userId',
  default: 1,
})

const selectedCaseDetails = atom({
  key: 'selectedcasedetails',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const selectCaseDetailNavigationDetails = atom({
  key: 'selectCaseDetailNavigationDetails',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

const selectedContractDetails = atom({
  key: 'selectedcontractdetails',
  default: {},
  effects_UNSTABLE: [persistAtom],
})
const orgSetupData = atom({
  key: 'orgSetupData',
  default: { mode: 'new', data: null },
  effects_UNSTABLE: [persistAtom],
})
const contactAddressData = atom({
  key: 'contactAddressData',
  default: { mode: 'new', data: null },
  effects_UNSTABLE: [persistAtom],
})
const contactAddressstore = atom({
  key: 'contactAddressstore',
  default: false,
})
const additionalOptionsDisabled = atom({
  key: 'additionalOptionsDisabled',
  default: true,
})

const contactAddressPage = atom({
  key: 'contactAddressPage',
  default: false,
})
const imageData = atom({ key: 'imageData', default: false })

const selectedMenuData = atom({
  key: 'selectmenudata',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const expandWidgetCountData = atom({
  key: 'expandWidgetCountData',
  default: false,
})
const widgetCountDataAtom = atom({ key: 'widgetCountDataAtom', default: [] })

const selfRenderring = atom({ key: 'selfRenderring', default: false })

const userTokenDetails = atom({
  key: 'userTokenDetails',
  default: null,
  // effects_UNSTABLE: [persistAtom],
})
const selectOrgNavigationDetails = atom({
  key: 'selectOrgNavigationDetails',
  default: [],
  effects_UNSTABLE: [persistAtom],
})
// contains - considered roles of logged in users (1. when selected any contract - will have that contract's role. 2. When not selected contract will have all distinct assigned roles)
const mappedUserRoles = atom({
  key: 'mappedUserRoles',
  default: [],
  // effects_UNSTABLE: [persistAtom],
})
// contains - roles of logged in user against assigned contract
const assignedContractRoles = atom({
  key: 'assignedContractRoles',
  default: null,
  // effects_UNSTABLE: [persistAtom],
})
// contains - distinct roles of logged in user
const assignedDistinctRoles = atom({
  key: 'assignedDistinctRoles',
  default: [],
  // effects_UNSTABLE: [persistAtom],
})

const faqSetupData = atom({
  key: 'faqSetupData',
  default: { mode: 'new', data: null },
  effects_UNSTABLE: [persistAtom],
})

const refreshCaseOverview = atom({
  key: 'refreshCaseOverview',
  default: null,
})

const selectedRecId = atom({
  key: 'selectedRecId',
  default: {},
})

const templateData = atom({
  key: 'templateData',
  default: { mode: 'new', data: null, type: 'design' },
  effects_UNSTABLE: [persistAtom],
})
//stores decoded claims of logged in user
const userDetail = atom({
  key: 'userDetail',
  default: null,
})
const triggerPaymentRefresh = atom({
  key: 'triggerPaymentRefresh',
  default: null,
})
const currencyAtom = atom({
  key: 'currencySymbolAtom',
  default: {
    //used globally based on selected contract from homeWidget
    selectedContract: {
      currencyName: '',
      currencySymbol: '',
    },
    //used when search different contract's case through global search
    searchedCase: {
      currencyName: '',
      currencySymbol: '',
    },
  },
  effects_UNSTABLE: [persistAtom],
})
const timezoneAtom = atom({
  key: 'timezoneAtom',
  default: {
    //used globally based on selected contract from homeWidget
    selectedContract: {
      timezoneName: 'GMT Standard Time',
      timezoneId: 'Europe/Belfast', //used for conversion in dayjs
      timezoneOffset: '',
    },
    //used when search different contract's case through global search
    searchedCase: {
      timezoneName: 'GMT Standard Time',
      timezoneId: 'Europe/Belfast', //used for conversion in dayjs
      timezoneOffset: '',
    },
    default: {
      timezoneName: 'GMT Standard Time',
      timezoneId: 'Europe/Belfast', //used for conversion in dayjs
      timezoneOffset: 'GMT+00',
    },
  },
  effects_UNSTABLE: [persistAtom],
})
const selectedContactAddress = atom({
  key: 'selectedContactAddress',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

const fetchCurrentApprovalList = atom({
  key: 'fetchCurrentApprovalList',
  default: false,
})

const showLoadingText = atom({
  key: 'showLoadingText',
  default: { isShowLoadingText: false },
})
const tribunalAppealData = atom({
  key: 'tribunalAppealData',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const madeChanges = atom({
  key: 'madeChanges',
  default: false,
})
const invalidatePcnData = atom({
  key: 'invalidatePcnData',
  default: false,
})
const refreshManualDiscountGrid = atom({
  key: 'refreshManualDiscountGrid',
  default: true,
})

const locationData = atom({
  key: 'locationData',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

const bailiffData = atom({
  key: 'bailiffData',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const purgeData = atom({
  key: 'purgeData',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
/* atom used to update when token expired at runtime based on api-response(unauthorized) */
const runtimeTokenExpiredAtom = atom({
  key: 'runtimeTokenExpiredAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
})
const LoaderOnIndexJsAtom = atom({ key: 'LoaderOnIndexJsAtom', default: true })

// made this atom stable due to maintain this inactive(for TokenExpire screen) after refresh also
const userWentInactiveAtom = atom({
  key: 'userWentInactiveAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

const errorCodes = atom({
  key: 'errorCodes',
  default: { errorCode: '', showErrorPage: false },
})

const loggingOutAtom = atom({ key: 'loggingOutAtom', default: false })

const contactAddressKeeper = atom({
  key: 'contactAddressId',
  default: null,
})

const representationReviewParams = atom({
  key: 'representationReviewParams',
  default: {},
})

// used to store the display/hide the search list page
const showSearchListPage = atom({
  key: 'showSearchListPage',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

// used for handling the applied field's list and values in search page
const searchListFormValues = atom({
  key: 'searchListFormValues',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

// Used for handling the reset and apply button values in search page
const buttonResetValues = atom({
  key: 'buttonResetValues',
  default: { resetButton: false, applyButton: false },
  effects_UNSTABLE: [persistAtom],
})

// Used for store the local selected value and used to display the org and contract name
const brandingConfigurationValue = atom({
  key: 'brandingConfigurationValue',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const integrationServiceDataAtom = atom({
  key: 'integrationServiceDataAtom',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const selectLinkedCaseDetails = atom({
  key: 'selectLinkedCaseDetails',
  default: [],
  effects_UNSTABLE: [persistAtom],
})

//used to search list grid data because when main page next and previous case prevision
const searchListFormGridData = atom({
  key: 'searchListFormGridData',
  default: {},
  effects_UNSTABLE: [persistAtom],
})
const isSidebarExpanded = atom({
  key: 'isSidebarExpanded',
  default: true,
  effects_UNSTABLE: [persistAtom],
})
const pcnNumberReservationData = atom({
  key: 'pcnNumberReservationData',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

const refreshReallocationGrid = atom({
  key: 'refreshReallocationGrid',
  default: true,
})
const contractSettingData = atom({
  key: 'contractSettingData',
  default: {},
  effects_UNSTABLE: [persistAtom],
})
const geoFencingSetUpData = atom({
  key: 'geoFencingSetUpData',
  default: { mode: 'new', data: null },
  effects_UNSTABLE: [persistAtom],
})
const brandingStateValues = atom({
  key: 'brandingStateValues',
  default: { brandingRecId: null, brandingType: '', selectedBrandingData: {} },
  effects_UNSTABLE: [persistAtom],
})
const prioritySettingDataFinal = atom({
  key: 'prioritySettingDataFinal',
  default: {},
})
const priorityFieldList = atom({
  key: 'priorityFieldList',
  default: [],
})
export {
  toastMessage,
  showLoading,
  axiosConfiguration,
  msalConfigAtom,
  invalidDomainAtom,
  currentUsernameAtom,
  gotInitialSessionResponseOfAuthenticationAtom,
  penalityChargeNoticeDetail,
  captchaInputClear,
  PaymentResponse,
  NotificationDetails,
  UserDetails,
  showSplashScreen,
  selectedCaseDetails,
  selectedContractDetails,
  prefixSetupData,
  contractSetupData,
  userId,
  orgSetupData,
  contactAddressData,
  imageData,
  contactAddressstore,
  additionalOptionsDisabled,
  selectedMenuData,
  contactAddressPage,
  selfRenderring,
  expandWidgetCountData,
  widgetCountDataAtom,
  contraventionSetupData,
  userTokenDetails,
  selectOrgNavigationDetails,
  selectCaseDetailNavigationDetails,
  mappedUserRoles,
  assignedContractRoles,
  assignedDistinctRoles,
  faqSetupData,
  refreshCaseOverview,
  templateData,
  selectedRecId,
  userDetail,
  triggerPaymentRefresh,
  currencyAtom,
  timezoneAtom,
  selectedContactAddress,
  showLoadingText,
  fetchCurrentApprovalList,
  tribunalAppealData,
  madeChanges,
  invalidatePcnData,
  refreshManualDiscountGrid,
  locationData,
  bailiffData,
  purgeData,
  runtimeTokenExpiredAtom,
  LoaderOnIndexJsAtom,
  userWentInactiveAtom,
  errorCodes,
  loggingOutAtom,
  contactAddressKeeper,
  representationReviewParams,
  showSearchListPage,
  searchListFormValues,
  buttonResetValues,
  brandingConfigurationValue,
  integrationServiceDataAtom,
  selectLinkedCaseDetails,
  searchListFormGridData,
  isSidebarExpanded,
  refreshReallocationGrid,
  pcnNumberReservationData,
  contractSettingData,
  geoFencingSetUpData,
  brandingStateValues,
  prioritySettingDataFinal,
  priorityFieldList,
}
