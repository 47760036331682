/**
 * @file        utils.js
 * @description This file contains all the common functions which are used in the application.
 * @version     0.1.0
 * @copyright   This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
                is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd.
 * @license     All rights reserved.
 */
// System defined variables
import React from 'react'
import { DateTime } from 'luxon'
import _ from 'lodash'
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

// Custom defined variables
import homeGrey from '../assets/static/Marston_NPS_Grey/Home_Grey.svg'
import homeBlue from '../assets/static/Marston_NPS_Blue/Home_Blue.svg'
import reportsGrey from '../assets/static/Marston_NPS_Grey/Reports_Grey.svg'
import reportsBlue from '../assets/static/Marston_NPS_Blue/Reports_Blue.svg'
import hhdMessageGrey from '../assets/static/Marston_NPS_Grey/HHDMessage_Grey.svg'
import hhdMessageBlue from '../assets/static/Marston_NPS_Blue/HHDMessage_Blue.svg'
import approvalQueueGrey from '../assets/static/Marston_NPS_Grey/ApprovalQueue_Grey.svg'
import approvalQueueBlue from '../assets/static/Marston_NPS_Blue/ApprovalQueue_Blue.svg'
import configurationGrey from '../assets/static/Marston_NPS_Grey/Configuration_Grey.svg'
import configurationBlue from '../assets/static/Marston_NPS_Blue/Configuration_Blue.svg'
import searchGrey from '../assets/static/Marston_NPS_Grey/Search_Grey.svg'
import searchBlue from '../assets/static/Marston_NPS_Blue/Search_blue.svg'
import clientSetupGrey from '../assets/static/Marston_NPS_Grey/Client_Setup.svg'
import clientSetupBlue from '../assets/static/Marston_NPS_Blue/ClientSetup_Blue.svg'
import workflowManagerGrey from '../assets/static/Marston_NPS_Grey/WorkflowManager_Grey.svg'
import workflowManagerBlue from '../assets/static/Marston_NPS_Blue/WorkflowManager_Blue.svg'
import myWorkflowGrey from '../assets/static/Marston_NPS_Grey/MyWorkflow_Grey.svg'
import myWorkflowBlue from '../assets/static/Marston_NPS_Blue/MyWorkflow_Blue.svg'
import noticeProcessingGrey from '../assets/static/Marston_NPS_Grey/Notice_Processing_Grey.svg'
import noticeProcessingBlue from '../assets/static/Marston_NPS_Blue/Notice_Processing_Blue.svg'
//import representationGrey from '../assets/static/Marston_NPS_Grey/RepresentationReview_Grey.svg'
//import representationBlue from '../assets/static/Marston_NPS_Blue/RepresentationReview_Blue.svg'
import wokinghamGrey from '../assets/static/Marston_NPS_Grey/Wokingham_Grey.svg'
import wokinghamBlue from '../assets/static/Marston_NPS_Blue/Wokingham_Blue.svg'
import liveTrackingGrey from '../assets/static/Marston_NPS_Grey/LiveTracking_Grey.svg'
import liveTrackingBlue from '../assets/static/Marston_NPS_Blue/LiveTracking_Blue.svg'
import { createTheme } from '@mui/material'
import manualCaseBlue from '../assets/static/Marston_NPS_Blue/manualCase_Blue.svg'
import manualCaseGrey from '../assets/static/Marston_NPS_Grey/Manual_case_Grey.svg'
import CR_ConfigBlue from '../assets/static/Marston_NPS_Blue/CR_Config_Blue.svg'
import CR_ConfigGrey from '../assets/static/Marston_NPS_Grey/CR_Config_grey.svg'
import clampAndRemovalBlue from '../assets/static/Marston_NPS_Blue/ClampAndRemovalBlue.svg'
import clampRemovalGrey from '../assets/static/Marston_NPS_Grey/ClampRemovalGrey.svg'

// function to sort string and numeric values in array of objects based on a key -- start
function sortArrayOfObjects(arrayOfObjectsToSort, sortByKey) {
  let arrayOfObjects = arrayOfObjectsToSort
  // If Array has NONE property Shift before sort and unshift after sort
  let hasNoneProperty = false
  if (
    arrayOfObjects &&
    arrayOfObjects?.length > 0 &&
    (arrayOfObjects[0]?.Name === '-- None --' ||
      arrayOfObjects[0]?.id === '-- None --')
  ) {
    hasNoneProperty = true
    arrayOfObjects.shift()
  }
  const stringArr = [] // will have null, undefined and others
  const numericArr = [] // will have numeric data
  arrayOfObjects?.forEach((obj) => {
    // Separate arrayOfObjects data to numeric and string array
    if (
      obj[sortByKey] !== undefined &&
      obj[sortByKey] !== null &&
      isNaN(obj[sortByKey]?.toString().trim() ? obj[sortByKey] : '_') // since IsNaN('  ') returns  -> false, making it true with underscore
    )
      stringArr.push(obj)
    else numericArr.push(obj)
  })

  // string sort -> if same string -> small case will have higher priority
  stringArr.sort((a, b) => {
    const data =
      a[sortByKey] && a[sortByKey] !== null && a[sortByKey] !== undefined
        ? a[sortByKey]?.toString()
        : null
    const compareData =
      b[sortByKey] && b[sortByKey] !== null && b[sortByKey] !== undefined
        ? b[sortByKey]?.toString()
        : null

    if (data?.toLowerCase() === compareData?.toLowerCase()) {
      let returnData = 0
      for (
        let dataCharIndex = 0;
        dataCharIndex < data?.length;
        dataCharIndex++
      ) {
        for (
          let compareDataCharIndex = 0;
          compareDataCharIndex < compareData?.length;
          compareDataCharIndex++
        ) {
          if (
            data.charCodeAt(data[dataCharIndex]) <
            compareData.charCodeAt(compareData[compareDataCharIndex])
          ) {
            compareDataCharIndex = compareData?.length
            dataCharIndex = data?.length
            returnData = 1
          }
          if (
            data.charCodeAt(data[dataCharIndex]) >
            compareData.charCodeAt(compareData[compareDataCharIndex])
          ) {
            compareDataCharIndex = compareData?.length
            dataCharIndex = data?.length
            returnData = -1
          }
        }
      }
      return returnData
    }
    if (data?.toLowerCase() < compareData?.toLowerCase()) return -1
    if (data?.toLowerCase() > compareData?.toLowerCase()) return 1
  })

  // numeric sort
  numericArr.sort((a, b) => parseFloat(a[sortByKey]) - parseFloat(b[sortByKey]))
  Array.prototype.push.apply(numericArr, stringArr)
  arrayOfObjects = numericArr

  if (hasNoneProperty)
    arrayOfObjects.unshift({ Name: '-- None --', id: '-- None --' })
  return arrayOfObjects
}
// function to sort string and numeric values in array of objects based on a key -- end

/* This function was developed for alert show to user when they goback on edited stage */
/* istanbul ignore next */
const apiResponseCode = {
  successCode: 200,
  internalServerError: 500,
  badRequest: 400,
  notFound: 404,
  forbidden: 403,
  unauthorized: 401, //tokenExpired
  noContent: 204,
  zeroCode: 0,
  successResponse: 201,
}
const apiDomainName = {
  userPermission: 'usePermission',
}
const handleBeforeUnload = (e) => {
  e.preventDefault()
  const message =
    'Are you sure wish to go back? You will lose all entered information.'
  e.returnValue = message
  return message
}
/* istanbul ignore next */
const removeKeyFromObject = (
  obj,
  keysToRemove = [],
  removeKeysWithIncludes = []
) => {
  let objClone = _.cloneDeep(obj)
  if (!Array.isArray(obj)) objClone = [objClone]

  return objClone.map((objItem) => {
    keysToRemove.map((keyItem) => delete objItem[keyItem])
    if (removeKeysWithIncludes && removeKeysWithIncludes.length > 0) {
      const keyList = Object.keys(objItem)
      keyList.forEach((key) => {
        removeKeysWithIncludes.map((keyItem) => {
          const selectedKey = key.includes(keyItem)
          if (selectedKey && objItem[key]) delete objItem[key]
        })
      })
    }
    return objItem
  })
}

const convertArrOfObjectToArrOfString = (objArray, requiredKey) => {
  const ArrayToString = (value) => value[requiredKey]
  const stringArray = objArray?.map(ArrayToString)

  return stringArray || []
}
/* istanbul ignore next */
/* will inclue in lazyload implementation */
const confirmwindow = (conformText) => {
  if (
    window.confirm(
      conformText ||
        'Are you sure wish to go back? You will lose all entered information.'
    )
  ) {
    return true
  }
  return false
}
const utcDateFormat = (PickerType) => {
  if (PickerType === 'Date') {
    return 'yyyy-MM-dd'
  }
  return 'yyyy-MM-dd HH:mm:ss'
}
// This will contain roles for each menuItem. And this spread to menuList data
const menuAccessRoles = {
  home: [
    'SuperAdmin',
    'OrganisationAdmin',
    'ContractAdmin',
    'BOManager',
    'QCBOUser',
    'BOUser',
    'NoticeProcessing',
    'Client',
    'ReadOnly',
    'CEOManager',
    'ClientReadOnly',
    'PoundOperator',
    'PoundManager',
  ],

  selectedContract: [
    'SuperAdmin',
    'OrganisationAdmin',
    'ContractAdmin',
    'BOManager',
    'QCBOUser',
    'BOUser',
    'NoticeProcessing',
    'Client',
    'ReadOnly',
    'CEOManager',
    'ClientReadOnly',
  ],
  globalSearch: [
    'SuperAdmin',
    'BOManager',
    'QCBOUser',
    'BOUser',
    'NoticeProcessing',
    'Client',
    'ReadOnly',
    'ClientReadOnly',
    'CEOManager',
    'PoundOperator',
    'PoundManager',
  ],
  reporting: [
    'SuperAdmin',
    'OrganisationAdmin',
    'ContractAdmin',
    'BOManager',
    'QCBOUser',
    'NoticeProcessing',
    'Client',
    'ReadOnly',
    'CEOManager',
    'PoundManager',
  ],
  myWorkFlow: ['SuperAdmin', 'BOManager', 'QCBOUser', 'BOUser', 'Client'],
  workflowManager: ['SuperAdmin', 'BOManager', 'QCBOUser', 'NoticeProcessing'],
  approvalQueue: ['SuperAdmin', 'BOManager', 'QCBOUser'],
  noticeProcessing: ['SuperAdmin', 'BOManager', 'NoticeProcessing'],
  debtReview: ['Client'],
  configuration: ['SuperAdmin', 'OrganisationAdmin', 'ContractAdmin'],
  clientSetup: ['SuperAdmin', 'OrganisationAdmin'],
  hhdMessage: [
    'SuperAdmin',
    'BOManager',
    'QCBOUser',
    'BOUser',
    'NoticeProcessing',
    'Client',
    'CEOManager',
    'PoundOperator',
    'PoundManager',
  ],
  manualCase: [
    'SuperAdmin',
    'OrganisationAdmin',
    'ContractAdmin',
    'BOManager',
    'NoticeProcessing',
    'CEOManager',
  ],
  representationReview: ['SuperAdmin', 'BOManager', 'Client'],
  tracking: [
    'BOManager',
    'CEOManager',
    'SuperAdmin',
    'OrganisationAdmin',
    'ContractAdmin',
  ],
  crConfiguration: ['SuperAdmin', 'OrganisationAdmin', 'ContractAdmin'],
}
const subMenuAccessRoles = {
  debtReview: [
    ...menuAccessRoles?.noticeProcessing,
    ...menuAccessRoles?.debtReview,
  ],
  monitoring: [
    'SuperAdmin',
    'OrganisationAdmin',
    'ContractAdmin',
    'BOManager',
    'QCBOUser',
    'NoticeProcessing',
    'ReadOnly',
    'CEOManager',
  ],
  geoFenceLog: ['SuperAdmin', 'BOManager', 'CEOManager'],
}
const contractTypes = {
  pcnName: 'pcn',
  fpnName: 'fpn',
}
const menuList = [
  {
    menuindex: 0,
    menuItem: 'Home',
    menuItemId: 'Home',
    redirectURL: '/',
    imageURLactive: homeBlue,
    imageURLinactive: homeGrey,
    roles: [...menuAccessRoles?.home],
    contractRequired: false,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: false,
  },
  {
    menuenable: true,
    menuindex: 0,
    menuItem: `__SelectedContractName__`,
    menuItemId: 'wokinghampcn',
    redirectURL: '/caseoverview',
    imageURLactive: wokinghamBlue,
    imageURLinactive: wokinghamGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.selectedContract],
    contractRequired: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: false,
  },
  {
    menuenable: true,
    menuindex: 1,
    menuItem: `Search`,
    menuItemId: 'Search',
    redirectURL: '/search',
    imageURLactive: searchBlue,
    imageURLinactive: searchGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.globalSearch],
    contractRequired: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: false,
  },
  {
    menuenable: true,
    menuindex: 2,
    menuItem: `Reporting`,
    menuItemId: 'reporting',
    imageURLactive: reportsBlue,
    imageURLinactive: reportsGrey,
    openCollapse: false,
    //redirectURL: '/reporting',
    roles: [...menuAccessRoles?.reporting],
    contractRequired: true,
    subMenuSpecified: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Case Status',
        menuItemId: 'casestatus',
        redirectURL: '/casestatus',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Correspondence Out',
        menuItemId: 'outboundcorrespondence',
        redirectURL: '/outboundcorrespondence',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Correspondence In',
        menuItemId: 'inboundcorrespondence',
        redirectURL: '/inboundcorrespondence',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Enforcement Team',
        menuItemId: 'enforcementteam',
        redirectURL: '/enforcementteam',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Financial',
        menuItemId: 'financial',
        redirectURL: '/financial',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Audit & Tracking',
        menuItemId: 'auditandtracking',
        redirectURL: '/auditandtracking',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Monitoring',
        menuItemId: 'monitoring',
        redirectURL: '/monitoring',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Performance Monitoring',
        menuItemId: 'performancemonitoring',
        redirectURL: '/performancemonitoring',
        roles: [...menuAccessRoles?.reporting],
        contractType: [contractTypes?.pcnName],
      },
    ],
    openCollapse: true,
  },
  {
    menuenable: true,
    menuindex: 3,
    menuItem: `My Workflow`,
    menuItemId: 'myWorkflow',
    redirectURL: '/myworkflow',
    imageURLactive: myWorkflowBlue,
    imageURLinactive: myWorkflowGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.myWorkFlow],
    contractRequired: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: false,
  },
  {
    menuenable: true,
    menuindex: 4,
    menuItem: `Rep.Review`,
    menuItemId: 'repReview',
    redirectURL: '/repReview',
    imageURLactive: myWorkflowBlue,
    imageURLinactive: myWorkflowGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.representationReview],
    contractRequired: true,
    displayBasedOnFlagKey: 'clientWorkFlow',
    contractType: [contractTypes?.pcnName],
    isHavingSubmenu: false,
  },
  {
    menuenable: true,
    menuindex: 5,
    menuItem: `Workflow Manager`,
    menuItemId: 'workflowmanager',
    redirectURL: '/workflowmanager',
    imageURLactive: workflowManagerBlue,
    imageURLinactive: workflowManagerGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.workflowManager],
    contractRequired: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: false,
  },
  {
    menuenable: true,
    menuindex: 6,
    menuItem: `Approval Queue`,
    menuItemId: 'approvalQueue',
    redirectURL: '/approvalQueueList',
    imageURLactive: approvalQueueBlue,
    imageURLinactive: approvalQueueGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.approvalQueue],
    contractRequired: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: false,
  },
  {
    menuenable: true,
    menuindex: 7,
    menuItem: `Notice Processing`,
    menuItemId: 'noticeProcessing',
    //redirectURL: '/noticeprocessing',
    imageURLactive: noticeProcessingBlue,
    imageURLinactive: noticeProcessingGrey,
    openCollapse: false,
    roles: [
      ...menuAccessRoles?.noticeProcessing,
      ...menuAccessRoles.debtReview,
    ],
    contractRequired: true,
    subMenuSpecified: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Debt Registration',
        menuItemId: 'debt',
        redirectURL: '/debt',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Warrant Request',
        menuItemId: 'warrant',
        redirectURL: '/warrant',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Bailiff',
        menuItemId: 'bailiff',
        redirectURL: '/bailiffbatch',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Bailiff Message',
        menuItemId: 'bailiffMessage',
        redirectURL: '/bailiffmessage',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Pre Debt',
        menuItemId: 'preDebt',
        redirectURL: '/predebt',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Debt Review',
        menuItemId: 'debtReview',
        redirectURL: '/debtreview',
        roles: [...subMenuAccessRoles?.debtReview],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'DVLA',
        menuItemId: 'dvla',
        redirectURL: '/dvla',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Print Batches',
        menuItemId: 'printBatches',
        redirectURL: '/printbatchlist',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Foreign Debt',
        menuItemId: 'foreignDebt',
        redirectURL: '/foreigndebt',
        displayBasedOnFlagKey: 'isInternationalDebtRecovery',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Scan Import',
        menuItemId: 'scanImport',
        redirectURL: '/scanimport',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      // {
      //   submenuenable: true,
      //   menuItem: 'Awaiting Print',
      //   menuItemId: 'awaitingPrint',
      //   redirectURL: '/awaitingprint',
      // },
      // {
      //   submenuenable: true,
      //   menuItem: 'Financial',
      //   menuItemId: 'financial',
      //   redirectURL: '/financial',
      // },
      {
        submenuenable: true,
        menuItem: 'Bulk Update',
        menuItemId: 'bulkUpdate',
        redirectURL: '/bulkupdatelist',
        roles: [...menuAccessRoles?.noticeProcessing],
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
    ],
    openCollapse: true,
  },
  {
    menuenable: true,
    menuindex: 7,
    menuItem: 'Configuration',
    menuItemId: 'Configuration',
    imageURLactive: configurationBlue,
    imageURLinactive: configurationGrey,
    roles: [...menuAccessRoles?.configuration],
    contractRequired: false,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Users',
        menuItemId: 'Users',
        redirectURL: '/manageuser',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Templates',
        menuItemId: 'template',
        redirectURL: '/templates',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Zone',
        menuItemId: 'zone',
        redirectURL: '/zone',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Location',
        menuItemId: 'Location',
        redirectURL: '/location',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      // {
      //   submenuenable: true,
      //   menuItem: 'Country',
      //   menuItemId: 'country',
      // },
      {
        submenuenable: true,
        menuItem: 'Contravention Codes',
        menuItemId: 'contraventioncodes',
        redirectURL: '/contraventions',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      // {
      //   submenuenable: true,
      //   menuItem: 'Vehicle Makes',
      //   menuItemId: 'vehiclemakes',
      // },
      // {
      //   submenuenable: true,
      //   menuItem: 'Vehicle Colours',
      //   menuItemId: 'vehiclecolours',
      // },
      {
        submenuenable: true,
        menuItem: 'FAQ',
        menuItemId: 'FAQ',
        redirectURL: '/faqList',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Cancellation Reasons',
        menuItemId: 'cancellationReason',
        redirectURL: '/cancellationreason',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'CP Rep. Grounds',
        menuItemId: 'groundsList',
        redirectURL: '/groundslist',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Hold',
        menuItemId: 'holdReasons',
        redirectURL: '/holdreasonslist',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Geo Fencing Setup',
        menuItemId: 'geofencingsetuplist',
        redirectURL: '/geofencingsetuplist',
      },
    ],
    openCollapse: true,
  },
  {
    menuenable: true,
    menuindex: 8,
    menuItem: 'Client Setup',
    menuItemId: 'clientsetup',
    imageURLactive: clientSetupBlue,
    imageURLinactive: clientSetupGrey,
    roles: [...menuAccessRoles?.clientSetup],
    contractRequired: false,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Organisation',
        menuItemId: 'organisation',
        redirectURL: '/organisationlist',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Contract',
        menuItemId: 'contract',
        redirectURL: '/contractListPage',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Branding',
        menuItemId: 'branding',
        redirectURL: '/brandinglistpage',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Prefix',
        menuItemId: 'prefix',
        redirectURL: '/prefix',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'Bailiff',
        menuItemId: 'bailiff',
        redirectURL: '/bailiff',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      // {
      //   submenuenable: true,
      //   menuItem: 'Purge',
      //   menuItemId: 'Purge',
      //   redirectURL: '/purge',
      //   contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      // },
      {
        submenuenable: true,
        menuItem: 'Integration',
        menuItemId: 'integration',
        redirectURL: '/integration',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
    ],
    openCollapse: true,
  },
  {
    menuenable: true,
    menuindex: 9,
    menuItem: `Messaging`,
    menuItemId: 'hhdMessage',
    //redirectURL: '/hhdmessage',
    imageURLactive: hhdMessageBlue,
    imageURLinactive: hhdMessageGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.hhdMessage],
    contractRequired: true,
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
    isHavingSubmenu: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Backoffice',
        menuItemId: 'backOffice',
        redirectURL: '/backoffice',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      {
        submenuenable: true,
        menuItem: 'On Street',
        menuItemId: 'onStreet',
        redirectURL: '/onstreet',
        contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      },
      // {
      //   submenuenable: true,
      //   menuItem: 'Tasks',
      //   menuItemId: 'tasks',
      //   redirectURL: '/tasks',
      //   displayBasedOnFlagKey: 'isCreateTask',
      //   contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
      // },
    ],
    openCollapse: true,
  },
  {
    menuenable: true,
    menuindex: 10,
    menuItem: 'Tracking',
    menuItemId: 'tracking',
    imageURLactive: clientSetupBlue,
    imageURLinactive: clientSetupGrey,
    openCollapse: true,
    roles: [...menuAccessRoles?.tracking],
    contractRequired: true,
    contractType: [contractTypes?.pcnName],
    subMenuSpecified: true,
    isHavingSubmenu: true,
    isOfficerTracking: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Officer Tracking',
        menuItemId: 'officerTracking',
        redirectURL: '/officertracking',
        isOfficerTracking: true,
        contractType: [contractTypes?.pcnName],
        roles: [...menuAccessRoles?.tracking],
      },
      {
        submenuenable: true,
        menuItem: 'Geo Fence Log',
        menuItemId: 'geoFenceLog',
        redirectURL: '/geofencelog',
        isOfficerTracking: true,
        contractType: [contractTypes?.pcnName],
        roles: [...subMenuAccessRoles?.geoFenceLog],
      },
    ],
  },
  {
    menuenable: true,
    menuindex: 11,
    menuItem: `Manual Case`,
    menuItemId: 'manualCase',
    imageURLactive: manualCaseBlue,
    imageURLinactive: manualCaseGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.manualCase],
    contractRequired: true,
    isManualCaseEnabled: true,
    contractType: [contractTypes?.pcnName],
    isHavingSubmenu: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Manual case creation',
        menuItemId: 'manualCaseCreation',
        redirectURL: '/manualcasecreation',
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'PCN number reservation',
        menuItemId: 'pcnNumberReservation',
        redirectURL: '/pcnnumberreservation',
        contractType: [contractTypes?.pcnName],
      },
    ],
    openCollapse: true,
  },
  // {
  //   menuenable: true,
  //   menuindex: 12,
  //   menuItem: `Clamp & Removal`,
  //   menuItemId: 'crConfiguration',
  //   imageURLactive: clampAndRemovalBlue,
  //   imageURLinactive: clampRemovalGrey,
  //   openCollapse: false,
  //   roles: [...menuAccessRoles?.crConfiguration],
  //   contractRequired: true,
  //   contractType: [contractTypes?.pcnName],
  //   isHavingSubmenu: true,
  //   isCandREnabled: true,
  //   MenuGroupitem: [
  //     {
  //       submenuenable: true,
  //       menuItem: 'Jobs',
  //       menuItemId: 'jobs',
  //       redirectURL: '/jobs',
  //       contractType: [contractTypes?.pcnName],
  //     },
  //   ],
  //   openCollapse: true,
  // },
  {
    menuenable: true,
    menuindex: 13,
    menuItem: `CR Configuration`,
    menuItemId: 'crConfiguration',
    imageURLactive: CR_ConfigBlue,
    imageURLinactive: CR_ConfigGrey,
    openCollapse: false,
    roles: [...menuAccessRoles?.crConfiguration],
    contractRequired: false,
    contractType: [contractTypes?.pcnName],
    isHavingSubmenu: true,
    MenuGroupitem: [
      {
        submenuenable: true,
        menuItem: 'Pound Location',
        menuItemId: 'poundLocation',
        redirectURL: '/poundlocation',
        contractType: [contractTypes?.pcnName],
      },
      {
        submenuenable: true,
        menuItem: 'Cancellation Reasons',
        menuItemId: 'cancellationreason',
        redirectURL: '/cancellationreasonlist',
        contractType: [contractTypes?.pcnName],
      },
      // {
      //   submenuenable: true,
      //   menuItem: 'Priority Settings',
      //   menuItemId: 'prioritysettings',
      //   redirectURL: '/prioritysettingslist',
      //   contractType: [contractTypes?.pcnName],
      // },
    ],
    openCollapse: true,
  },
]

/* istanbul ignore next */
// getting the browser name
function browserIdentify() {
  var ua = navigator.userAgent
  var tem
  var browserName =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []
  if (/trident/i.test(browserName[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (browserName[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem !== null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  browserName = browserName[2]
    ? [browserName[1], browserName[2]]
    : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) !== null)
    browserName.splice(1, 1, tem[1])
  return browserName[0]
}

/* istanbul ignore next */
// This return role of selected contract. Due to logged in user's
const getRoleFromAssignedContractJSON = (selectedContract, contractJSON) => {
  let selectedContractBasedRole = {}
  let superAdminOrOrgAdmin = ''
  if (contractJSON) {
    selectedContractBasedRole = JSON.parse(contractJSON).find((item) => {
      //For SuperAdmin and OrgAdmin role, the JSON string will have one object with contractId-0(represents - AllOrg/AllContracts) with its roleName
      if (item?.Id?.toString() === '0') {
        superAdminOrOrgAdmin = item?.RoleName
      }
      return item?.Id === selectedContract?.contractId
    })
  }
  // priority given to specific contract's role then superAdmin/OrgAdmin
  return selectedContractBasedRole?.RoleName
    ? [selectedContractBasedRole?.RoleName]
    : superAdminOrOrgAdmin
    ? [superAdminOrOrgAdmin]
    : []
}
/* istanbul ignore next */
const checkUserRole = (contractId, contractJSON, roleName) => {
  let selectedContractBasedRole = {}
  if (contractJSON) {
    selectedContractBasedRole = JSON.parse(contractJSON).find((item) => {
      return item?.Id === contractId
    })
  }
  if (
    selectedContractBasedRole?.RoleName?.toString()
      ?.toLowerCase()
      ?.replaceAll(' ', '') === roleName?.toLowerCase()?.replaceAll(' ', '')
  )
    return true
  else return false
}

/* istanbul ignore next */
// function to return permission status with userRoleData(Array) and AllowedRoleData(Array)
const checkPermittedRoleOrNot = (
  selectedContract,
  currentRoleJsonArray,
  validRoleArray
) => {
  let selectedContractBasedRole = {}

  selectedContractBasedRole = JSON?.parse(currentRoleJsonArray)?.find(
    (item) => {
      //For SuperAdmin and OrgAdmin role, the JSON string will have one object with contractId-0(represents - AllOrg/AllContracts) with its roleName
      return (
        item?.Id?.toString() === '0' ||
        item?.Id === selectedContract?.contractId
      )
    }
  )
  if (
    validRoleArray?.includes(
      selectedContractBasedRole?.RoleName?.toString()
        ?.toLowerCase()
        ?.replaceAll(' ', '')
    )
  )
    return true
  else return false
}

const tptetaNoticeType = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
]

/* istanbul ignore next */
const commonDateTimeFormat = (PickerType) => {
  // const localeFormatStr = localStorage.getItem('localeDate')
  if (PickerType === 'Date') {
    const dateFormat = 'dd/MM/yyyy'
    // localeFormat?.localeDateFormat[localeFormatStr] ?? 'dd/MM/yyyy'
    return dateFormat
  }

  if (PickerType === 'Time24h') {
    const dateTimeFormat = 'HH:mm:ss'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'HH:mm'
    return dateTimeFormat
  }
  if (PickerType === 'Time24hwithoutsec') {
    return 'HH:mm'
  }

  if (PickerType === 'Time12h') {
    const dateTimeFormat = 'hh:mm:ss A'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'HH:mm'
    return dateTimeFormat
  }
  if (PickerType === 'DateAndTime') {
    const dateTimeFormat = 'dd/MM/yyyy HH:mm:ss'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'dd/MM/yyyy HH:mm:ss'
    return dateTimeFormat
  }
  if (PickerType === 'DateAndTimeForMoment') {
    const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'dd/MM/yyyy HH:mm:ss'
    return dateTimeFormat
  }
  if (PickerType === 'utcDate') {
    const dateTimeFormat = 'yyyy-MM-dd'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'dd/MM/yyyy HH:mm:ss'
    return dateTimeFormat
  }
  if (PickerType === 'monthText') {
    return 'MMMM'
  }
  if (PickerType === 'year') {
    return 'yyyy'
  }
  if (PickerType === 'downloadingFileNameFormat_Date') return 'ddMMyyyy' //'YYYYMMDD'
  if (PickerType === 'downloadingFileNameFormat_Time') return 'HHmmss'
  if (PickerType === 'downloadingFileNameFormat_Date_Time')
    return 'ddMMyyyy_HHmmss'
}

/* istanbul ignore next */
// Separated dateFormat for baseComponent due to luxon library uses small case dateFormat and react-multi-date-picker uses upperCase as date-format
const commonDateTimeFormatForReactMultiDatePicker = (pickerType) => {
  if (pickerType === 'Date') {
    const dateFormat = 'DD/MM/YYYY'
    return dateFormat
  }

  if (pickerType === 'Time24h') {
    const dateTimeFormat = 'HH:mm:ss'
    return dateTimeFormat
  }
  if (pickerType === 'Time24hwithoutsec') {
    return 'HH:mm'
  }

  if (pickerType === 'Time12h') {
    const dateTimeFormat = 'hh:mm:ss A'
    return dateTimeFormat
  }
  if (pickerType === 'DateAndTime') {
    const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss'
    return dateTimeFormat
  }
  if (pickerType === 'downloadingFileNameFormat_Date') return 'DDMMYYYY' //'YYYYMMDD'
  if (pickerType === 'downloadingFileNameFormat_Time') return 'HHmmss'
}
/* istanbul ignore next */
const commonDateTimePlaceHolder = (PickerPlaceHolder) => {
  // const localeFormatStr = localStorage.getItem('localeDate')
  if (PickerPlaceHolder === 'Date') {
    const datePlaceHolder = 'dd/mm/yyyy'
    // localeFormat?.localeDateFormat[localeFormatStr] ?? 'dd/MM/yyyy'
    return datePlaceHolder
  }

  if (PickerPlaceHolder === 'Time24h') {
    const TimePlaceHolder = 'hh:mm:ss'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'HH:mm'
    return TimePlaceHolder
  }

  if (PickerPlaceHolder === 'Time24hwithoutsec') {
    return 'hh:mm'
  }

  if (PickerPlaceHolder === 'Time12h') {
    const TimePlaceHolder = 'hh:mm:ss A'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'HH:mm'
    return TimePlaceHolder
  }
  if (PickerPlaceHolder === 'DateAndTime') {
    const dateTimePlaceHolder = 'dd/mm/yyyyThh:mm:ss'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'dd/MM/yyyy HH:mm:ss'
    return dateTimePlaceHolder
  }
}
/* istanbul ignore next */
const commonPostBodyDateTimeFormat = (pickerType) => {
  if (pickerType?.toString()?.toLowerCase() === 'date') return 'YYYY-MM-DD'
  if (pickerType?.toString()?.toLowerCase() === 'dateandtime')
    return 'YYYY-MM-DD HH:mm:ss'
}

/* const dateAndTimeConversion = (params, converttype = null) => {
  if (converttype) {
    return dayjs(params).format('DD/MM/YYYY')
  } else {
    if (params?.data?.createdOn) {
      if (params?.data?.createdOn?.length > 10) {
        return (
          dayjs(params?.data?.createdOn).format('DD/MM/YYYY') +
          ' ' +
          dayjs(params?.data?.createdOn).format('HH:mm')
        )
      } else if (params?.data?.createdOn?.length === 10) {
        return dayjs(params?.data?.createdOn).format('DD/MM/YYYY')
      }
    }
  }
} */
/* const dateAndTimeConversion = (params, converttype = null) => {
  if (converttype) {
    return dayjs(params).format('DD/MM/YYYY')
  } else {
    const field = params?.colDef?.field
    const data = params?.data?.hasOwnProperty(field)
      ? params?.data[field]
      : null
    if (data) {
      if (data?.length > 10) {
        return (
          dayjs(data).format('DD/MM/YYYY') + ' ' + dayjs(data).format('HH:mm')
        )
      } else if (data?.length === 10) {
        return dayjs(data).format('DD/MM/YYYY')
      }
    }
  }
} */
/* istanbul ignore next */
const dateAndTimeConversion = (params, converttype = null) => {
  if (converttype) {
    // here the params(date from ag-grid filter) date will be in this format 'Mon Aug 21 2023 00:00:00 GMT+0530'. Therefore converting it to date object to isoString which will be accepted in luxon library.
    const dateString = new Date(params)
    if (converttype === 'utcDate') {
      return DateTime?.fromISO(dateString?.toISOString())?.toFormat(
        commonDateTimeFormat('utcDate')
      )
    } else {
      return DateTime?.fromISO(dateString?.toISOString())?.toFormat(
        commonDateTimeFormat(converttype || 'Date')
      )
    }
  } else {
    const field = params?.colDef?.field
    const data = params?.data?.hasOwnProperty(field)
      ? params?.data[field]
      : null
    if (data) {
      if (data?.length > 10) {
        return (
          DateTime?.fromISO(data)?.toFormat(commonDateTimeFormat('Date')) +
          ' ' +
          DateTime?.fromISO(data)?.toFormat(
            commonDateTimeFormat('Time24hwithoutsec')
          )
        )
      } else if (data?.length === 10) {
        return DateTime?.fromISO(data)?.toFormat(commonDateTimeFormat('Date'))
      }
    }
  }
}
/* istanbul ignore next */
const convertSystemDateTimeToDate = (params) => {
  let dataValue = ''
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    dataValue = DateTime.fromISO(params?.data[params?.colDef?.field]).toFormat(
      commonDateTimeFormat('Date')
    )
  }
  return dataValue
}
/* istanbul ignore next */
const convertDateTimePickerDateTimeToCustomFormat = (value, customFormat) => {
  let dataValue = value
  if (dataValue) {
    if (customFormat === commonDateTimeFormat('Date'))
      dataValue = dataValue?.substring(0, 10)
    else if (customFormat === commonDateTimeFormat('Time24h'))
      dataValue = dataValue?.substring(0, 19)
  }
  return dataValue
}

const iconFontSize = '20px'
/* istanbul ignore next */
const convertUtcDateTimeToFormattedDateAgGrid = (params) => {
  let dataValue = ''
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    dataValue = DateTime.fromISO(params?.data[params?.colDef?.field]).toFormat(
      commonDateTimeFormat('Date')
    )
  }
  return dataValue
}
/* istanbul ignore next */
const convertUtcDateTimeToFormattedDateTimeAgGrid = (params) => {
  let dataValue = ''
  if (
    params?.data?.hasOwnProperty(params?.colDef?.field) &&
    params?.data[params?.colDef?.field]
  ) {
    dataValue = DateTime.fromISO(params?.data[params?.colDef?.field]).toFormat(
      commonDateTimeFormat('DateAndTime')
    )
  }
  return dataValue
}
/* istanbul ignore next */
const convertUtcDateTimeToFormattedDateTime = (dateTimeValue, formatType) => {
  let dataValue = ''
  if (dateTimeValue) {
    dataValue = DateTime.fromISO(
      getDateTimeValueInUtcFormat(dateTimeValue)
    ).toFormat(formatType)
  }
  return dataValue
}
/* istanbul ignore next */
const getDateTimeValueInUtcFormat = (dateTimeValue) => {
  return dateTimeValue?.length === 10
    ? dateTimeValue
    : `${dateTimeValue?.toString()?.substring(0, 19)?.replace(' ', 'T')}Z`
}
/* istanbul ignore next */
const returnCurrentDateTimeBasedOnTimezone = (ianaTimezone) => {
  return DateTime.now()
    .setZone(ianaTimezone)
    .toFormat(commonDateTimeFormat('DateAndTime'))
}
/* istanbul ignore next */
const returnCurrentDateTimeSpecificFormatBasedOnTimezone = (
  ianaTimezone,
  requiredFormat
) => {
  return DateTime.now().setZone(ianaTimezone).toFormat(requiredFormat)
}
/* istanbul ignore next */
const returnCurrentDateBasedOnTimezoneWithoutFormatting = (ianaTimezone) => {
  return DateTime.now().setZone(ianaTimezone)?.toISO()
}
/* istanbul ignore next */
const returnCurrentDateTimeInUTC = () => {
  return DateTime.utc().toISO()
}
/* istanbul ignore next */
const convertUtcDateTimeToTimezoneDateTime = (ianaTimezone, dateTimeValue) => {
  return DateTime.fromISO(getDateTimeValueInUtcFormat(dateTimeValue), {
    zone: 'utc',
  })
    .setZone(ianaTimezone)
    .toFormat(commonDateTimeFormat('DateAndTime'))
}
/* istanbul ignore next */
const convertUtcDateTimeToTimezoneDate = (ianaTimezone, dateTimeValue) => {
  return DateTime.fromISO(getDateTimeValueInUtcFormat(dateTimeValue), {
    zone: 'utc',
  })
    .setZone(ianaTimezone)
    .toFormat(commonDateTimeFormat('Date'))
}
/* istanbul ignore next */
const convertUtcDateTimeToTimezoneTime = (ianaTimezone, dateTimeValue) => {
  return DateTime.fromISO(getDateTimeValueInUtcFormat(dateTimeValue), {
    zone: 'utc',
  })
    .setZone(ianaTimezone)
    .toFormat(commonDateTimeFormat('Time24h'))
}
/* istanbul ignore next */
const convertUtcDateTimeToTimezoneCustomFormat = (
  ianaTimezone,
  dateTimeValue,
  formatType
) => {
  return DateTime.fromISO(getDateTimeValueInUtcFormat(dateTimeValue), {
    zone: 'utc',
  })
    .setZone(ianaTimezone)
    .toFormat(formatType)
}
/* istanbul ignore next */
const convertTimezoneDateTimeToUtcDateTime = (ianaTimezone, dateTimeValue) => {
  return DateTime.fromISO(dateTimeValue?.replace(' ', 'T'), {
    zone: ianaTimezone,
  })
    .setZone('utc')
    .toISO()
}
/* istanbul ignore next */
const gridFilterOptions = {
  commonFilterParams: {
    filterOptions: [
      {
        displayKey: 'Equals',
        displayName: 'Equals',
        /* istanbul ignore next */
        predicate: ([fv1], cellValue) => cellValue === fv1,
        numberOfInputs: 1,
      },
    ],
    suppressAndOrCondition: true,
  },
  dateFilterParams: {
    filterOptions: [
      {
        displayKey: 'Equals',
        displayName: 'Equals',
        /* istanbul ignore next */
        predicate: ([fv1], cellValue) =>
          cellValue === dateAndTimeConversion(fv1, true),
        numberOfInputs: 1,
      },
    ],
    suppressAndOrCondition: true,
  },
}
const permissionMessage = {
  Message: 'No access permission or no data found',
}
const dataSaveWarnTitle = 'Cancel Confirmation'
const dataCloseConfirmation = 'Close Confirmation'
const dataSaveWarnMessage = 'Your work is unsaved. Are you sure want to cancel?'
const cancellationReasonWarnMessage =
  'Unsaved data will be lost. Do you want to continue?'
const dataEditTitle = 'Save Confirmation'
const dataEditMessage = 'Are you sure want to make these changes?' // Are you sure to save the changes to this record (based on the story 41933 message title changed, in previous story not mentioned the edit confirmation message in story 40640 )
const deleteConfirmationTitle = 'Delete Confirmation'
const deleteConfirmation = 'Are you sure to delete this entry?'
const messageTitle = 'Message'
const deleteMessage =
  'Please delete the locations associated with this zone to perform this action.'
const rejectTitle = 'Please enter reason'
const infectedVirusMessage =
  'Virus Detected. Preview or download of the original file will be unavailable for safety reasons. Please contact your support team.'
const scanFailed = 'Virus scan failed. Please contact your support team.'
const scanOnProgress =
  'Security Alert: Scanning is incomplete. Preview or download of the original file will be unavailable for safety reasons. Please contact your support team.'
const generateNewRequest = 'Are you sure wish to generate new warrant batch?'
const generateNewRequestDebt =
  'Are you sure wish to generate new debt registration batch?'
const processMessage = 'Are you sure wish to process this Batch?'
const processCancel = 'Are you sure want to cancel the TEC Process?'
const discountApplied = 'Discount already applied'
const discountCannotApply = 'Discount can not be applied for this status'
const representationReview =
  'Are you sure want to complete the client review and mark this representation as '
const representationReviewMoreInfo =
  'Are you sure want to request more information for this representation'
const generateNewRequestBailiff =
  'Are you sure wish to generate a new bailiff export batch?'
const tokenExpiredMessage = 'Authorization Failed: Token Expired'
const constMessageObject = {
  userLogoNotAvailable: 'User Details Not Found',
  noDataFound: 'No Data Found',
}
const isAssociatedWithTask =
  'For the selected case there are other linked workflow items available, do you want to assign those item(s) as well?'
const reAssignUserMessage =
  'Are you sure wish to reassign the selected task(s)?'
const assignUSerMessage = 'Are you sure wish to assign the selected task(s)?'
const completeSuccessMessage = 'Task completed successfully!'
const completedTaskMessage =
  'Are you sure wish to complete the selected task(s)?'
const deleteTaskConfirmMessage = 'Are you sure to delete the workflow task?'
const workFlowAssignMessage = 'Task assigned successfully!'
const workFlowUnassignMessage = 'Task unassigned successfully!'
const workFlowReAssignMessage = 'Task reassigned successfully!'
const inputSaveMessage = 'Your inputs have been saved'
const cancellationWarningMessage =
  'Cancellation reason is already associated with a system process.'
const cancellationReasonDelete =
  'Cancellation reason were deleted successfully.'
const exportDataError = 'Error on Getting Export Data'
const errorOnGettingData = 'Error on getting Data'
const keeperConfirmation =
  'Please note that this case has no Keeper address set so will be excluded from any future print and export batches until one is set manually or new KADOE address added.'
const keeperUpdateConfirmation = 'Do you wish to amend the keeper address?'
const actionButton = {
  popupMessage: {
    challengeConfirm: 'Are you sure want to record the entry of ',
    actionConfirm: 'Are you sure want to record the entry in NPS as ',
    challengeStageValidation: 'Unable to proceed. Please check current stage',
    stageNoChange: 'Stage will not Change',
    statusNoChange: 'Status will not Change',
  },
}
const exceedRows =
  '*You have to export search results as it contains more than 5000 rows'

/* istanbul ignore next */
const isValidEmailAddress = (value) => {
  /* istanbul ignore next */
  // const validMailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const validMailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  /* istanbul ignore next */
  if (!validMailRegEx.test(value)) {
    return false
  }
  return true
}

/* istanbul ignore next */
const isValidCEOBadgeNumber = (ceoBadgeNumber) => {
  let ceoBadgeRegex = new RegExp(`^[a-zA-Z]{2}\\d{5}$`)
  if (!ceoBadgeRegex.test(ceoBadgeNumber)) {
    return false
  }
  return true
}

/* istanbul ignore next */
const isAlphaNumeric = (value, alphaNumericMust = true) => {
  if (!alphaNumericMust) {
    const alphaNumericWithSpace = /^[\w\-\s]+$/
    if (!value.match(alphaNumericWithSpace) || !isNaN(value)) {
      return false
    }
  } else {
    const alphaNumeric = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/
    if (!value.match(alphaNumeric)) {
      return false
    }
  }
  return true
}
/* istanbul ignore next */
const isValidPhoneNumber = (value) => {
  const validPhoneNumberRegEx = /[!_,`~|@#$%^<[{&*=;:}\]>'"?/a-zA-Z\s]/
  if (validPhoneNumberRegEx.test(value)) {
    return false
  }
  return true
}
/* istanbul ignore next */
const csvDownLoadClickHandler = (csvJsonData, fileName) => {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  /* istanbul ignore next */
  const blob = new Blob([csvJsonData], { type: 'text/csv' })
  // Creating an object for downloading url
  /* istanbul ignore next */
  const url = window.URL.createObjectURL(blob)
  // const dataValue =
  //   dayjs(selectedData?.generatedDateTime).format('DD/MM/YYYY') +
  //   ' ' +
  //   dayjs(selectedData?.generatedDateTime).format('HH:mm:ss')
  // Creating an anchor(a) tag of HTML
  /* istanbul ignore next */
  const a = document.createElement('a')
  // Passing the blob downloading url
  /* istanbul ignore next */
  a.setAttribute('href', url)
  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  /* istanbul ignore next */
  a.setAttribute('download', fileName)
  // Performing a download with click
  /* istanbul ignore next */
  a.click()
}
/* istanbul ignore next */
const setDecimalValue = (inputnumber, decimaldigit) => {
  return Number(inputnumber)?.toFixed(decimaldigit)
}
const userRoleCheck = [
  'OrganisationAdmin',
  'SuperAdmin',
  'ContractAdmin',
  'BOManager',
  'QCBOUser',
  'BOUser',
  'NoticeProcessing',
  'Client',
  'ReadOnly',
  'ClientReadOnly',
  'CEOManager',
  'PoundOperator',
  'PoundManager',
]
const userRoleCheckAdmin = ['SuperAdmin']

const defaultHHDAutoLogOut = 120
const minHHDAutoLogOut = 60
const maxHHDAutoLogOut = 1440

const fileDownloaderByURL = (urlName, fileName) => {
  var link = document.createElement('a')
  link.download = fileName
  link.href = urlName
  document.body.appendChild(link)
  link.click()
  setTimeout(function () {
    document.body.removeChild(link)
  }, 50)
}
/* istanbul ignore next */
// used to download the blob file by creating new url with specific fileName instead of directly download from blob
const createObjectUrlAndDownload = async (blobUrl, fileName) => {
  let downloadStatus = 'error'
  let errorData = null
  await axios({
    url: blobUrl,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      /* istanbul ignore next */
      let createdBlobUrl = window.URL.createObjectURL(response?.data)
      /* istanbul ignore next */
      fileDownloaderByURL(createdBlobUrl, fileName)
      /* istanbul ignore next */
      downloadStatus = 'success'

      // Cleanup the temporary URL
      /* istanbul ignore next */
      URL.revokeObjectURL(createdBlobUrl)
    })
    .catch((error) => {
      errorData = error?.message
      downloadStatus = 'error'
    })
  return { downloadStatus: downloadStatus, errorData: errorData }
}
const isValidURL = (value) => {
  // Commenting this due to code quality analysis issue
  // const validRegEx = new RegExp(
  //   '^([a-zA-Z]+:\\/\\/)?' + // protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //     '(\\#[-a-z\\d_]*)?$', // fragment locator
  //   'i'
  // )
  // // const validRegEx = new RegExp(
  // //   '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
  // // )
  // if (!validRegEx.test(value)) {
  //   return false
  // }
  // return true

  try {
    new URL(value)
    return true
  } catch {
    return false
  }
}

const stageTextMap = {
  chargeCertificate: {
    displayText: 'Charge Certificate',
    key: 'ChargeCertificate',
  },
  confirmationNote: {
    displayText: 'Confirmation Note',
    key: 'ConfirmationNote',
  },
  highCourt: { displayText: 'High Court', key: 'HighCourt' },
  informal: { displayText: 'Informal', key: 'Informal' },
  informalNoa: { displayText: 'NOA Informal', key: 'NOAInformal' },
  informalNor: { displayText: 'NOR Informal', key: 'NORInformal' },
  intimereceived: { displayText: 'In Time', key: 'InTime' },
  letter_14Day: { displayText: '14 Day Letter', key: '14DayLetter' },
  letter_28Day: { displayText: '28 Day Letter', key: '28DayLetter' },
  nto: { displayText: 'NTO', key: 'NTO' },
  ofr: { displayText: 'OFR', key: 'OFR' },
  ofrExpired: { displayText: 'OFR Expired', key: 'OfrExpired' },
  outimereceived: { displayText: 'Out of Time', key: 'OutofTime' },
  postedPcn: { displayText: 'Posted PCN', key: 'PostedPCN' },
  representation: { displayText: 'Representation', key: 'Representation' },
  representation_cc: {
    displayText: 'CC Representation',
    key: 'CCRepresentation',
  },
  representationNoa: {
    displayText: 'NOA Representation',
    key: 'NOARepresentation',
  },
  representationNor: {
    displayText: 'NOR Representation',
    key: 'NORRepresentation',
  },
  revokeOrder: { displayText: 'Revoke Order', key: 'RevokeOrder' },
  tribunal: { displayText: 'Tribunal', key: 'Tribunal' },
  tribunalAdjudicator: {
    displayText: 'Tribunal Adjudicator',
    key: 'TribunalAdjudicator',
  },
  tribunalReview: { displayText: 'Tribunal Review', key: 'TribunalReview' },
  warrant: { displayText: 'Warrant', key: 'Warrant' },
  warrantExported: { displayText: 'Warrant Exported', key: 'WarrantExported' },
  warrantReceived: { displayText: 'Warrant Received', key: 'WarrantReceived' },
  warrantRequested: {
    displayText: 'Warrant Requested',
    key: 'WarrantRequested',
  },
  noaWarningNotice: {
    displayText: 'NOA Warning Notice',
    key: 'NOAWarningNotice',
  },
  ofrStage: { displayText: 'OFR', key: 'ofr' },
  liveStage: { displayText: 'Live', key: 'Live' },
  debtStage: { displayText: 'Debt', key: 'Debt' },
  debtRegistration: {
    displayText: 'Debt Registration',
    key: 'DebtRegistration',
  },
  paidCase: {
    newStage: {
      displayText: 'No Change',
      key: 'NoChange',
    },
  },
  atCourt: {
    displayText: 'At Court',
    key: 'atCourt',
  },
  finalWarning: {
    displayText: 'Final Warning',
    key: 'finalWarning',
  },
  courtEligible: {
    displayText: 'Court Eligible',
    key: 'courtEligible',
  },
  tolRepresentationSuccessful: {
    displayText: 'TOL Representation',
    key: 'TOLRepresentation',
  },
}

const statusTextMap = {
  actioned: { displayText: 'Actioned', key: 'Actioned' },
  reverted: { displayText: 'Reverted', key: 'Reverted' },
  prepared: { displayText: 'Prepared', key: 'Prepared' },
  contested: { displayText: 'Contested', key: 'Contested' },
  active: { displayText: 'Active', key: 'Active' },
  cancelled: { displayText: 'Cancelled', key: 'Cancelled' },
  onHold: { displayText: 'On hold', key: 'Onhold' },
  lost: { displayText: 'Lost', key: 'Lost' },
  rejected: { displayText: 'Rejected', key: 'Rejected' },
  successful: { displayText: 'Successful', key: 'Successful' },
  unsuccessful: { displayText: 'Unsuccessful', key: 'Unsuccessful' },
  paid: { displayText: 'Paid', key: 'Paid' },
  infoRequested: { displayText: 'Info Requested', key: 'InfoRequested' },
  clientReviewed: { displayText: 'Client Reviewed', key: 'ClientReviewed' },
  discountStatus: { displayText: 'Discount', key: 'discount' },
  paidCase: {
    newStatus: {
      displayText: 'Paid',
      key: 'Paid',
    },
  },
  requested: { displayText: 'Requested', key: 'Requested' },
}
const onHoldOffHoldPCN = {
  offHoldPCNMessage:
    'Are you sure wish to take off hold and revert to previous status?',
  indefinite: 'Indefinite',
  timeLimited: 'Time Limited',
  holdPcn: 'Hold PCN',
  expiryDateTimeErrorMessage: 'Please select a valid expiry date and time',
}
const sourceTextMap = {
  ocrError: { displayText: 'OCRError', key: 'ocrerror' },
}
const challengeAction = {
  reCloseFollowingPaidCorrespondenceTitle:
    'Re-Close following Paid Correspondence',
}
const applicationInactiveTimeLapse = 1200000 //20 mins
const refreshTokenMinutesEarlier = 10 //5 mins
const toastAutoHideDuration = 30000

const messageDisplayItems = [
  {
    id: 1,
    name: 'All',
  },
  {
    id: 2,
    name: 'Current',
  },
  {
    id: 3,
    name: 'Future',
  },
  {
    id: 4,
    name: 'Expired',
  },
]

const bailiffMessages = {
  errorSplitMessage:
    'Split of job among the Bailiff does not cover 100%. Please recheck',
  errorShouldNotExceed: 'Bailiff Job Split should total to 100%',
  deleteWarningMessage: 'Are you sure want to remove this entry?',
  errorMaxRecord:
    'To add maximum of 10 Partner ID and Bailiff entries per contract.',
  errorUnique: 'Bailiff must be unique.',
  saveSuccessMessage: 'Bailiff record has been added',
  updateSuccessMessage: 'Bailiff record has been updated',
  deleteMessage: 'Bailiff Record has been deleted.',
  dataEditMessage: 'Are you sure wish to update this record?',

  saveEmptyValidation: 'Please complete data input before saving this entry',
  updateEmptyValidation:
    'Please complete data input before updating this entry',
}

const reallocationAttachment = {
  title: 'Reallocate Attachment',
  popupType: {
    reallocation: 'reallocate',
    reallocateCancel: 'reallocateCancel',
  },
  reallocationAttachmentConfirmation:
    'Are you sure wish to reassign the attachment(s)?',
  reacllocationAttachmentSuccessMessage:
    'Attachments reallocated successfully!',
  reallocationAttachmentCancel: 'Are you sure to cancel this request?',
}
// const toastSuccessStatus = 'success'

const repReviewActionStatus = {
  accept: 'Representation marked as accepted',
  reject: 'Representation marked as rejected',
  moreInfo: 'More info requested for',
  failedStatus: 'Representation review not updated',

  acceptKey: 'accepted',
  rejectKey: 'rejected',
  moreInfoKey: 'moreinfo',
  acceptDisplayName: 'Accepted',
  rejectDisplayName: 'Rejected',
  moreInfoDisplayName: 'MoreInfo',
}
const templateData = {
  initialText: 'Design',
  headerText: 'Header',
  footerText: 'Footer',
  hhdNoticeTemplate: {
    tabName: 'hhdNoticeTemplate',
    designTypeText: 'PCN',
    tabTitle: 'HHD Notice',
    templateType: ['pcn', 'warningpcn'],
    fpnNoticeTemplateType: ['fpnnotice'],
    fpnDesignTemplate: ['statdoc', 'e-mail', 'letter'],
    activationWarningText:
      'a contract cannot have more than 1 active pcn template',
    possibleTemplateTypeMessage: 'PCN / Warning PCN',
    successInsertMessage: 'HHD Notice Template added successfully',
    successUpdateMessage: 'HHD Notice Template updated successfully',
  },
  pcnTemplateActivationWarning:
    'Are you sure wish to __activation__ the __templateType__ template?',
  templateNameLength: 100,
  fpnNotificationTemplate: [
    'alertemail',
    'casesyncfailure',
    'correspondenceapprove',
    'ctportalchallenge',
    'malwarescanvirusinfected',
    'newusercreation',
    'pcnprefixalert',
    'printfailure',
    'printnoresponse',
  ],
}
const contractData = {
  message: {
    invalidEmail: 'Please enter valid email address.',
  },
}
/* istanbul ignore next */
const integrationServices = {
  fieldName: {
    isActiveDaysEnabled: 'isActiveDaysEnabled',
    isKadoeEnabled: 'isKadoeEnabled',
    isPrintEnabled: 'isPrintEnabled',
    isScanEnabled: 'isScanEnabled',
    isCctvEnabled: 'isCctvEnabled',
    isBailiffEnabled: 'isBailiffEnabled',
  },
  title: {
    isActiveDaysEnabled: 'Active days',
    isKadoeEnabled: 'KADOE',
    isPrintEnabled: 'Print',
    isScanEnabled: 'Scan',
    isCctvEnabled: 'CCTV',
    isBailiffEnabled: 'Bailiff',
  },
  description: {
    isActiveDaysEnabled:
      'Disabling this active days will disable the entire progression of cases.',
    isKadoeEnabled:
      'Disabling this service will stop VQ4 generation and VQ5 import.',
    isPrintEnabled:
      'Disabling this service will not generate any scheduled print batches.',
    isScanEnabled:
      'Disabling this service will not import files from Scan and Parseq.',
    isCctvEnabled:
      'Disabling this service will not import case files form 3rd party CCTV.',
    isBailiffEnabled:
      'Disabling this service will not generate Bailiff XML file and will not import Bailiff message file.',
  },
  confirmMessage: {
    isActiveDaysEnabled:
      'Disabling active days will disable all other services',
    /* istanbul ignore next */
    commonService: (toggleValue, serviceName) =>
      `Are you sure want to ${
        toggleValue ? 'enable' : 'disable'
      } the ${serviceName} ?`,
  },
  successMessage: 'Integration Entry updated successfully',
}

const brandingMenu = {
  configuration: {
    field: 'configuration',
    recType: 'configuration',
    title: 'Configuration',
    stage: 0,
  }, //if API following different field name - need to add that too in this object - apiField : 'Configuration'
  attachments: {
    field: 'attachments',
    recType: 'attachments',
    title: 'Attachments',
    stage: 1,
  },
  headerSetting: {
    field: 'headerSetting',
    recType: 'header',
    title: 'Header Settings',
    stage: 1,
  },
  footerSetting: {
    field: 'footerSetting',
    recType: 'footer',
    title: 'Footer Settings',
    stage: 2,
  },
  headerCode: {
    field: 'headerSetting',
    recType: 'header',
    title: 'Header Code',
    stage: 2,
  },
  footerCode: {
    field: 'footerSetting',
    recType: 'footer',
    title: 'Footer Code',
    stage: 3,
  },
  launchSummaryBasic: {
    field: 'launchSummaryBasic',
    recType: 'launchSummaryBasic',
    title: 'Launch Summary',
    stage: 3,
  },
  launchSummaryAdvanced: {
    field: 'launchSummaryAdvance',
    recType: 'launchSummaryAdvance',
    title: 'Launch Summary',
    stage: 4,
  },
}

const persistantEvaderLabel = {
  labelName: 'Persistent Evader',
}

const branding = {
  brandingTypeTitle: {
    basic: { id: 'basic', displayText: 'Basic' },
    advanced: { id: 'advanced', displayText: 'Advanced' },
  },
  brandingSetupMenuDataSource: {
    basic: [
      { ...brandingMenu?.configuration },
      { ...brandingMenu?.headerSetting },
      { ...brandingMenu?.footerSetting },
      { ...brandingMenu?.launchSummaryBasic },
    ],
    advanced: [
      { ...brandingMenu?.configuration },
      { ...brandingMenu?.attachments },
      { ...brandingMenu?.headerCode },
      { ...brandingMenu?.footerCode },
      { ...brandingMenu?.launchSummaryAdvanced },
    ],
  },
  backgroundOptions: [
    {
      name: 'Image',
      value: 'image',
    },
    {
      name: 'Colour',
      value: 'colour',
    },
  ],
  buttonMenuDataSource: {
    button: {
      id: 'newBranding',
      fieldValue: 'New Branding',
      fieldLabel: 'New Branding',
    },
    menu: { id: 'optionsMenu', dataTestId: 'brandingOptions' },
    menuItems: [{ label: 'Basic' }, { label: 'Advanced' }],
  },
  success: {
    title: 'Success',
    message: 'Branding published successfully',
  },
  error: {
    fileTooBig: 'Error! File size too big, maximum allowed is 1mb',
    maxFilesReached:
      'Max files allowed is 10, please review before clicking submit',
    invalidFormat:
      'Error! File type is not permitted, please attach a document of the following type(s) only .png, .jpg, .svg',
  },
  attachmentCopied: 'Copied to clipboard',
  limit: { attachmentCount: 10, attachmentSize: 1 },
  makeDefault: 'Select a default URL',
  validDomainURL: 'Please enter valid URL',
  duplicateDomainURL: 'Duplicate domain URL is not allowed',
  placeholder: 'Enter URL',
}

const localPrintingMessage = {
  noFilesToPrint: 'No file to print',
  noFilesToDownload: 'No file to download',
  printSuccess: 'print batch sent to printer successfully',
  downloadSuccess: 'print batch downloaded successfully',
  statusPartConfirmed: 'Part Confirmed',
  statusUpdateError: 'Status update failed.',
  localPrintingStatus: {
    new: 'New',
    sent: 'Sent',
    confirm: 'Confirmed',
  },
}

const invalidGPS = 'Invalid GPS Values'
const toastStatus = {
  error: 'error',
  success: 'success',
  warning: 'warning',
}
const confirmWindowMessage =
  'Please save your data before switching to another case.'
const allowedFileLimit = 'Max allowed is 10 files, please review'
const newGenerateRequestMessage = 'New Request Generated'
const newGenerateRequestMessageFalse = 'New Request Not Generated'
const domainSettings = 'Domain Settings'
const noRecordsFound = 'No Records Found'
const configurationStep1 = 'Step 1 - Configuration'
const txtbutton = 'Button'
const brandingContractHeading = 'Contract Settings'

/**
 * Function to get blob detail
 * @param {*} blobName - blobName with extension
 * @param {*} requiredDetail - name or extension
 * @returns {string} - name / extension based on requiredDetail
 */
/* istanbul ignore next */
const getBlobDetail = (blobName, requiredDetail) => {
  const extensionIndex = blobName?.toString()?.lastIndexOf('.')
  if (requiredDetail === 'name')
    return blobName?.toString()?.slice(0, extensionIndex)
  else if (requiredDetail === 'extension')
    return blobName?.toString()?.slice(extensionIndex)
  return ''
}
// const escapeRegExp = (str) => {
//   return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1')
// }
// const replaceAll = (str, find, replace) => {
//   return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
// }
// const convertSvgTextToCssString = (text) => {
//   var encoded = text?.replace(/\s+/g, ' ')
//   encoded = replaceAll(encoded, '%', '%25')
//   encoded = replaceAll(encoded, '> <', '><') // normalise spaces elements
//   encoded = replaceAll(encoded, '; }', ';}') // normalise spaces css
//   encoded = replaceAll(encoded, '<', '%3c')
//   encoded = replaceAll(encoded, '>', '%3e')
//   encoded = replaceAll(encoded, '"', "'")
//   encoded = replaceAll(encoded, '#', '%23') // needed for ie and firefox
//   encoded = replaceAll(encoded, '{', '%7b')
//   encoded = replaceAll(encoded, '}', '%7d')
//   encoded = replaceAll(encoded, '|', '%7c')
//   encoded = replaceAll(encoded, '^', '%5e')
//   encoded = replaceAll(encoded, '`', '%60')
//   encoded = replaceAll(encoded, '@', '%40')
//   var uri = 'url("data:image/svg+xml;charset=UTF-8,' + encoded + '")'
//   return uri
// }

/**
 * Converts SVG text to a CSS string.
 * @param {string} text - The SVG text to be converted.
 * @returns {string} - The converted CSS string.
 */
const convertSvgTextToCssString = (text) => {
  // Normalize whitespace and escape special characters
  let encoded = text
    ?.replace(/\s+/g, ' ')
    .replace(/%/g, '%25')
    .replace(/> <|; }|</g, (match) => {
      switch (match) {
        case '> <':
          return '><'
        case '; }':
          return ';}'
        case '<':
          return '%3c'
        default:
          return match
      }
    })
    .replace(/>|"|#|{|}|[|^|`|@]/g, (match) => {
      const replacements = {
        '>': '%3e',
        '"': "'",
        '#': '%23',
        '{': '%7b',
        '}': '%7d',
        '|': '%7c',
        '^': '%5e',
        '`': '%60',
        '@': '%40',
      }
      return replacements[match] || match
    })

  return `url("data:image/svg+xml;charset=UTF-8,${encoded}")`
}

/**
 *
 * @param {*} url - get svg text from url
 * @returns {string} - returns the svg text
 */

/* istanbul ignore next */
const getTextOfSvgImageUrl = async (url) => {
  const svgText = await axios({
    url: url,
    method: 'GET',
    responseType: 'text',
  })?.then((response) => {
    return response?.data
  })
  return svgText
}

/**
 * Loads css replaced string of svg tag text
 * @param {*} url - get svg text from url and convert to css string
 * @returns {string} - returns the css string
 */

/* istanbul ignore next */
const loadCssStringOfSvgImage = async (url) => {
  const svgText = await getTextOfSvgImageUrl(url)
  const cssString = convertSvgTextToCssString(svgText)
  return cssString
}

/**
 * sets background image to the applied dom with the given image url
 * @param {*} appliedSection
 * @param {*} bgImage
 */
/* istanbul ignore next */
const setBackgroundImage = async (appliedSection = null, bgImage = null) => {
  if (bgImage && appliedSection) {
    let backgroundImageUrl = `url(${bgImage})`
    const mediaExtension = getBlobDetail(bgImage, 'extension')
    if (mediaExtension.includes('svg')) {
      backgroundImageUrl = await loadCssStringOfSvgImage(bgImage)
    }
    document.getElementsByClassName(appliedSection)[0].style.backgroundImage =
      backgroundImageUrl
  } else {
    document.getElementsByClassName(appliedSection)[0].style.backgroundImage =
      null
  }
}

/* istanbul ignore next */
const setBackground = (appliedSection = null, bgData = null) => {
  /* istanbul ignore next */
  if (bgData) {
    document.getElementsByClassName(appliedSection)[0].style.backgroundColor =
      bgData
  } else {
    /* istanbul ignore next */
    document.getElementsByClassName(appliedSection)[0].style.backgroundColor =
      null
  }
}
/* istanbul ignore next */
const setBgImageProperty = (appliedSection) => {
  if (appliedSection) {
    document.getElementsByClassName(appliedSection)[0].style.backgroundRepeat =
      'no-repeat'
    // document.getElementsByClassName(appliedSection)[0].style.backgroundSize =
    //   'cover'
  }
}
/* istanbul ignore next */
const applyPreviewData = (appliedSection, appliedData) => {
  if (document.getElementsByClassName(appliedSection)?.length > 0) {
    if (!appliedData?.isBackgroundImage) {
      setBackgroundImage(appliedSection, null)
      setBackground(appliedSection, appliedData?.backgroundColor)
    } else if (appliedData?.isBackgroundImage) {
      setBackgroundImage(appliedSection, appliedData?.backgroundFileLocation)
      setBackground(appliedSection, null)
      setBgImageProperty(appliedSection)
    } else {
      setBackgroundImage(appliedSection, null)
      setBackground(appliedSection, null)
    }
  }
}

const tribunalActionEnabledStages = [
  'NTO',
  'PostedPCN',
  'Tribunal',
  'TribunalReview',
  'HighCourt',
  'TribunalAdjudicator',
  'OFR',
  'OfrExpired',
  'ConfirmationNote',
  'RevokeOrder',
  '14DayLetter',
  '28DayLetter',
  'InTime',
  'OutofTime',
  'WarrantRequested',
  'WarrantReceived',
  'WarrantExported',
  'ChargeCertificate',
  // 'WarrantEligible',
]
const locationNameCheck = 'Location name already exist'
const uRLCheck = 'URL already exist'
const domainURLCheck = 'Duplicate domain URL is not allowed'
const SuccessMsgs = {
  locationAdd: 'Location has been added successfully.',
  locationEdit: 'Updated Successfully',
}
const mediaExtensions = {
  image: {
    tiff: ['tif', 'tiff'],
    imageTagSupports: ['jpg', 'jpeg', 'jfif', 'png', 'bmp', 'svg'],
    heic: ['heic'],
    videoTagSupports: ['mp4', 'ogg', 'webmp'],
    iframeTagSupports: ['xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx', 'txt'],
    video: 'video',
  },
}
const fileSequenceNoHelpText =
  'This field value is non-editable, please contact support team to change it.'

const searchFieldDataSource = [
  {
    apiFieldName: 'companyName',
    displayName: 'Company Name',
    controlType: 'textBox',
    sentToAPI: false,
    id: 'companyName',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'postCode',
    displayName: 'Post Code',
    controlType: 'textBox',
    sentToAPI: false,
    id: 'postCode',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'surname',
    displayName: 'Surname',
    controlType: 'textBox',
    sentToAPI: false,
    id: 'surname',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'sourceType',
    displayName: 'Source Type',
    controlType: 'list',
    sentToAPI: true,
    id: 'caseSourceTypeId',
    contractType: [contractTypes?.pcnName],
  },
  {
    apiFieldName: 'serviceMethod',
    displayName: 'Service Method',
    controlType: 'list',
    sentToAPI: true,
    id: 'issuedTypeId',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'stage',
    displayName: 'Stage',
    controlType: 'list',
    sentToAPI: true,
    id: 'stageId',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'status',
    displayName: 'Status',
    controlType: 'list',
    sentToAPI: true,
    id: 'statusId',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'location',
    displayName: 'Location',
    controlType: 'list',
    sentToAPI: true,
    id: 'locationId',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'createdOn',
    displayName: 'Created On',
    controlType: 'datePicker',
    sentToAPI: false,
    id: 'createdOn',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'contraventionDate',
    displayName: 'Contravention Date',
    controlType: 'datePicker',
    sentToAPI: false,
    id: 'contraventionDate',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'contraventionDescription',
    displayName: 'Contravention Description',
    controlType: 'list',
    sentToAPI: true,
    id: 'contraventionId',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  {
    apiFieldName: 'vehicleColour',
    displayName: 'Vehicle Colour',
    controlType: 'list',
    sentToAPI: true,
    id: 'vehicleColorId',
    contractType: [contractTypes?.pcnName],
  },
  {
    apiFieldName: 'vehicleMake',
    displayName: 'Vehicle Make',
    controlType: 'list',
    sentToAPI: true,
    id: 'vehicleMakeId',
    contractType: [contractTypes?.pcnName],
  },
  {
    apiFieldName: 'vrmNo',
    displayName: 'VRM No',
    controlType: 'textBox',
    sentToAPI: false,
    id: 'vrmNo',
    contractType: [contractTypes?.pcnName],
  },
  {
    apiFieldName: 'warningFlag',
    displayName: 'Warning Flag',
    controlType: 'list',
    sentToAPI: false,
    id: 'warningFlag',
    contractType: [contractTypes?.pcnName],
  },
  {
    apiFieldName: 'CEOId',
    displayName: 'CEO Id',
    controlType: 'textBox',
    sentToAPI: false,
    id: 'CEOId',
    contractType: [contractTypes?.pcnName, contractTypes?.fpnName],
  },
  // {
  //   apiFieldName: 'amountPaid',
  //   displayName: 'Amount Paid',
  //   controlType: 'number',
  //   sentToAPI: false,
  //   id: 'amountPaid',
  // },
  // {
  //   apiFieldName: 'balance',
  //   displayName: 'Balance',
  //   controlType: 'number',
  //   sentToAPI: false,
  //   id: 'balance',
  // },
]
/* istanbul ignore next */
const sampleFileZone = 'SampleFileZone'

const getLabel = (label, mandatory, disabled) => {
  return (
    <div
      className={` ${'inputLabel'} ${
        disabled ? 'contract_InputLabel_Disabled' : 'contract_InputLabel'
      }`}
    >
      <span>
        {label}
        {mandatory ? <span className="mandatorySymbol">{` *`}</span> : null}
      </span>
    </div>
  )
}
const brandingDeleteSuccess = 'Branding deleted successfully.'
/* istanbul ignore next */
const getFileSizeInKb = (fileSize) => {
  return Number(fileSize) / 1024 / 1024
}

/* istanbul ignore next */
const customTooltipThemeData = {
  branding: {
    tooltip: {
      marginRight: '85px !important',
    },
  },
}

const defaultButtonBGColor = '#1f3c88'
const defaultButtonTextColor = '#FFFFFF'

const acceptPartPayment =
  'Are you sure wish to write off the remaining balance?'
const updatedSuccessfully = 'Updated Successfully'

const roleList = {
  superAdmin: 'superadmin',
  orgAdmin: 'organisationadmin',
  ceo: 'ceo',
}
const debtReviewCancelCases = {
  cancelSuccessMessage: 'Debt review completed',
  permittedRole: ['noticeprocessing', 'bomanager', 'superadmin', 'client'],
}
const liveTrackingTimelineTabPermittedRoles = [
  'bomanager',
  'ceomanager',
  'superadmin',
  'contractadmin',
  'organisationadmin',
]
const discountButtonValue = {
  discount: {
    title: 'Discount Pre NtO Challenge Reject',
    subTitle: null,
    description:
      'Informal challenge reject actioned and discount period reset for 14 days',
  },
  discountRepReject: {
    title: 'Discount Rep Reject',
    subTitle: null,
    description:
      'Representation reject actioned and discount period re-offered for 14 days. After which then 14 days later will progress to Charge Certificate',
  },
  discountCCRepReject: {
    title: 'Discount CC Rep Reject',
    subTitle: null,
    description:
      'Late Representation following the issue of a Charge Certificate reject actioned and discount period re-offered for 14 days. After which 14 days later will progress to eligible for Debt Registration',
  },
  discountOFRRepReject: {
    title: 'Discount OFR Rep Reject',
    subTitle: null,
    description:
      'Late Representation following the issue of an Order for Recovery reject actioned and discount period re-offered for 14 days. After which 14 days later will progress to eligible for Warrant Request',
  },
  reverttoIssuedDiscount: {
    title: 'Revert to Issued (Discount)',
    description: 'Revert to issued/discount',
    customPopupTitle:
      'Are you sure wish to revert the case to issued stage at the discount charge',
  },
}

const isShowPreviouslyIssuedPcnValues = {
  enableOption:
    "Enabling this feature will show the CEOs with the details of previously issued PCN's for that vehicle for that day.",
  disableOption:
    "Disabling this feature will not show the CEOs with the details of previously issued PCN's for that vehicle for that day.",
}

const toggleHorizontalLine = 'HorizontalLine'

const contractSetupToggleOptions = [
  {
    optionLabel: 'Client Workflow',
    optionValue: 'clientWorkFlow',
    optionDataTestId: 'clientWorkFlow',
  },
  {
    optionLabel: 'International Debt Recovery',
    optionValue: 'internationalDebtRecovery',
    optionDataTestId: 'internationalDebtRecovery',
  },
  {
    optionLabel: 'Show FAQ in Citizen Portal',
    optionValue: 'isShowFAQCitizen',
    optionDataTestId: 'ShowFAQCitizenSwitch',
  },
  {
    optionLabel: 'Is Active',
    optionValue: 'isActive',
    optionDataTestId: 'ActiveSwitch',
  },
  {
    optionLabel: 'TRO/TMO Map',
    optionValue: 'troTmoMap',
    optionDataTestId: 'TroTmoMapSwitch',
  },
  {
    optionLabel: 'Show previously issued PCN in HHD',
    optionValue: 'isShowPreviouslyIssuedPCN',
    optionDataTestId: 'IsShowPreviouslyIssuedPCN',
  },
  {
    optionLabel: 'Correspondence PIN',
    optionValue: 'isCorrespondencePin',
    optionDataTestId: 'correspondencePinSwitch',
  },
  {
    optionLabel: 'HHD Illumin8',
    optionValue: 'isHHDIllumini8',
    optionDataTestId: 'HHDIllumin8',
  },
  {
    optionLabel: 'Officer Tracking',
    optionValue: 'isOfficerTracking',
    optionDataTestId: 'officerTracking',
  },
  {
    optionLabel: 'HorizontalLine',
    optionValue: 'HorizontalLine',
    optionDataTestId: 'HorizontalLine',
  },
  {
    optionLabel: 'Print Local',
    optionValue: 'isPrintLocal',
    optionDataTestId: 'printLocalId',
  },
  {
    optionLabel: 'Print Export',
    optionValue: 'isPrintExport',
    optionDataTestId: 'printExportId',
  },
  {
    optionLabel: 'Manual Case Creation',
    optionValue: 'isManualCaseEnabled',
    optionDataTestId: 'isManualCaseEnabled',
  },
]
const clampAndRemovalSource = {
  optionLabel: 'Clamp & Removal',
  optionValue: 'isCandREnabled',
  optionDataTestId: 'isCandREnabled',
}
const contractDisablingToggles = {
  cAndREnabled: 'isCandREnabled',
  clientWorkFlow: 'clientWorkFlow',
}
const userPermissionMessage = {
  accountPermissionAction:
    'Please contact your supervisor to configure your account.',
  accountInactive: 'Account inactive',
  insufficientPermission: 'Insufficient Permissions',
  invalidUser: 'Invalid User',
  poundManagerLowerCase: 'pound manager',
  poundOperatorLowerCase: 'pound operator',
  onBoardCeoLowerCase: 'onboard ceo',
  mobileCeoLowerCase: 'mobile ceo',
}
const attachmentDropZoneStaticData = {
  attachmentDropZoneError:
    'Error! File type is not permitted, please attach a document of the following type(s) only .png, .pdf, .jpg, .bmp, .tif, .heic',
  attachmentCSVError: 'CSV Format does not match',
  fileUploadValidatingContent: 'Please wait while we are validating CSV...',
  fileUploadSuccessContent: 'Please wait... Uploading in progress.',
  fileUploadSkipContentData:
    'Do you wish to skip the error data and upload the CSV?',
  fileUploadSaveAlertMessage: 'CSV Uploaded Successfully',
}
const attachmentInvalidErrors = {
  bulkUpdateError:
    'Error! File type is not permitted, please attach a document of the following type(s) only .png .pdf .jpg .bmp .tif .heic .doc',
  attachmentTabError:
    'Error! File type is not permitted, please attach a document of the following type(s) only .png, .pdf, .jpg, .bmp, .tif, .heic',
  fileFormatError: 'Error! File type is not permitted.',
  scanImportError:
    'Error! File type is not permitted, please attach a document of the following type(s) only .png .jpg  .tif .pdf',
}
const bulkUpdateMessages = {
  bulkUpdateCancelWarning: 'Are you sure wish to cancel bulk batch details ?',
  duplicateWarning:
    'duplicate case numbers have been identified, which would be skipped.',
  acceptedFileFormats: `(Accepted file type: .png .pdf .jpg .bmp .tif .heic) |
                  Max.Files:10 | Max.Size:10 mb per file`,
  maxAllowedFiles: 'Maximum allowed files are 10, please review',
  maxAllowedSizeForAttachments:
    'Error! File size too big, maximum allowed is 10mb',
  attachmentfilecount: 'Attachments file count : ',
  bulkReportSubmitted: 'Bulk update successfully submitted,',
  bulkReportCommitApproval: 'Awaiting for approval.',
  bulkReportCommitConfirmation:
    'Are you sure want to commit this Bulk Update ?',
  bulkReportCommitHelpText:
    'Validation set for stage and status as per the PCN service method',
}
const fileUploadStateValue = {
  maxAllowedCaseNumbers: 1000,
  caseNumberDuplicate: 'Case already exists. Try again with another number',
  caseNumberCountValidate: 'Case number exceeding the limit 1000',
  fieldValidationError: "CSV format doesn't match",
  csvFileValidationByAPI: 'Please wait while we are validating CSV...',
  skipCSVFileError: 'Do you wish to skip the error data and upload the CSV ?',
  csvFileUploadAPI: 'Uploading...',
}
const maxNotesLength = 60000
const maximumCharacterLimitReached = 'Maximum character limit is reached'
const placeHolderText = {
  enterNotes: 'Enter Notes',
}
/* istanbul ignore next */
const holdReasonStaticData = {
  contentMessageAdd: 'Hold Reason added successfully!',
  contentMessageEdit: 'Hold Reason updated successfully!',
  fromAddMode: 'add',
  fromEditMode: 'edit',
  headerTitle: 'Add Hold Reason',
  btnTextSave: 'Add',
  btnTextCancel: 'Cancel',
  headerEditTitle: 'Edit Hold Reason',
  btnUpdate: 'Update',
  orgInputAlert: 'Please select organisation',
  postMethode: 'POST',
  putMethode: 'PUT',
  holdReasonInputAlert: 'Should not exceed more than 200 character',
  deletedMessage: 'Hold Reason deleted successfully!',
  deletedConfirmation: 'Are you sure want to delete?',
}
/* istanbul ignore next */
const cancellationReasonFileUploadPage = {
  sampleFileCancelreason: 'cancelreasonsample',
  skippedDataCancelreason: 'cancelreason',
  crConfigurationCancellationReason: 'cancelreasonsample',
  crConfigurationSampleDownloadName: 'cancellationreasonsample',
  crConfigurationSkippedDownloadName: 'cancellationreason',
}

const officerTrackingMessage = {
  liveLocationURL: 'https://development-mobile-restapi.azurewebsites.net/hub',
  locationNameError: 'Error on getting GEO Location name',
  noCEOLocation: 'Selected CEO not in live state.',
}
/* istanbul ignore next */
const RemoveBase64FileType = (base64String) => {
  return base64String?.substring(
    base64String?.indexOf(',') + 1,
    base64String?.length
  )
}

/* getFileData from blobURL */
/* istanbul ignore next */
const getBase64FromBlobURL = async (params) => {
  const base64BlobData = await axios({
    url: params,
    method: 'GET',
    responseType: 'blob',
  })

  if (base64BlobData) {
    if (base64BlobData?.status === apiResponseCode?.successCode) {
      const reader = new FileReader()
      reader.readAsDataURL(base64BlobData?.data)
      return (reader.onloadend = () => {
        const base64data = reader?.result
        return decode(RemoveBase64FileType(base64data))
      })
    }
  }
}

/* istanbul ignore next */
const tooltipTheme = (customTooltipValue) =>
  createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          arrow: () => ({
            color: 'var(--tooltipBackgroundColor) !important',
          }),
          tooltip: {
            fontFamily: 'var(--default-FontName) !important',
            fontSize: 'var(--tooltipFontSize) !important',
            fontWeight: 'var(--default-FontWeight) !important',
            color: 'var(--tooltipFontColor) !important',
            backgroundColor: 'var(--tooltipBackgroundColor) !important',
            margin: '0px !important',
            marginBottom: '2px !important',
            borderRadius: 'var(--tooltipBorderRadius) !important',
            ...customTooltipValue,
          },
        },
      },
    },
  })
/* istanbul ignore next */
const locationFileUploadPage = {
  sampleFileLocation: 'locationsample',
  skippedDataLocation: 'Location',
}
const xmlFileDownLoadClickHandler = (csvJsonData, fileName) => {
  const blob = new Blob([csvJsonData], { type: 'application/xml' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', fileName)
  a.click()
}
const dvlaXMLFileDownloadSuccess = 'Batch downloaded successfully!'
const noValuesChanged = 'No values changed'

const emptyFunction = () => {}

const inputFieldNameListLoweredCase = {
  contractName: 'contractName',
  addFaultName: 'addfaultName',
  offlineCaseCount: 'offlineCaseCount',
  emailAddress: 'emailAddress',
  contractSenderEmail: 'contractSenderEmail',
  recipientMail: 'recipientMail',
  troTmoMapPath: 'trotmomappath',
  kadoeReference: 'kadoeReference',
  hhdAutoLogout: 'hhdAutoLogout',
  skipDays: 'skipDays',
  citizenPortalDomain: 'citizenPortalDomain',
}
const contractSetupFieldErrors = {
  faultNameError: 'Please enter the Fault Name',
  offlineCaseCount: 'Minimum count should be 10 and maximum can be 500.',
  validTROMap: 'Enter valid TRO/TMO map URL',
  kadoeReferenceError: 'Please enter alpha numeric values',
  CTDomainError: 'Enter valid CT Domain',
  noValuesChanges: 'No values changed.',
  taskMaxAllowedCharacters: 'Maximum 50 characters only allowed ',
  duplicateValues: 'Duplicate category is not allowed',
}
const modes = {
  newMode: 'new',
  editMode: 'edit',
}

const caseSettingsPage = {
  optionDataSource: {
    yes: 'Yes',
    no: 'No',
  },
  iscancel: 'iscancel',
  isupdate: 'isupdate',
  true: 'true',
  false: 'false',
  stage: 'stage',
  reasonForCancellation: 'reasonForCancellation',
  fileCount: 10,
  notes: 'notes',
  notesHeadding: 'Notes',
  attachmentsHeading: 'Attachments',
  cancelHeading: 'Cancel',
  updateHeading: 'Update',
}
/* istanbul ignore next */
const getBase64StringDetail = (base64String, required) => {
  let requiredData = required?.toString()?.toLowerCase()
  if (requiredData === 'base64string')
    return base64String?.substring(
      base64String?.indexOf(',') + 1,
      base64String?.length
    )
  if (requiredData === 'filetype')
    return base64String?.substring(0, base64String?.indexOf(','))
}
const apiMethods = {
  post: 'POST',
  put: 'PUT',
  get: 'GET',
}

const purgePage = {
  purgeHeading: 'Purge',
  add: 'add',
  edit: 'edit',
  contract: 'contract',
  organisation: 'organisation',
  deleteConfirmationHeading: 'Delete Confirmation',
  confirmationMessage: 'Are you sure to delete this purge rule?',
  headerTitle: 'Add New Purge Rule',
  editHeaderTitle: 'Edit Purge Rule',
  pleaseSelectOrganisation: 'Please select organisation',
  yearsCount: 'yearscount',
  minYearsCount: 2,
  maxYearsCount: 6,
  placeHolderYearsCount: 'Select years',
  maxYearsCountWarrningMessage: 'Maximum value is 6',
  minYearsCountWarrningMessage: 'Minimum value is 2',
  noValuesChanged: 'No values changed.',
  saveSuccessMessage: 'Purge rule added successfully!',
  updateSuccessMessage: 'Purge rule updated successfully!',
  deleteSuccessMessage: 'Purge rule deleted successfully!',
  buttonTextField: {
    addNewRule: 'Add New Rule',
    delete: 'Confirm',
    cancel: 'Cancel',
    save: 'Save',
    update: 'Update',
    confirmation: 'Confirm',
    edit: 'Edit',
  },
  purgeConditionsSection: {
    purgeConditions: 'Purge Conditions',
    setYearsCountSinceCase: 'Set years count since case',
    closed: ' closed',
    purgePersonalInformation: 'Purge personal information',
    purgeCaseAttachments: 'Purge case attachments',
    purgeCaseNotes: 'Purge case notes',
    purgeIncludesNonUKVRM: 'Purge includes Non-UK VRM',
  },
}

const revertedType = {
  ntoReverted: {
    displayText: 'NTO/Reverted',
    key: 'NTO/Reverted',
  },
  postedPCNReverted: {
    displayText: 'PostedPCN/Reverted',
    key: 'PostedPCN/Reverted',
  },
  ntoActioned: {
    displayText: 'NTO/Actioned',
    key: 'NTO/Actioned',
  },
  postedPCNActioned: {
    displayText: 'PostedPCN/Actioned',
    key: 'PostedPCN/Actioned',
  },
  revertIssued: {
    displayText: 'RevertToIssued',
    key: 'RevertToIssued',
  },
  revertToCourtEligible: {
    displayText: 'Revert to Court Eligible',
    key: 'RevertToCourtEligible',
  },
  reverttoAtCourt: {
    displayText: 'Revert to At Court',
    key: 'RevertToAtCourt',
  },
  revertToFinalWarningSent: {
    displayText: 'Revert to Final Warning Sent',
    key: 'RevertToFinalWarningSent',
  },
  revertToSelectForFinalWarning: {
    displayText: 'Revert to select for Final Warning',
    key: 'RevertToSelectForFinalWarning',
  },
}
/* istanbul ignore next */
const signOutTheUser = (
  msalInstance,
  config,
  authenticationResult,
  msalPolicyName
) => {
  // can select which account application should sign out
  let homeAccountId = authenticationResult?.account?.homeAccountId

  const logoutRequest = {
    account: msalInstance?.getAccountByHomeId(homeAccountId),
    postLogoutRedirectUri: config?.VariableSettings?.BackOfficeUIURL,
  }
  // for AD user-flow(B2CPolicyNameCustom) alone the authorityEndpoint should be common
  if (
    msalPolicyName?.toString()?.toLowerCase() ===
    config?.VariableSettings?.B2CPolicyNameCustom?.toString()?.toLowerCase()
  ) {
    logoutRequest.authority = config?.VariableSettings?.B2CAuthorityCommon
  }

  msalInstance?.logoutRedirect(logoutRequest)
}

const messagePopupStaticData = {
  setLocation: 'Set Location',
  setUser: 'Set User',
  setVRM: 'Set VRM',
  setContravention: 'Set Contravention Code',
  messageSuccess: 'Message has been successfully sent',
  validDateTIme: 'Valid Till should be greater or equal to Valid From date',
  view: 'view',
  add: 'add',
}

const workFlowManagerPage = {
  ok: 'OK',
  cancel: 'Cancel',
  workflowManager: 'Workflow Manager',
  assignedCases: 'Assigned Cases',
  unAssignedCases: 'UnAssigned Cases',
  tabTitle: {
    unassignedTasks: 'Unassigned Tasks',
    assignedTasks: 'Assigned Tasks',
  },
  dialogType: {
    assignUser: 'assignUser',
    unAssignUser: 'unAssignUser',
    reAssignUser: 'reAssignUser',
    complete: 'complete',
  },
  buttonText: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
  },
}

const scanImportPage = {
  pageTitle: 'Scan Import',
  upload: 'Upload',
  attachment: 'Attachment',
  uploadTitle:
    ' (Accepted file type (e.g., JPEG,PNG,TIFF) and documents formats (e.g., PDF) | Max. files: 1)',
  fileLimit: 1,
  fileSizeLimit: 10,
  allowedFileLimitMessage: 'Max files allowed is 1, please review',
  maxAllowedSizeForAttachments:
    'Error! File size too big, maximum allowed is 10mb',
  uploadSuccessMessage: 'File uploaded successfully',
}
const workflow = {
  deleteConfirmationMessage: 'Are you sure to delete the workflow task?',
  assignConfirmationMessage: 'Are you sure want to assign files to ',
  invalidPcn: 'Invalid case number',
}
const linkedCases = 'Linked Cases'
const contraventionCode = {
  observationPeriodSetting:
    'Observation period must be set to enable wheel positions recording',
  enablingWheelPositions: 'Are you sure want to enable record wheel position ?',
  disablingWheelPositions:
    'Are you sure want to disable record wheel position ?',
  errorWhileSaving: `Setting the observation time as 00:00:00 will automatically disable the wheel positions toggle. Are you sure want to disable the wheel position?`,
  noValuesChanged: 'No values changed.',
  createdSuccessFully: 'Created Successfully',
  updatedSuccessfully: 'Updated Successfully',
  errors: {
    charactersAllowed: 'Only 4 Characters are allowed',
    alphaNumericAllowed: 'AlphaNumeric only allowed',
    numbersOnlyAllowed: 'Number only allowed with decimal value',
    valueGreater: 'Value should be greater than 0',
  },
  fieldErrors: {
    organisation: 'organisation',
    contract: 'contract',
    code: 'code',
    description: 'description',
    observationTime: 'observationTime',
    contraventionPath: 'contraventionPath',
    insideBandA: 'insideBandA',
    insideBandB: 'insideBandB',
    differentialLevel: 'differentialLevel',
    tptetaNoticeType: 'tptetaNoticeType',
    dvlaEnquiryCode: 'dvlaEnquiryCode',
    outside: 'outside',
    locationGroup: 'locationGroup',
    recordWheelPositions: 'recordWheelPositions',
  },
}

const addAttachmentPage = {
  title: 'Attach the Documents that may support your contents',
  fileTypeText:
    '*Accepted files types: .png, .pdf, .jpg, .bmp, .tif, .heic | Max. files: 10.',
  noteText:
    'Following successful upload, the file will be virus scanned. Next the attachment page must be reloaded to preview the uploaded document.',
  createWorkflowTaskWarnMessage:
    'Your selected attachment is added and a workflow task has been created',
  createWorkflowTask: 'Create workflow task?',
}
const templateSetupPage = {
  mode: {
    edit: 'edit',
  },
  areYouSureToUpdateTheChanges: 'Are you sure to update the changes?',
  areYouSureToSaveTheDesignedTemplate:
    'Are you sure to save the designed template?',
}
/* istanbul ignore next */
const getCurrentDateTime = () => {
  var currentdate = new Date()
  var dateTimeFormat =
    (currentdate?.getDate() < 10
      ? '0' + currentdate?.getDate()?.toString()
      : currentdate?.getDate()?.toString()) +
    (currentdate?.getMonth() + 1 < 10
      ? '0' + (currentdate?.getMonth() + 1)?.toString()
      : (currentdate?.getMonth() + 1)?.toString()) +
    currentdate?.getFullYear()?.toString() +
    '_' +
    (currentdate?.getHours() < 10
      ? '0' + currentdate?.getHours()?.toString()
      : currentdate?.getHours()?.toString()) +
    (currentdate?.getMinutes() < 10
      ? '0' + currentdate?.getMinutes()?.toString()
      : currentdate?.getMinutes()?.toString()) +
    (currentdate?.getSeconds() < 10
      ? '0' + currentdate?.getSeconds()?.toString()
      : currentdate?.getSeconds()?.toString())

  return dateTimeFormat
}
/* istanbul ignore next */
export const generateZipFromCloud = async (
  selectedData,
  allURLs,
  showLoader,
  showMessage,
  timeZoneID
) => {
  let filename = `${selectedData?.noticeType
    ?.toString()
    ?.toLowerCase()}_${selectedData?.batchReference?.toString()}_${
    selectedData?.successQuantity
      ? selectedData?.successQuantity?.toString()
      : selectedData?.quantity?.toString()
  }_${convertUtcDateTimeToTimezoneCustomFormat(
    timeZoneID,
    returnCurrentDateTimeInUTC(),
    commonDateTimeFormat('downloadingFileNameFormat_Date_Time')
  )}`
  showLoader(true)
  const zip = new JSZip()
  const folder = zip.folder('batchFiles')
  for (let i = 0; i < allURLs?.length; i++) {
    const blobPromise = await fetch(allURLs[i]?.filePath)
    if (
      blobPromise?.status === apiResponseCode?.successCode ||
      blobPromise?.status === apiResponseCode?.zeroCode
    ) {
      const name = `${allURLs[i]?.filePath
        ?.toString()
        ?.substring(
          allURLs[i]?.filePath?.toString()?.lastIndexOf('/') + 1,
          allURLs[i]?.filePath?.toString()?.indexOf('?')
        )}`
      folder?.file(name, blobPromise?.blob())
    } else {
    }
  }

  zip
    .generateAsync({ type: 'blob' })
    .then((blob) => saveAs(blob, filename))
    .catch((e) => showMessage(toastStatus?.error, e))
  showLoader(false)
  showMessage(toastStatus?.success, localPrintingMessage?.downloadSuccess)
}

const caseActionsStaticData = {
  selectActionDataSource: [
    {
      name: 'Reversal',
    },
    {
      name: 'Challenge',
    },
    {
      name: 'Discount',
    },
  ],
  caseReversal: 'Reversal',
  caseChallenge: 'Challenge',
  caseDiscount: 'Discount',
  fromAction: ['Reversal', 'Challenge', 'Discount'],
  caseActionConfirmation: 'Do you also wish to send a letter?',
  headerTitle: 'Case Update',
  btnTextSave: 'Save & Send',
  btnTextSkip: 'Skip',
  reversalActionsButtonValue: {
    ntoReverted: {
      title: 'Revert to Select for NTO',
      description: 'Generate a new NTO',
    },
    ntoActioned: {
      title: 'Revert to Post NTO',
      description: 'Revert to NTO issued',
    },
    postedPCNReverted: {
      title: 'Revert to Select for Posted PCN',
      description: 'Generate new Postal PCN',
    },
    postedPCNActioned: {
      title: 'Revert to Post Postal PCN',
      description: 'Revert to Postal PCN issued',
    },
    letter_14Day: {
      title: 'Prepare 14 day letter reverted',
      description: 'TEC/Appeal - Send 14 day letter',
    },
    noaWarningNotice: {
      title: 'Revert to Select for Warning Notice',
      description: 'Generate new Postal Warning Notice',
    },
    revertIssued: {
      title: 'Revert to Issued',
      description: 'Revert case to issued stage (Full Charge)',
      customPopupTitle:
        'Are you sure wish to revert the case to the primary stage to allow progression again from that point at the full charge amount',
    },
    revertToSelectFinalWarning: {
      title: 'Revert to select for Final Warning',
      description: 'Reverting the case for Final warning',
      customPopupTitle:
        'Are you sure wish to revert the case to send a new Final Warning Letter and to allow progression again from that point at the full charge amount',
    },
    revertToFinalWarningSent: {
      title: 'Revert to Final Warning Sent',
      description: 'Reverting the case to Final Warning Sent',
      customPopupTitle:
        'Are you sure wish to revert the case to the secondary stage (Final Warning Issued) to allow progression again from that point at the full charge amount',
    },
    revertToAtCourt: {
      title: 'Revert to At Court',
      description: 'Reverting the case to At Court',
      customPopupTitle:
        'Are you sure wish to revert the case to the final stage to allow progression again from that point at the full charge amount',
    },
    revertToCourtEligible: {
      title: 'Revert to Court Eligible',
      description: 'Reverting the case to court eligible',
      customPopupTitle:
        'Are you sure wish to revert the case to the third stage to allow progression again from that point at the full charge amount',
    },
  },
  challengeButtonValue: {
    ChallengeSuccess: {
      title: 'Challenge Successful',
    },
    ChallengeUnSuccess: {
      title: 'Challenge Unsuccessful',
    },
    RepresentationSuccess: {
      title: 'Representation Successful',
    },
    RepresentationUnSuccess: {
      title: 'Representation Unsuccessful',
    },
    RejectRep: {
      title: 'Reject Rep. at CC',
    },
    AcceptRep: {
      title: 'Accept Rep. at CC',
    },
    IgnoreCC: {
      title: 'CC Ignore Representation',
    },
    IgnoreChallenge: {
      title: 'Ignore Challenge',
    },
    IgnoreRepresentation: {
      title: 'Ignore Representation',
    },
    TOLRepresentationSuccessful: {
      title: 'TOL Representation Successful',
    },
    IgnoreOFRRep: {
      title: 'Ignore OFR Rep',
    },
    IgnoreWarrantRep: {
      title: 'Ignore Warrant Rep',
    },
    customEventDescription: 'Representation info requested',
    reviewAccepted: {
      title: 'Review Accepted',
      description: 'The review has been accepted so cancelling the case',
    },
    reviewRejected: {
      title: 'Review Rejected',
      description: 'The review has been rejected',
    },

    courtSuccess: {
      title: 'Court Successful',
      description: 'Court has been successful, case needs to be proceeded',
    },
    courtUnSuccess: {
      title: 'Court Unsuccessful',
      description: 'Court has been unsuccessful, case needs to be cancelled',
    },
    unCancelCase: {
      title: 'Un-cancel Case',
      description: 'Put the case into a non cancelled state',
    },
    reCloseFollowingPaid: {
      title: 'Re-close following Paid Correspondence',
      description:
        'Update status to Paid following receipt and review of correspondence along with or following a payment being made',
    },
  },
}

const reClosefollowingPaidCorrespondenceChallenge = {
  paidCorrespondence: 'Paid Correspondence',
  updatedSuccessfully: 'Updated successfully',
}

const stageAndStatus = {
  stage: {
    id: 'stageId',
    label: 'Change Stage',
    placeHolder: 'Select stage',
  },
  status: {
    id: 'statusId',
    label: 'Change Status',
    placeHolder: 'Select status',
  },
  cancellationReason: {
    id: 'cancellationReason',
    label: 'Choose Cancellation Reason',
    placeHolder: 'Select Cancellation Reason',
  },
}

const ContraventionFileUploadPage = {
  uploadCSV: 'Upload CSV',
  uploadSummary: 'Upload Summary',
  totalData: 'Total Data',
  uploadedData: 'Uploaded Data',
  skippedData: 'Skipped Data',
  errorFiles: 'Error Files: ',
  downloadSample: 'Download Sample',
  skippedDataReport: 'Skipped data report',
  sampleFileContravention: 'contraventionsample',
}

const pcnNumberReservationPage = {
  pcnNumberReservationHeading: 'PCN number reservation',
  add: 'add',
  headerTitle: 'Reserve PCN number',
  rangeValue: 'rangeValue',
  placeHolderEnterRange: 'Enter',
  step: 10,
  minRange: 0,
  maxRange: 200,
  rangeShouldNotExceedMoreThan: 'Range should not exceed more than 200',
  saveSuccessMessage: 'PCN Number Reservation inserted successfully!',
  reservedPCNNumbersMailedSuccessfully:
    'Reserved PCN Numbers mailed successfully!',
  processComplete:
    'PCN reservation request initiated. Once completed, PCN(s) will be reserved successfully.',
  confirmationUsed1: 'Please note, already there are ',
  confirmationUsed2:
    ' unused reserved PCNs available for this CEOID. Do you wish to continue?',
  confirmationUnused1: 'Do you wish to reserve ',
  confirmationUnused2: ' PCNs for the CEO ID ',
  buttonTextField: {
    addNewRule: 'Add New Rule',
    cancel: 'Cancel',
    save: 'Save',
    reserve: 'Reserve',
    send: 'Send',
  },
  sendEmail: {
    headerTitle: 'Send Email',
    attachment: 'Attachment',
  },
}
const manualCase = {
  stepperName: {
    caseDetails: 'caseDetails',
    vehicleDetails: 'vehicleDetails',
    contraventionDetails: 'contraventionDetails',
    additionalInfo: 'additionalInfo',
    createdPcn: 'createdPcn',
  },
  contraventionDetails: {
    contravention: {
      id: 'contravention',
      label: 'Contravention',
      placeholder: 'Select Contravention',
    },
    observationStartTime: {
      id: 'observationStartTime',
      label: 'Observation Start Time',
      placeholder: 'Enter observation start time',
    },
    observationDuration: {
      id: 'observationDuration',
      label: 'Observation Duration',
      placeholder: 'Enter observation duration',
    },
    serviceMethod: {
      id: 'serviceMethod',
      label: 'Service Method',
      placeholder: 'Select service method',
    },
    wheelPositionOne: {
      id: 'wheelPositionOne',
      label: 'Wheel Position 1',
      placeholder: 'Select Wheel Position 1',
    },
    wheelPositionTwo: {
      id: 'wheelPositionTwo',
      label: 'Wheel Position 2',
      placeholder: 'Select Wheel Position 2',
    },
    returnWheelPositionOne: {
      id: 'returnWheelPositionOne',
      label: 'Return Wheel Position 1',
      placeholder: 'Select Wheel Position 1',
    },
    returnWheelPositionTwo: {
      id: 'returnWheelPositionTwo',
      label: 'Return Wheel Position 2',
      placeholder: 'Select Wheel Position 2',
    },
    allWindowsChecked: {
      id: 'allWindowsChecked',
      label: 'All Windows Checked',
    },
    dbs: {
      id: 'dbs',
      label: 'DBS',
      relativeField: 'ndbs',
    },
    ns: {
      id: 'ns',
      label: 'NS',
      relativeField: 'nn',
    },
    lo: {
      id: 'lo',
      label: 'LO',
      relativeField: 'nlo',
    },
    ds: {
      id: 'ds',
      label: 'DS',
      relativeField: 'dns',
    },
    ch: {
      id: 'ch',
      label: 'CH',
      relativeField: 'nc',
    },
    allInteriorChecked: {
      id: 'allInteriorChecked',
      label: 'All Interiors Checked',
    },
    ndbs: {
      id: 'ndbs',
      label: 'NDBS',
      relativeField: 'dbs',
    },
    nn: {
      id: 'nn',
      label: 'NN',
      relativeField: 'ns',
    },
    nlo: {
      id: 'nlo',
      label: 'NLO',
      relativeField: 'lo',
    },
    dns: {
      id: 'dns',
      label: 'DNS',
      relativeField: 'ds',
    },
    nc: {
      id: 'nc',
      label: 'NC',
      relativeField: 'ch',
    },
    warning: {
      id: 'warning',
      label: 'Warning',
    },
  },
  case_vehicle_details: {
    ceoId: {
      id: 'ceoId',
      label: 'CEO ID',
      placeholder: 'Select CEO ID',
      valueField: 'id',
      textField: 'badgeNumber',
    },
    caseNumber: {
      id: 'caseNumber',
      label: 'Case Number',
      placeholder: 'Select Case Number',
      valueField: 'caseNumber',
      textField: 'caseNumber',
    },
    contraventionDateTime: {
      id: 'contraventionDateTime',
      label: 'Date & Time',
      placeholder: 'Select Date & Time',
    },
    country: {
      id: 'country',
      label: 'Country',
      placeholder: 'Select Country',
      valueField: 'id',
      textField: 'country',
    },
    location: {
      id: 'location',
      label: 'Location',
      placeholder: 'Select Location',
      valueField: 'id',
      textField: 'name',
    },
    gps: {
      id: 'gps',
      label: 'GPS',
      placeholder: 'Enter GPS',
    },
    reason: {
      id: 'reason',
      label: 'Reason - Manual Case',
      placeholder: 'Select Reason',
      valueField: 'id',
      textField: 'manualCaseReason',
    },
    vrm: {
      id: 'vrm',
      label: 'VRM',
      placeholder: 'Enter VRM',
    },
    make: {
      id: 'make',
      label: 'Make',
      placeholder: 'Select make',
      valueField: 'id',
      textField: 'make',
    },
    color: {
      id: 'color',
      label: 'Color',
      placeholder: 'Select color',
      valueField: 'id',
      textField: 'color',
    },
  },
  additionalInfo: {
    attachments: {
      title: 'Attachments',
      acceptedAttachmentText:
        '(Accepted file type: .png .pdf .jpg .bmp .tif .heic) | Max Size:10 mb per file',
      maximumFileSizExceed:
        'Error! File size too big, maximum allowed is 10 mb',
    },
    notes: {
      id: 'notes',
      label: 'Notes',
      placeholder: 'Enter Notes',
      inputLimit: 60000,
      alert: 'Maximum character limit is reached',
    },
  },
  manualCaseButtons: {
    cancel: {
      label: 'Cancel',
      variant: 'secondary',
      id: 'btn_cancel',
      className: 'cancelButton',
    },
    next: {
      label: 'Next',
      variant: 'primary',
      id: 'btn_next',
      className: 'nextButton',
    },
    back: {
      label: 'Back',
      variant: 'primary',
      id: 'btn_back',
      className: 'backButton',
    },
    createPcn: {
      label: 'Create PCN',
      variant: 'primary',
      id: 'btn_createPcn',
      className: 'createPcn',
    },
  },
  reportFault: {
    attachmentTitle: 'Attachment',
    attachmentFileReference: `(Accepted file type: .png .jpg .bmp .tif .heic)`,
    reportFaultSuccessTitle: 'Fault Reported Successfully!',
  },
}
/* istanbul ignore next */
const getDisplayTypeOfAttachment = (fileExtension) => {
  let fileNameExtension = fileExtension?.toString()?.toLowerCase()
  let displayType = ''
  switch (fileNameExtension) {
    case 'jpg':
    case 'jpeg':
    case 'bmp':
    case 'heic':
    case 'tif':
    case 'tiff':
    case 'png':
    case 'jfif':
      displayType = 'img'
      break
    case 'pdf':
      displayType = 'pdf'
      break
    case 'docx':
    case 'doc':
      displayType = 'doc'
      break
  }
  return displayType
}

const tecActions = {
  tecActions1: {
    prepareFourteenDayLetter: {
      title: 'Prepare 14 Days Letter',
      description: 'Generate 14 day TEC/Tribunal Letter to the customer',
    },
    fourteenDayLetterContest: {
      title: 'Prepare 14 Days Letter Contested',
      description:
        'RK has contested the case following the issue if a 14 days letter',
    },
  },
  tecActions2: {
    confirmationNoteReceived: {
      title: 'Confirmation Note Received',
      description: 'TEC confirmation note has been received',
    },
    revokeOrderReceived: {
      title: 'Revoke Order Received',
      description: 'TEC revoke order has been received',
    },
    cancelFollowingRevokeOrder: {
      title: 'Cancel Following Revoke Order',
      description: 'TEC revoke order received, case cancelled',
    },
    decisionNoteReceived: {
      title: 'Decision Note Received',
      description: 'TEC decision note has been received',
    },
  },
  tecActions3: {
    inTimeReceived: {
      title: 'In Time Received',
      description: 'In time witness statement received',
    },
    outTimeReceived: {
      title: 'Out Of Time Received',
      description: 'Out of time witness statement received',
    },
    inTimeLost: {
      title: 'In Time Lost',
      description: 'Witness statement outcome',
    },
    outTimeRejected: {
      title: 'Out Of Time Rejected',
      description: 'Out of Time witness statement rejected',
    },
  },
}

const location = {
  message: {
    confirmationWarningEndDateBelowSixMonth:
      'Are you sure want to set the Warning period at less than 6 months?',
    locationLimitReached: 'Input limit exceeds',
  },
}
const additionalTab = {
  locationDescription: 'Location Description',
  bodyWornVideo: 'Body Worn Video',
  observation: 'Observation',
  selectionToggles: 'Selection Toggles',
}

const monitoringReports = {
  monitoringDashboard: {
    title: 'Monitoring Dashboard',
    dataTestId: 'monitoringDashboard',
  },
  casesNotProgressed: {
    title: 'Cases Not Progressed',
    dataTestId: 'casesNotProgressed',
  },
  pcnForecast: {
    title: 'PCN Forecast',
    dataTestId: 'pcnForecast',
  },
  casesOnHoldAndNextProgression: {
    title: 'Cases On Hold and Next Progression',
    dataTestId: 'casesOnHoldAndNextProgression',
  },
  caseActiveDays: {
    title: 'Case Active Days',
    dataTestId: 'caseActiveDays',
  },
  printBatches: {
    title: 'Print Batches',
    dataTestId: 'printBatches',
  },
  bailiffBatches: {
    title: 'Bailiff Batches',
    dataTestId: 'bailiffBatches',
  },
  pageTitle: 'Monitoring Reports',
}
const hhdSourceType = 'HHD'
const manualCaseSourceType = 'Manual'
/* istanbul ignore next */
const challengeActionDisable = (
  stageKey,
  statusKey,
  sourceType,
  actionName,
  balanceAmount = 0,
  activeDays = null
) => {
  const challengeActionButtons = caseActionsStaticData?.challengeButtonValue
  statusKey = statusKey?.toString()?.toLowerCase()
  stageKey = stageKey?.toString()?.toLowerCase()
  if (
    actionName === challengeActionButtons?.ChallengeSuccess?.title ||
    actionName === challengeActionButtons?.ChallengeUnSuccess?.title ||
    actionName === challengeActionButtons?.IgnoreChallenge?.title
  ) {
    if (
      (stageKey === stageTextMap?.liveStage?.key?.toString().toLowerCase() ||
        stageKey === stageTextMap?.informal?.key?.toString().toLowerCase()) &&
      statusKey !== statusTextMap?.cancelled?.key?.toString().toLowerCase() &&
      statusKey !== statusTextMap?.paid?.key?.toString().toLowerCase() &&
      statusKey !==
        statusTextMap?.manualDiscount?.key?.toString().toLowerCase() &&
      (sourceType === hhdSourceType?.toString().toLowerCase() ||
        sourceType === manualCaseSourceType?.toString()?.toLowerCase())
    )
      return false
    return true
  } else if (
    actionName === challengeActionButtons?.RepresentationSuccess?.title ||
    actionName === challengeActionButtons?.RepresentationUnSuccess?.title ||
    actionName === challengeActionButtons?.IgnoreRepresentation?.title ||
    actionName === challengeActionButtons?.TOLRepresentationSuccessful?.title
  ) {
    if (
      stageKey === stageTextMap?.representation?.key?.toLowerCase() &&
      (statusKey === statusTextMap?.clientReviewed?.key?.toLowerCase() ||
        statusKey === statusTextMap?.onHold?.key?.toLowerCase())
    )
      return false
    return true
  } else if (
    actionName === challengeActionButtons?.AcceptRep?.title ||
    actionName === challengeActionButtons?.RejectRep?.title ||
    actionName === challengeActionButtons?.IgnoreCC?.title
  ) {
    if (
      (stageKey === stageTextMap?.chargeCertificate?.key?.toLowerCase() &&
        (statusKey === statusTextMap?.active?.key?.toLowerCase() ||
          statusKey === statusTextMap?.onHold?.key?.toLowerCase())) ||
      (stageKey === stageTextMap?.debtStage?.key?.toLowerCase() &&
        (statusKey === statusTextMap?.active?.key?.toLowerCase() ||
          statusKey === statusTextMap?.onHold?.key?.toLowerCase()))
    )
      return false
    return true
  } else if (actionName === challengeActionButtons?.IgnoreOFRRep?.title) {
    if (
      stageKey === stageTextMap?.ofr?.key?.toLowerCase() &&
      (statusKey === statusTextMap?.active?.key?.toLowerCase() ||
        statusKey === statusTextMap?.onHold?.key?.toLowerCase())
    )
      return false
    return true
  } else if (actionName === challengeActionButtons?.IgnoreWarrantRep?.title) {
    if (
      stageKey === stageTextMap?.warrantExported?.key?.toLowerCase() &&
      statusKey === statusTextMap?.active?.key?.toLowerCase()
    )
      return false
    return true
  } else if (
    actionName === challengeActionButtons?.courtSuccess?.title ||
    actionName === challengeActionButtons?.courtUnSuccess?.title
  ) {
    if (
      stageKey === stageTextMap?.atCourt?.key?.toString()?.toLowerCase() &&
      statusKey !== statusTextMap?.paid?.key?.toString()?.toLowerCase() &&
      statusKey !== statusTextMap?.cancelled?.key?.toString()?.toLowerCase()
    )
      return false
    return true
  } else if (actionName === challengeActionButtons?.unCancelCase?.title) {
    if (statusKey === statusTextMap?.cancelled?.key?.toString()?.toLowerCase())
      return false
    return true
  } else if (
    actionName === challengeActionButtons?.reCloseFollowingPaid?.title
  ) {
    if (
      statusKey !== statusTextMap?.paid?.key?.toString()?.toLowerCase() &&
      balanceAmount === 0
    )
      return false
    return true
  } else if (actionName === challengeActionButtons?.reviewRejected?.title) {
    if (
      statusKey === statusTextMap?.onHold?.key?.toString()?.toLowerCase() &&
      statusKey !== statusTextMap?.paid?.key?.toString()?.toLowerCase() &&
      statusKey !== statusTextMap?.cancelled?.key?.toString()?.toLowerCase() &&
      stageKey !==
        stageTextMap?.courtEligible?.key?.toString()?.toLowerCase() &&
      stageKey !== stageTextMap?.atCourt?.key?.toString()?.toLowerCase()
    )
      return false
    return true
  } else if (
    actionName === challengeActionButtons?.reviewAccepted?.title &&
    statusKey === statusTextMap?.onHold?.key?.toString()?.toLowerCase()
  )
    return false
  return true
}

const isDisabledBasedOnCurrentStageStatus = (
  buttonTitle,
  existingStageKey,
  existingStatusKey
) => {
  existingStageKey = existingStageKey?.toString()?.toLowerCase()
  existingStatusKey = existingStatusKey?.toString()?.toLowerCase()
  const liveStage = stageTextMap?.liveStage?.key?.toString()?.toLowerCase()
  const informalStage = stageTextMap?.informal?.key?.toString()?.toLowerCase()
  const informalNORStage = stageTextMap?.informalNor?.key
    ?.toString()
    ?.toLowerCase()
  const paidStage = statusTextMap?.paid?.key?.toString()?.toLowerCase()
  const cancelledStage = statusTextMap?.cancelled?.key
    ?.toString()
    ?.toLowerCase()
  const ntoStage = stageTextMap?.nto?.key?.toString()?.toLowerCase()
  const postedPCNStage = stageTextMap?.postedPcn?.key?.toString()?.toLowerCase()
  const representationStage = stageTextMap?.representation?.key
    ?.toString()
    ?.toLowerCase()
  const representationNORStage = stageTextMap?.representationNor?.key
    ?.toString()
    ?.toLowerCase()
  const requestedStage = statusTextMap?.requested?.key
    ?.toString()
    ?.toLowerCase()
  const debtRegistrationStage = stageTextMap?.debtRegistration?.key
    ?.toString()
    ?.toLowerCase()
  const debtStage = stageTextMap?.debtStage?.key?.toString()?.toLowerCase()
  const ofrStage = stageTextMap?.ofrStage?.key?.toString()?.toLowerCase()
  const orfExpiredStage = stageTextMap?.ofrExpired?.key
    ?.toString()
    ?.toLowerCase()
  const atCourtStage = stageTextMap?.atCourt?.key?.toString()?.toLowerCase()
  const chargeCertificateStage = stageTextMap?.chargeCertificate?.key
    ?.toString()
    ?.toLowerCase()

  if (buttonTitle === discountButtonValue?.discount?.title) {
    if (
      (existingStageKey === liveStage ||
        existingStageKey === informalStage ||
        existingStageKey === informalNORStage) &&
      existingStatusKey !== paidStage &&
      existingStatusKey !== cancelledStage
    )
      return false
    return true
  } else if (buttonTitle === discountButtonValue?.discountRepReject?.title) {
    if (
      (existingStageKey === ntoStage ||
        existingStageKey === postedPCNStage ||
        existingStageKey === representationStage ||
        existingStageKey === representationNORStage) &&
      existingStatusKey !== paidStage &&
      existingStatusKey !== cancelledStage &&
      existingStatusKey !== requestedStage
    )
      return false
    return true
  } else if (buttonTitle === discountButtonValue?.discountCCRepReject?.title) {
    if (
      (existingStageKey === chargeCertificateStage ||
        existingStageKey === debtStage) &&
      existingStatusKey !== paidStage &&
      existingStatusKey !== cancelledStage
    )
      return false
    return true
  } else if (buttonTitle === discountButtonValue?.discountOFRRepReject?.title) {
    if (
      (existingStageKey === debtRegistrationStage ||
        existingStageKey === ofrStage ||
        existingStageKey === orfExpiredStage) &&
      existingStatusKey !== paidStage &&
      existingStatusKey !== cancelledStage
    )
      return false
    return true
  } else if (
    buttonTitle == discountButtonValue?.reverttoIssuedDiscount?.title
  ) {
    if (
      existingStageKey !== atCourtStage &&
      existingStatusKey !== cancelledStage &&
      existingStatusKey !== paidStage &&
      existingStageKey !== liveStage
    )
      return false
    return true
  }
}

const prefixSetupPage = {
  placeholder2: 'Enter 2 character prefix',
  placeholder3: 'Enter 3 character prefix',
  createdSuccessfully: 'Created Successfully',
  updatedSuccessfully: 'Updated Successfully',
  enterthetwocharacterprefixhere: 'Please enter two character prefix.',
  enterthethreecharacterprefixhere: 'Please enter three character prefix.',
  warningMessage:
    'Please note this template will create an attachment on the case but a letter will not be sent to the customer automatically.',
}
const twoPrefixPCN = {
  pcnLength: 10,
  prefixCount: 2, // when length is 10 and prefix is 2
  digitCount: 7, // when length is 10 and prefix is 2 then number length should be 6
  suffixCount: 1,
}
const threePrefixPCN = {
  pcnLength: 11,
  prefixCount: 3, // when length is 11 and prefix is 3
  digitCount: 7, // when length is 11 and prefix is 3 then number length should be 7
  suffixCount: 1,
}
// This is to validate the given caseNumber is valid format of PCN or not
const isValidPcnNumber = (caseNumber) => {
  let pcnNumber = caseNumber?.toString()?.trim()
  let pcnNumberRegExString = ``
  let regExOccurence = { ...twoPrefixPCN } // considered 2 prefix is default pcnFormat

  if (pcnNumber?.length === threePrefixPCN?.pcnLength) {
    regExOccurence = { ...threePrefixPCN }
  }
  pcnNumberRegExString = `^[a-zA-Z]{${regExOccurence?.prefixCount}}\\d{${regExOccurence?.digitCount}}[a-zA-Z0-9]{${regExOccurence?.suffixCount}}$`
  const pcnNumberRegEx = new RegExp(pcnNumberRegExString)
  return pcnNumberRegEx?.test(pcnNumber)
}
const taskPreview = {
  attachmentTitle: 'Images',
  acceptedFiles: `(Accepted file types: .tiff .bmp .jpg .jpeg .png .heic) | Max.Size: 10 mb per file.`,
  note: 'Notes',
}

const dvlaMismatchStaticData = {
  dvlaHeaderTitle: 'DVLA Response',
  npsHeaderTitle: 'NPS Data Mismatch',
  btnTextProgress: 'Progress',
  btnTextCancelCase: 'Cancel Case',
  Source: 'DVLA',
}
const fpnBasedChanges = {
  offender: 'Offender',
  keeper: 'Keeper',
  fpnRadioBoxWidth: 100,
  globalSearch: 'Quick Search',
  invalidGlobalSearch: 'Input should not be empty.',
  cancelCase: 'Cancel Case',
  cancelCaseConfirmation: 'Are you sure want to cancel the Case?',
  holdCase: 'Hold Case',
  offHoldCase: 'Off Hold Case',
  locationDescription: 'Location Description',
  pcnDescription: 'Description',
  pcnLocation: 'Location',
  pcnContravention: 'Code',
  pcnOperationalReport: 'Operational Internal',
  pcnActiveDays: ['servedinpost', 'servedinperson'],
  fpnDescription: 'Contravention Description',
  fpnLocation: 'Offence Location',
  fpnContravention: 'Contravention Code',
  fpnAvailable: 'Available',
  fpnUnAvailable: 'Unavailable',
  fpnNoLinkedCase: 'No linked cases',
  fpnContraventionCode: 'Contravention Code',
  fpnOperationalReport: 'Case Status',
}

const mismatchProgressWarnMessage = 'Are you sure want to progress this case?'
const cancelCaseWarnMessage =
  'Cancelling this case will stop the case progression. Are you sure want to cancel?'

const changeBasedOnTheFPNContract = (
  oldChange,
  fpnWordChange,
  contractName
) => {
  return contractName === 'fpn' ? fpnWordChange : oldChange
}
const buttonDisabledValidation = (currentStage, currentStatus, actionName) => {
  const caseStatus = currentStatus?.toString()?.toLowerCase()
  const caseStage = currentStage?.toString()?.toLowerCase()
  const atCourtStage = stageTextMap?.atCourt?.key?.toString()?.toLowerCase()
  const liveStage = stageTextMap?.liveStage?.key?.toString()?.toLowerCase()
  const paidStatus = statusTextMap?.paid?.key?.toString()?.toLowerCase()
  const finalWarning = stageTextMap?.finalWarning?.key
    ?.toString()
    ?.toLowerCase()
  const courtEligiblestage = stageTextMap?.courtEligible?.key
    ?.toString()
    ?.toLowerCase()
  const onHoldStatus = statusTextMap?.onHold?.key?.toString()?.toLowerCase()

  if (
    actionName ===
    caseActionsStaticData?.reversalActionsButtonValue?.revertIssued?.title
  ) {
    if (
      caseStage !== atCourtStage &&
      caseStage !== liveStage &&
      caseStatus !== paidStatus
    )
      return false
    return true
  } else if (
    actionName ===
    caseActionsStaticData?.reversalActionsButtonValue?.revertToFinalWarningSent
      ?.title
  ) {
    if (
      caseStage !== atCourtStage &&
      caseStatus !== paidStatus &&
      caseStage !== liveStage &&
      caseStage !== finalWarning
    )
      return false
    return true
  } else if (
    actionName ===
    caseActionsStaticData?.reversalActionsButtonValue
      ?.revertToSelectFinalWarning?.title
  ) {
    if (
      caseStage !== atCourtStage &&
      caseStatus !== paidStatus &&
      caseStage !== liveStage
    )
      return false
    return true
  } else if (
    actionName ===
    caseActionsStaticData?.reversalActionsButtonValue?.revertToAtCourt?.title
  ) {
    if (caseStage === courtEligiblestage && caseStatus !== paidStatus)
      return false
    return true
  } else if (
    actionName ===
    caseActionsStaticData?.reversalActionsButtonValue?.revertToCourtEligible
      ?.title
  ) {
    if (caseStage === atCourtStage && caseStatus !== paidStatus) return false
    return true
  }
}

const cancellationReasonPopup = {
  crCancellationReasonSummaryHeading: 'Summary',
  crCancellationReasonAddSuccessMessage:
    'Cancellation reason added successfully!',
  cancelPopupConfirmation: 'Are you sure want to cancel?',
}
const cancellationReasonList = {
  cancellationReasonTitle: 'Cancellation Reason',
  uploadCsv: {
    uploadCsv: 'Upload CSV',
  },
  addCancellationButton: {
    fieldValue: 'AddCancellationReason',
    fieldLabel: 'Add Cancellation Reason',
    dataTestId: 'add_cancellationReason',
  },
  exportButton: {
    export: 'Export',
    className: 'exportCancellationReasonButton',
    dataTestId: 'export_cancellationReason',
    startIconClassName: 'exportCancellationReason',
  },
  reasonLimitReach: 'Should not exceed more than 5000 character',
  deleteConfirmationMessage:
    'Once this data is deleted then same can’t be retrieved. Are you sure to delete?',
}

const poundLocationList = {
  poundLoationTitle: 'Pound Location',
  addPoundLocationButton: {
    fieldValue: 'addPoundLocation',
    fieldLabel: 'Add Pound Location',
    dataTestId: 'add_PoundLocation',
  },
  poundLocationNameLimitReach: 'Should not exceed more than 500 character',
  poundLocationIDLimitReach: 'Should not exceed more than 5 character',
  deleteConfirmationMessage:
    'Once this data is deleted then same can’t be retrieved. Are you sure to delete?',
  cancelPopupConfirmation: 'Are you sure want to cancel?',
  selectOrganisation: 'Please select organisation',
  belowExistingBayCount:
    'Maximum bay count should be greater than the current highest used value',
  summaryPopup: {
    confirmationButton: 'Confirm',
    editButton: 'Edit',
  },
  bayCountLimitError: 'Bay count should be lesser than 500',
  crPoundLocationAddSuccessMessage: 'Pound Location added successfully!',
  locationIDlength: 5,
  locationNameLenght: 500,
}
const prioritySettingsList = {
  prioritySettingsTitle: 'Priority Settings',
}

export default {
  sortArrayOfObjects,
  handleBeforeUnload,
  confirmwindow,
  utcDateFormat,
  commonDateTimeFormat,
  commonDateTimeFormatForReactMultiDatePicker,
  commonDateTimePlaceHolder,
  permissionMessage,
  convertArrOfObjectToArrOfString,
  dateAndTimeConversion,
  convertSystemDateTimeToDate,
  convertDateTimePickerDateTimeToCustomFormat,
  convertUtcDateTimeToFormattedDateAgGrid,
  convertUtcDateTimeToFormattedDateTimeAgGrid,
  convertUtcDateTimeToFormattedDateTime,
  returnCurrentDateTimeBasedOnTimezone,
  returnCurrentDateBasedOnTimezoneWithoutFormatting,
  returnCurrentDateTimeInUTC,
  returnCurrentDateTimeSpecificFormatBasedOnTimezone,
  menuAccessRoles,
  subMenuAccessRoles,
  menuList,
  getRoleFromAssignedContractJSON,
  checkUserRole,
  checkPermittedRoleOrNot,
  dataSaveWarnTitle,
  dataCloseConfirmation,
  rejectTitle,
  dataSaveWarnMessage,
  dataEditTitle,
  dataEditMessage,
  commonPostBodyDateTimeFormat,
  convertUtcDateTimeToTimezoneDateTime,
  convertUtcDateTimeToTimezoneDate,
  convertUtcDateTimeToTimezoneTime,
  convertUtcDateTimeToTimezoneCustomFormat,
  convertTimezoneDateTimeToUtcDateTime,
  gridFilterOptions,
  apiResponseCode,
  apiDomainName,
  isValidEmailAddress,
  isAlphaNumeric,
  tptetaNoticeType,
  setDecimalValue,
  userRoleCheck,
  userRoleCheckAdmin,
  defaultHHDAutoLogOut,
  minHHDAutoLogOut,
  maxHHDAutoLogOut,
  removeKeyFromObject,
  isValidPhoneNumber,
  isValidCEOBadgeNumber,
  deleteConfirmation,
  deleteConfirmationTitle,
  isValidURL,
  messageTitle,
  deleteMessage,
  browserIdentify,
  infectedVirusMessage,
  scanFailed,
  scanOnProgress,
  generateNewRequest,
  processMessage,
  discountApplied,
  discountCannotApply,
  stageTextMap,
  statusTextMap,
  sourceTextMap,
  generateNewRequestDebt,
  representationReview,
  representationReviewMoreInfo,
  generateNewRequestBailiff,
  tokenExpiredMessage,
  fileDownloaderByURL,
  createObjectUrlAndDownload,
  processCancel,
  csvDownLoadClickHandler,
  constMessageObject,
  applicationInactiveTimeLapse,
  refreshTokenMinutesEarlier,
  toastAutoHideDuration,
  cancellationReasonWarnMessage,
  iconFontSize,
  isAssociatedWithTask,
  reAssignUserMessage,
  assignUSerMessage,
  completedTaskMessage,
  deleteTaskConfirmMessage,
  inputSaveMessage,
  workFlowAssignMessage,
  cancellationWarningMessage,
  exportDataError,
  cancellationReasonDelete,
  completeSuccessMessage,
  workFlowReAssignMessage,
  messageDisplayItems,
  workFlowUnassignMessage,
  keeperConfirmation,
  keeperUpdateConfirmation,
  actionButton,
  // toastSuccessStatus,
  bailiffMessages,
  repReviewActionStatus,
  templateData,
  contractData,
  integrationServices,
  brandingMenu,
  branding,
  invalidGPS,
  toastStatus,
  confirmWindowMessage,
  allowedFileLimit,
  newGenerateRequestMessage,
  newGenerateRequestMessageFalse,
  tribunalActionEnabledStages,
  locationNameCheck,
  SuccessMsgs,
  getLabel,
  uRLCheck,
  domainURLCheck,
  brandingDeleteSuccess,
  domainSettings,
  noRecordsFound,
  configurationStep1,
  txtbutton,
  brandingContractHeading,
  errorOnGettingData,
  roleList,
  mediaExtensions,
  fileSequenceNoHelpText,
  searchFieldDataSource,
  sampleFileZone,
  attachmentDropZoneStaticData,
  contractSetupToggleOptions,
  discountButtonValue,
  isShowPreviouslyIssuedPcnValues,
  getFileSizeInKb,
  applyPreviewData,
  setBackgroundImage,
  setBackground,
  setBgImageProperty,
  acceptPartPayment,
  updatedSuccessfully,
  tooltipTheme,
  defaultButtonBGColor,
  defaultButtonTextColor,
  customTooltipThemeData,
  dvlaXMLFileDownloadSuccess,
  xmlFileDownLoadClickHandler,
  noValuesChanged,
  userPermissionMessage,
  emptyFunction,
  inputFieldNameListLoweredCase,
  contractSetupFieldErrors,
  modes,
  roleList,
  fileUploadStateValue,
  attachmentInvalidErrors,
  bulkUpdateMessages,
  maxNotesLength,
  caseSettingsPage,
  maximumCharacterLimitReached,
  placeHolderText,
  getBase64StringDetail,
  purgePage,
  apiMethods,
  revertedType,
  officerTrackingMessage,
  signOutTheUser,
  messagePopupStaticData,
  workFlowManagerPage,
  scanImportPage,
  workflow,
  linkedCases,
  getBase64FromBlobURL,
  addAttachmentPage,
  templateSetupPage,
  RemoveBase64FileType,
  generateZipFromCloud,
  localPrintingMessage,
  getCurrentDateTime,
  contraventionCode,
  persistantEvaderLabel,
  holdReasonStaticData,
  debtReviewCancelCases,
  caseActionsStaticData,
  challengeAction,
  reClosefollowingPaidCorrespondenceChallenge,
  toggleHorizontalLine,
  onHoldOffHoldPCN,
  stageAndStatus,
  ContraventionFileUploadPage,
  reallocationAttachment,
  exceedRows,
  pcnNumberReservationPage,
  cancellationReasonFileUploadPage,
  manualCase,
  getDisplayTypeOfAttachment,
  locationFileUploadPage,
  tecActions,
  location,
  additionalTab,
  monitoringReports,
  exceedRows,
  hhdSourceType,
  challengeActionDisable,
  prefixSetupPage,
  isValidPcnNumber,
  taskPreview,
  contractTypes,
  dvlaMismatchStaticData,
  fpnBasedChanges,
  mismatchProgressWarnMessage,
  cancelCaseWarnMessage,
  changeBasedOnTheFPNContract,
  buttonDisabledValidation,
  isDisabledBasedOnCurrentStageStatus,
  clampAndRemovalSource,
  contractDisablingToggles,
  cancellationReasonPopup,
  cancellationReasonList,
  liveTrackingTimelineTabPermittedRoles,
  poundLocationList,
  prioritySettingsList,
}
